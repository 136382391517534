import React, {useState} from 'react';
import {FormItem, FormItemType} from "components/_common";
import {in_array, copyClipboard, rand, generateRandomString} from "utils/func";
import {rolesAdmin, rolesModerator, MENU_TITLE, SITE_URL} from "utils/config";


const UserEdit = props => {

	const {
		edit, formRef, avatarFileRef,
		userId, userData, divisions, rolesList, user,
		roleNameDefault, editSelf = false
	} = props;

	let roleSelect = null, divisionSelect = null;

	const [selectedRole, setSelectedRole] = useState(
		userData ? userData.role : roleNameDefault
	);

	const [passwordShow, setPasswordShow] = useState(false);
	const [passwordValue, setPasswordValue] = useState('');

	if (edit && in_array(user.role, rolesModerator) && userId !== 1) {
		let rolesOptions = {};
		for (const role in rolesList) {
			if (role !== 'admin' || in_array(user.role, rolesAdmin))
				rolesOptions[role] = {
					value: rolesList[role]
				}
		}

		roleSelect = <FormItem
			edit={true}
			name={"role"}
			fieldType={"select"}
			options={rolesOptions}
			label={"Роль"}
			defVal={selectedRole}
			changeAction={(e) => setSelectedRole(e.target.value)}
			isRequire={true}
		/>;
	}

	// const [selectedDivisionsId, setSelectedDivisionsId] = useState(new Set());

	if (edit && in_array(user.role, rolesAdmin) && divisions) {
		// console.dir(userData);
		const divOptions = {};
		for (const k in divisions) {
			divOptions[divisions[k].id] = {
				value: divisions[k].id,
				name: divisions[k].name,
				// ico: "users"
			}
		}


		divisionSelect = <FormItem
			label={MENU_TITLE.divisions.many}
			fieldType={"checkSelect"}
			options={divOptions}
			name={"divisions"}
			defVal={(userData && userData.divisions) ? userData.divisions : []}
			// changeAction={(e,v) => {
			// 	console.log(v);
			// 	if(e.target.checked)
			// 		selectedDivisionsId.add()
			// }}
		/>
	}
	// else if (userData && userData.divId && divisions && divisions[userData.divId] !== undefined)
	// 	divisionSelect = <FormItem
	// 		name={"divId"}
	// 		edit={false}
	// 		label={MENU_TITLE.divisions.one}
	// 		defVal={divisions[userData.divId].name}
	// 	/>;
	// console.log(edit, editSelf);

	return (
		<form ref={formRef} encType={"multipart/form-data"}>

			<FormItem
				edit={edit}
				name={"name"}
				label={"Имя пользователя"}
				isRequire={true}
				defVal={userData ? userData.name : ''}
				ico={"user"}
			/>

			{roleSelect}
			{divisionSelect}

			<FormItem
				edit={edit && !editSelf}
				name={"login"}
				label={"Логин (для входа в систему)"}
				fieldType={"text"}
				isRequire={true}
				defVal={userData ? userData.login : ''}
				ico={"user-o"}
				descr={"Допустимые знаки: a-z0-9_"}
				changeAction={(e, val) => {
					return val.replace(/\W/i, '');
				}}
			/>
			{
				edit
					? <FormItem
						edit={edit}
						name={"password"}
						label={"Пароль"}
						fieldType={passwordShow ? "text" : "password"}
						isRequire={!userId}
						defVal={passwordValue}
						ico={"key"}
						descr={userId ? "задайте новый пароль для смены" : ''}
						icoRight={ passwordValue ? {
							ico: 'copy',
							onClick: () => copyClipboard(passwordValue),
							tip: 'Скопировать в буфер обмена'
						} : null}

						descr={
							<a href={"#"}
							   onClick={e => {
									e.preventDefault();
									setPasswordValue(generateRandomString(rand(8, 16), true, true));
									setPasswordShow(true);
									// setTimeout(() => setPasswordShow(false), 500);
							   }}>
								сгенерировать пароль
							</a>
						}
					/>
					: null
			}

			<FormItem
				edit={edit}
				name={"email"}
				label={"Email"}
				isRequire={false}
				defVal={userData ? userData.email : ''}
				ico={"envelope"}
			/>
			<FormItem
				fieldType={FormItemType.bool}
				edit={edit}
				name={"mailing"}
				label={"Получать Email уведомления"}
				isRequire={false}
				defVal={1}
				// ico={"envelope"}
				isChecked={(userData && userData.mailing)}
			/>
			<FormItem
				edit={edit}
				name={"phone"}
				label={"Телефон"}
				defVal={userData ? userData.phone : ''}
				ico={"phone"}
			/>

			<FormItem
				edit={edit}
				name={"info"}
				label={"Дополнительные данные"}
				defVal={userData ? userData.info : ''}
				ico={"info"}
				fieldType={"txt"}
			/>
			<FormItem
				edit={edit}
				name={"num"}
				label={"Порядок"}
				defVal={userData ? userData.num : ''}
				ico={"sort-numeric-asc"}
				descr={"порядок сортировки, может исп. в разных частях, целое число 0 и больше"}
				numericRound={0}
			/>
			<div className="user-edit-avatar">
				<h3>Изображение профиля</h3>
				<div className="flx">
					<div className="-av">
						{
							(userData && userData.avatar)
								? < img src={SITE_URL + userData.avatar}/>
								: null
						}
					</div>
					{
						edit
							? <FormItem
								edit={edit}
								name={"avatar"}
								label={null}
								fieldType={"file"}
								isRequire={false}
								reff={avatarFileRef}
								// defVal={userData && userData.login}
							/>
							: null
					}

				</div>

			</div>

		</form>
	)

};

export default UserEdit;

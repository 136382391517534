import Storage from "utils/Storage";
import Cookies from 'universal-cookie';
import {COOKIE_LOGIN, COOKIE_PASS, SESSION_LIFETIME} from "utils/config";

export const SESSION_USER = 'user';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SET = 'USER_SET';
export const USER_SET_SETTINGS = 'USER_SET_SETTINGS';

// export const userAuth = (userInfo = null) => {
// 	const type = userInfo ? USER_LOGIN : USER_LOGOUT;
//
// 	if (type === USER_LOGOUT) {
// 		Storage.session_set(SESSION_USER, null);
//
// 		const cookies = new Cookies();
// 		cookies.remove(COOKIE_LOGIN);
// 		cookies.remove(COOKIE_PASS);
// 	}
// 	return {
// 		type: type,
// 		payload: userInfo
// 	}
// };
export const userAuth = (type, userInfo) => {

	console.log('userAuth type', type);
	// console.dir(userInfo);

	if (typeof type !== 'string')
		throw "UserAction.userAuth(type, userInfo)? parameter type must be a string";
	if (type === USER_LOGOUT) {
		// Storage.session_set(SESSION_USER, null);
		Storage.set(SESSION_USER, null);

		// const cookies = new Cookies();
		// cookies.remove(COOKIE_LOGIN);
		// cookies.remove(COOKIE_PASS);
		// cookies.remove(SESSION_USER);
	}
	else
		Storage.set(SESSION_USER, userInfo, SESSION_LIFETIME);

	return {
		type: type,
		payload: userInfo
	}
};
export const setUser = user => {
	return {
		type: USER_SET,
		payload: user
	}
};
export const userSetSettings = (key, val) => {
	return {
		type: USER_SET_SETTINGS,
		payload: {
			key: key,
			value: val
		}
	}
};

import React, {memo, useEffect, useState, useRef, useCallback} from 'react';
import cn from "classnames";
import Scrollbars from "react-custom-scrollbars";
// import * as uniqid from "uniqid";

/*
options - массив объектов
{
	name : 'string',
	value: 'mixed',
	selected: bool
}
 */



const SmartSelect = props => {

	const {
		options,
		// selectedInit = new Set(),
		label = '',
		placeholder = 'Выберите значение',
		//стрелка справа
		arrow=false,
		//показывать поиск по значениям
		withSearch=false,
		//текст поиска
		searchPlaceholder = 'поиск',
		name,
		onSelect = (res, paramName) => {console.log(paramName, res)},
		//выдает результат отсортированный как в списке
		isSorted = true,
		//выбор неск. значений
		multi = false,
		//удаляет элемент при повторной выборе
		deleteOnReselect=false,
		maxHeight=null,
		// selectedOnInit = new Map()
		refresh=false,
		errorMsg = '',
		setErrorMsg = null
	} = props;

	const thisRef = useRef(null);

	let selectedInit = new Map();

	// selectedInit.set('TEST', {
	// 	name: 'test',
	// 	value: 123,
	// });
	//
	// selectedInit.set('dfg', {
	// 	name: 'dfsdfsdf',
	// 	value: 456,
	// });

	// for (let opt of options) {
	// 	console.log(opt);
	// 	if (opt.selected)
	// 		selectedInit.set(name + opt.value, opt);
	// 	console.log(selectedInit.size);
	// }
	options.map((v, k) => {
		if (v.selected) {
			if (multi || !selectedInit.size)
				selectedInit.set(v.value, v);
		}
		return null;
	});
	// if (name== 'pid') {
		// console.clear();
		// console.log('SmartSelect RENDER optsLen', name, options.length);
		// console.log(selectedInit);
	// }


	const [selected, setSelected] = useState(selectedInit);
	const [popupShow, setPopupShow] = useState(false);

	const [showOptions, setShowOptions] = useState(options);

	const [searchText, setSearchText] = useState('');

	// if (name == 'pid') {
	// 	console.log('smart select RENDER: ',
	// 		'name: ' +name,
	// 		'selected: ' + selected.size,
	// 		'selInitSize:' + selectedInit.size,
	// 		'options Len:' + options.length
	// 	);
	// 	// console.dir(options);
	// 	// console.dir(showOptions);
	// 	// console.log(selectedInit.size, selected.size);
	// 	console.dir(selectedInit);
	// 	console.dir(selected);
	// }

	const sortByOptions = data => {
		let sortedOptions;
		// console.log('sortByOptions', isSorted, data);
		if (isSorted) {
			sortedOptions = new Map();
			options.map(itm => {
				// console.log(itm, data.has(itm));
				if (data.has(itm.value))
					sortedOptions.set(itm.value, itm);
			});

		}
		else
			sortedOptions = new Map(data);


		setSelected(sortedOptions);

		if (typeof onSelect === 'function')
			onSelect(sortedOptions, name);

	}

	const selectOption = (item, remove = false) => {
		// console.log('selectOption', item);
		const key = item.value;
		if (multi) {
			let s = selected;
			let changed = false;

			if (remove) {
				s.delete(key);
				changed = true;
			}
			else {
				if (!s.has(key)) {
					s.set(key, item);
					changed = true;
				}
				else if(deleteOnReselect) {
					s.delete(key);
					changed = true;
				}
			}

			if (changed)
				sortByOptions(s);
		}
		else {
			const s = new Map();
			s.set(key, item);



			if (typeof onSelect === 'function')
				onSelect(s, name);

			setSelected(s);
			setPopupShow(false);

			if (typeof setErrorMsg === 'function')
				setErrorMsg(prev => ({
					...prev,
					[name]: ''
				}));
		}
	}
	const clear =() => {
		let empty = new Map();
		setSelected(empty);
		setPopupShow(false);
		if (typeof onSelect === 'function')
			onSelect( new Map(), name);
	}


	const selectedItem = (itm) => {
		return <li key={itm.value}>
			{itm.name}
			{
				multi ?
					<i className="fa fa-times"
					   onClick={e => selectOption(itm, true)}
					></i>
					: null
			}
		</li>;
	}

	const onSearch = str => {
		const opts = [];
		options.map((itm, key) => {
			if (itm.name.toLowerCase().indexOf(str.toLowerCase().toLowerCase()) !== -1)
				opts.push(itm);
		});

		setShowOptions(opts);
		setSearchText(str);
	}

	const hideHandle = (e) => {
		if (thisRef.current && !thisRef.current.contains(e.target))
			setPopupShow(false);

	};

	useEffect(() => {
			window.addEventListener('mousedown', hideHandle);

			if (refresh)
				setSelected(
					// selectedInit
					new Map()
				);

			setShowOptions(options);

			return () => {
				window.removeEventListener('mousedown', hideHandle);
			}
		},
		[options, refresh]
	);


	return (
		<div
			className={cn("smart-select noselect", {"-one": !multi, "-arrowed": arrow})}
			ref={thisRef}
			// onMouseLeave={e => setPopupShow(false)}
			onClick={e => {
				// console.log(errorMsg, typeof setErrorMsg);
				if (errorMsg && typeof setErrorMsg === 'function') {
					setErrorMsg(prev => ({
						...prev,
						[name]: ''
					}));
				}
			}}
		>
			{
				errorMsg ?
					<div className="popup-msg">
						<div>
							{errorMsg}
						</div>
					</div>
					: null
			}
			{
				label ? <div className="label">
					{label}
					{/*/ {showOptions.length}*/}
				</div> : null
			}

			<ul
				className="ss-selected"
				onClick={e => {
					if (!multi || (e.target.nodeName !== 'LI' && e.target.nodeName !== 'I'))
						setPopupShow(true);

				}}
			>
				{
					(placeholder && !selected.size) ? <span className="-ph">{placeholder}</span> : null
				}

				{
					// selected.size
					[...selected.values()].map(itm => selectedItem(itm))
				}
				{
					multi && selected.size != 0
					? <li
							className={"close"}
							title={"Очистить"}
							onClick={() => {
								clear();
							}}
						><i className={"fa fa-close"}/></li>
						: null
				}
			</ul>
			<div className={cn("ss-pop", {show: popupShow})}>
				{
					withSearch ? <div className="ss-srch">
						<input type="text"
							   placeholder={searchPlaceholder}
							   value={searchText}
							   onChange={e => {
								   onSearch(e.target.value)
							   }}
						/>
					</div>
						: null
				}
				<Scrollbars
					autoHide={true}
					autoHeight={true}
					autoHeightMax={maxHeight ? maxHeight : "60vh"}
					// className={"scroll-wrap"}
				>
				<ul className="ss-list">

					{
						showOptions.map(item => {
							return <li
								key={item.value}
								onClick={e => selectOption(item)}
								// onMouseUp={e => selectOption(item)}

								className={cn({selected: selected.has(item.value)})}
							>
								{item.image
									? <div
										className="im"
										style={{
											backgroundImage: `url(${item.image})`
										}}
									></div>
									: null}
								{/*{item.image ? <img src={item.image} /> : null}*/}
								{item.name}
							</li>;
						})
					}
				</ul>
				</Scrollbars>

			</div>
		</div>
	)

};
export default SmartSelect;
// export default memo(SmartSelect);

import React from 'react';
import {Routes, Route} from "react-router-dom";
import {
	LoginContainer, ForgetPassword
} from "containers/user";

const LoginRouter = props => {

	return (
		<Routes>
			{/*регистрации нет, пользователи создаются администратором*/}
			<Route path="/forgetpassword" element={<ForgetPassword {...props} />} />

			<Route path="*" element={<LoginContainer {...props} />} />
		</Routes>
	)

};

export default LoginRouter;

import React from 'react';
import {FormItem} from "components/_common";
import {SiteAccessFieldTypeInfo} from "containers/projects/const";

const FieldContainer = props => {

	const {edit = true, onDelete, id, partId, data} = props;

	let predefinedNames = [];
	SiteAccessFieldTypeInfo.map(ft => {
		predefinedNames.push(ft.name);
		return null;
	});

	const fieldName_name = partId + '-field-name-' + id;
	const fieldName_value = partId + '-field-value-' + id;

	return (
		<div className="site-access-field flx -sb X-alc">
			<div className="col-name">
				<FormItem
					edit={edit}
					name={fieldName_name}
					defVal={data ? data.name : ''}
					label={'Название поля'}
					isRequire={true}
					predefined={predefinedNames}
				/>
			</div>
			<div className="col-val">
				<FormItem
					edit={edit}
					name={fieldName_value}
					defVal={data ? data.value : ''}
					label={'Значение'}
					isRequire={true}
				/>
			</div>
			<div className="col-del">
				<i className={"fa fa-times"}
				   // onClick={(e) => {onDelete(id)}}
				></i>
			</div>
		</div>
	);

}

export default FieldContainer;

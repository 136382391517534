import React from "react";
import * as strtotime from "strtotime";
// import {in_array} from "utils/func";
// import {rolesAdmin, rolesModerator, SITE_URL} from "utils/config";
import {sortingEmpty} from "containers/tasks/TasksListContainer";
import {SITE_URL, SITE_URL_FRONT} from "utils/config";




export const isEditableTask = taskDate => {
	taskDate = taskDate + ' 23:59:00';

	return strtotime(taskDate) * 1000 >= new Date().getTime();
};

export const sortTasks = (tasks, sorting, field) => {
	// console.clear();
	// console.log('sortTasks', field);
	let newTasks = [...tasks];
	let newSorting = {...sortingEmpty};
	// console.dir(sorting);
	let sortDirection = 0;

	if (field == '') {
		for (let f in sorting) {
			if (sorting[f] !== 0) {
				field = f;
				sortDirection = sorting[f];
			}
		}
	}
	else {
		//меняем направление
		sortDirection = sorting[field] ? sorting[field] : 1;
		if (sortDirection == 0)
			sortDirection = 1;
		else
			sortDirection *= -1;
	}

	if (field === '') {
		console.log('sortTasks field isEmpty =>return', field);
		return {tasks: newTasks, sorting: newSorting};
	}


	// console.log('sortDirection', sorting[field], sortDirection);

	// let newSortDirection;
	newTasks.sort((a, b) => {
		// console.log(a[field], b[field], typeof a[field]);

		let aField  = a[field];
		let bField = b[field];

		let sortRes = 0;

		if (typeof aField == 'string') {
			let dt = new Date(aField);
			//простая строка
			if (isNaN(dt.getTime())) {
				aField = aField.toLowerCase();
				bField = bField.toLowerCase();

				if (aField > bField)
					sortRes = 1;
				else if (aField < bField)
					sortRes = -1;
			}
			else {
				aField = dt.getTime();
				bField = new Date(bField).getTime();

				sortRes = aField - bField;

				if (aField - bField > 0)
					sortRes = 1;
				else if (aField - bField < 0)
					sortRes = -1;
			}
		}
		else {
			if (aField - bField > 0)
				sortRes = 1;
			else if (aField - bField < 0)
				sortRes = -1;

		}

		if (field)
			newSorting[ field ] = sortDirection;

		// console.log('sortRes', sortRes);


		return sortDirection == 1 ? sortRes : sortRes * -1;
	});
	// console.dir(tasks);
	// console.dir(newTasks);
	// console.log(sorting, newSorting);

	return {tasks: newTasks, sorting: newSorting};
}


export const taskCommentUrl = (comment) => {
	return `${SITE_URL_FRONT}tasks/${comment.tid}/${comment.id}`;
}
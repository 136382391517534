import React from 'react';
import {decrypt, copyClipboard} from "utils/func";
// import {Button, BUTTON_TYPE} from "components/_common";
import Scrollbars from 'react-custom-scrollbars';
import {URLS} from "utils/config";
import {useNavigate} from "react-router-dom";

const TaskAccess = ({site, underwater, canEdit}) => {

	let navigate = useNavigate();
	let accessList = JSON.parse(decrypt(site.access, underwater, site.salt));

	// console.log(accessList);


	return (
		<Scrollbars
			autoHide={true}
			autoHeight={true}
			// style={{height: 300}}
			autoHeightMin={100}
			autoHeightMax={500}
		>
			<div className={"task-site-info"}>
				{accessList.map((accessItem, i) => {
					// console.log(accessItem);
					return (
						<div key={'site-ai-' + i}>
							<h3>{accessItem.name}</h3>
							{
								(accessItem.fields && accessItem.fields.length)
									? accessItem.fields.map((field, j) => {
										let value = field.value;
										// console.log(value, field.value.indexOf('http'));
										if (field.value.indexOf('http') !== -1)
											value = <a href={field.value} target={"_blank"}>{field.value}</a>

										return <p key={'site-ai-' + i + '-' + j}>
											<span>{field.name}:</span> {value} <i
											className={"fa fa-copy"}
											title={"копировать значение"}
											onClick={() => copyClipboard(field.value)}
										/>
										</p>
									})
									: null
							}
						</div>
					);
				})}
				{
					canEdit
						? <i
							className={"fa fa-edit"}
							onClick={() => navigate(`${URLS.projects}/site/${site.id}`)}
						/>
						: null
				}
			</div>
		</Scrollbars>


	)

};

export default TaskAccess;

import React, {useEffect, useState} from 'react';
import {UsersListItem} from "components/user";
import {in_array, isAdmin} from "utils/func";
import cn from "classnames";
import {rolesModerator, MENU_TITLE} from "utils/config";
// import {preventDuplication} from "react-redux-toastr/lib/utils";

const UsersList = props => {

	const {users, user, onDelete, roleInit, divisions} = props;

	const [usersShow, setUsersShow] = useState(users);
	const [showRole, setShowRole] = useState(roleInit);

	const userIsAdmin = isAdmin(user);

	/**
	 * скрываем сортировки
	 * @param e
	 */
	const handleClickOutside = e => {
		if (e.target.className.indexOf('tbl-sorter-select') === -1) {
			setRoleIsHide(true);
		}
	};

	// useEffect(() => {
	// 	document.addEventListener('mousedown', handleClickOutside);
	// 	setUsersShow(users);
	// 	setShowRole(roleInit);
	// 	// console.log(roleInit);
	//
	// 	return () => {
	// 		// document.removeEventListener("mousedown", handleClickOutside);
	// 		// setUsersShow([]);
	// 		// setShowRole('');
	// 	}
	// },
	// 	[]
	// );

	const [sorter, setSorter] = useState({});
	const usersSort = (field, value) => {
		let sorter_new = sorter;
		sorter_new[field] = value;
		setSorter(sorter_new);


		let stepUsers = users;
		console.log(stepUsers);
		const sortStep = (selUsers, fld, val) => {
			let res = [];
			selUsers.map((user) => {
				if (val == '' || user[fld] == val)
					res.push(user);

			});
			return res;
		};
		for (const fld in sorter) {
			stepUsers = sortStep(stepUsers, fld, sorter[fld]);
		}
		setUsersShow(stepUsers);
	};

	/*
	 * Выбор роли
	 */

	const [roleIsHide, setRoleIsHide] = useState(true);
	const roleOptions = [];
	const divisionOptions = [];
	let usedRolesOptions = [];
	let usedDivisionOptions = [];
	console.log('userIsAdmin', userIsAdmin);
	users.map((_user) => {
		// _user.divId = parseInt(_user.divId);

		// console.log(_user.role);

		if (!in_array(_user.role, usedRolesOptions)) {
			// if (!userIsAdmin && _user.role !== 'admin')
			{
				usedRolesOptions.push(_user.role);
				roleOptions.push({
					label: _user.roleName,
					value: _user.role
				});
			}
		}
		if (!in_array(_user.divId, usedDivisionOptions)) {
			usedDivisionOptions.push(_user.divId);
			divisionOptions.push(
				_user.divId ? _user.division : {id: _user.divId}
			);
		}
	});
	console.dir(roleOptions);
	let roleHeaderSelect = 'Роль';
	if (roleInit === '')
		roleHeaderSelect = (
			<select
				className={"tbl-sorter-select " + (roleIsHide ? 'hidden' : '')}
				onMouseEnter={() => setRoleIsHide(false)}
				onChange={(e) => {
					setRoleIsHide(true);
					usersSort('role', e.target.value);
					setShowRole(e.target.value)
				}}
			>
				<option key={"role-"} value={""} className={"tbl-sorter-select-option"}>Роль</option>
				{roleOptions.map((itm) => {
					return (
						<option
							value={itm.value}
							key={"role-" + itm.value}
							className={"tbl-sorter-select-option"}
						>
							{itm.label ? itm.label : 'без роли'}
						</option>);
				})}
			</select>
		);


	const [sortField, setSortField] = useState('id');
	const [sortAsc, setSortAsc] = useState(true);
	const sortBy = (field) => {
		usersShow.sort((a, b) => {
			if (a[field] > b[field])
				return !sortAsc ? 1 : -1;
			if (a[field] < b[field])
				return !sortAsc ? -1 : 1;

			return 0;
		});
		setSortField(field);
		setSortAsc(!sortAsc);
	};


	let tblColumnRoleTitle = '';

	switch (showRole) {
		default:
			tblColumnRoleTitle = 'Email/Телефон';
			break;
	}
	const showColumnLogin = user.isAdmin || user.isOwner;//isAdmin(user);
	const showColumnDivision = user.isAdmin;
	console.log('showColumnLogin', showColumnLogin);

	return (
		<div className="mt20">
			<table className="tbl users-list">
				<thead>
				<tr>
					<td width="3%" className="_tac">
						&nbsp;&nbsp;
					</td>
					<td width="37%" className={"sortable"} onClick={() => sortBy('name')}>
						Пользователь
						<div className={cn("tbl-sort", {active: sortField === 'name'})}>
							<i className={cn("fa", {"fa-caret-down": sortAsc, "fa-caret-up": !sortAsc})}></i>
						</div>
					</td>
					{
						showColumnLogin
							? <td width="15%">
								Логин
							</td>
							: null
					}
					<td width="15%">
						{roleHeaderSelect}
					</td>
					<td width={"15%"}>
						{tblColumnRoleTitle}
					</td>
					{
						showColumnDivision
							? <><td width="20%">
									{MENU_TITLE.divisions.one}
								</td>
							</>
							: null
					}

				</tr>
				</thead>
				<tbody>

				{usersShow.map((item, i) =>
					<UsersListItem
						key={i}
						sysUser={user}
						user={item}
						divisions={divisions}
						onDelete={onDelete}
						showRole={showRole}
						showColumnLogin={showColumnLogin}
						showColumnDivision={showColumnDivision}
					/>
				)
				}
				</tbody>
			</table>

		</div>
	)

};

export default UsersList;

import {APP_ID} from "utils/config";
import {unixTime} from "utils/func";


const Storage = {
	realKey: (key) => {
		return APP_ID + '_' + key;
	},
	/**
	 *
	 * @param key - ключ
	 * @param value - значение
	 * @param expire - время в секундах
	 */
	set: (key, value, expire = 0) => {
		// console.log('Storage set "' + key + '"; type "' + typeof value + '"; expires sec: ' + expire);
		key = Storage.realKey(key);
		if (value === null) {
			localStorage.removeItem(key);
			localStorage.removeItem(key + '_exp');
			return;
		}

		localStorage.setItem(
			key,
			typeof value == 'object'  ? JSON.stringify(value) : value
		);

		if (expire)
			localStorage.setItem(key + '_exp', unixTime() + expire);

	},
	get: (key, defaultValue = null, isObject) => {
		key = Storage.realKey(key);
		let value = localStorage.getItem(key);
		if (value === null)
			return defaultValue;

		const expDate = localStorage.getItem(key + '_exp');

		// console.log(key +' exp time: ' + expDate);
		// console.log('now time: ' + unixTime());
		// console.log(expDate < unixTime());
		if (expDate && expDate < unixTime()) {
			localStorage.removeItem(key);
			localStorage.removeItem(key + '_exp');
			return defaultValue;
		}

		if (isObject)
			value = JSON.parse(value);

		return value;
	},
	clear: () => {
		localStorage.clear();
	},
	session_get: (key, defaultValue = null, isObject) => {
		let value = sessionStorage.getItem(key);
		if (value === null)
			return defaultValue;
		if (isObject)
			value = JSON.parse(value);
		return value;
	},
	session_set: (key, value) => {
		if (value === null)
			return sessionStorage.removeItem(key);
		return sessionStorage.setItem(
			key,
			typeof value == 'object'  ? JSON.stringify(value) : value
		);
	}
}
export default Storage;
import React, {useEffect, useState, createRef} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {ContentHeader, Button, BUTTON_TYPE, FormItem, Win} from "components/_common";
import {API_URLS, MENU_TITLE, MESSAGES} from "utils/config";
import {apiSend, dateFormat, encrypt, decrypt} from "utils/func";
import {useNavigate, useParams} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import SiteAccess from "components/projects/SiteAccess";
import * as uniqid from "uniqid";


const SiteEditContainer = props => {
	// console.log('SiteEditContainer');

	const {user, isNew = false} = props;
	let {id} = useParams();

	let siteId = !isNew ? parseInt(id) : 0;
	let projectId = isNew ? parseInt(id) : 0;
	// console.clear();
	// console.log(id, 'isNew', isNew, siteId, projectId);

	const [project, setProject] = useState(null);
	const [site, setSite] = useState(null);
	const [salt, setSalt] = useState(uniqid() + uniqid());
	const [secretKey, setSecretKey] = useState('');
	const formRef = createRef();

	let navigate = useNavigate();

	// console.log(salt, salt.length);
	// const skey = '123';
	// var ciphertext = CryptoJS.AES.encrypt('my message', skey).toString();
	// var bytes  = CryptoJS.AES.decrypt(ciphertext, skey);
	// var originalText = bytes.toString(CryptoJS.enc.Utf8);//CryptoJS.enc.Utf8
	// console.log(ciphertext, '/', originalText);

	const getData = async () => {
		await apiSend(
			API_URLS.projects,
			{
				action: 'getSite',
				userId: user.id,
				siteId: siteId,
				projectId: projectId,
			},
			(res, headers) => {
				// console.log(headers.pragma);
				if (headers.pragma === undefined || headers.pragma === '')
					toastr.error('Критическая ошибка', 'С сервера не получен секретный ключ');
				else {
					// console.dir(res.site.access);
					setSecretKey(headers.pragma);
					setProject(res.project);

					if (res.site.hasOwnProperty('id')) {
						let _salt = salt;
						if (res.site.salt && res.site.salt != '') {
							setSalt(res.site.salt);
							_salt = res.site.salt;
						}
						let access = '';
						if (res.site.access != '')
							access = decrypt(
								res.site.access,
								headers.pragma,
								_salt
							);
						// console.dir(access);
						res.site.access = access ? JSON.parse(access) : [];


						setSite(res.site);
					}
				}
			}
		)
	}

	useEffect(() => {
		let isSubscribe = true;

		if (isSubscribe)
			getData().then(null);

		return () => isSubscribe = false
	}, []);

	const save = async (isReturn) => {

		const form = formRef.current;
		const sendData = {
			action: 'saveSite',
			userId: user.id,
			id: siteId,
			projectId: project.id,
			domain: form.domain.value,
			txt: form.txt.value,
			publish: form.publish.checked ? 1 : 0,
			access: []
		};

		const accessData = {};
		// console.dir(form.elements);
		// console.dir(sendData);
		// return;

		[...form.elements].map((fld, k) => {

			const fields = [];
			// console.log(k);
			// console.log('fieldName:', fld.name);
			if (fld.name.indexOf('part-name-') >= 0) {
				console.log('partName:', fld.name);
				const partName = fld.value;
				const partId = fld.name.replace('part-name-', '');
				// console.log('Part:', partId, partName);

				if (!partName) {
					toastr.error('Ошибка заполнения', 'Не указано назание раздела');
				} else
					accessData[partId] = {
						name: partName,
						fields: {}
					};
			}

			if (fld.name.indexOf('-field-name-') >= 0) {
				// console.log('fieldName:', fld.name);
				const [fieldPartId4name, fieldId4name] = fld.name.split('-field-name-', 2);
				// console.log('Field 4 name:', fieldPartId4name, fieldId4name);
				if (accessData[fieldPartId4name]) {
					if (!accessData[fieldPartId4name].fields[fieldId4name])
						accessData[fieldPartId4name].fields[fieldId4name] = {
							name: '',
							value: ''
						};
					accessData[fieldPartId4name].fields[fieldId4name].name = fld.value;
				}
				// accessData[ fieldPartId ].fields[ fieldId ].name = fld.value;
			}
			if (fld.name.indexOf('-field-value-') >= 0) {
				// console.log('fieldName:', fld.name);
				const [fieldPartId4value, fieldId4value] = fld.name.split('-field-value-', 2);
				// console.log('Field 4 value:', fieldPartId4value, fieldId4value);
				accessData[fieldPartId4value].fields[fieldId4value].value = fld.value;
			}

		});
		// console.dir(accessData);
		const accessDataArray = [];

		Object.keys(accessData).map((partId) => {
			var part = {
				name: accessData[partId].name,
				fields: []
			};
			// Object.entries(accessData[ partId ].fields)
			// console.log(partId);
			Object.keys(accessData[partId].fields).map((fieldId, i) => {
				// console.log(fieldId, i);
				// console.dir(accessData[ partId ].fields[ fieldId ]);
				part.fields.push(accessData[partId].fields[fieldId]);
			});
			// console.dir(part);

			accessDataArray.push(part);
		})
		// console.dir(accessDataArray);

		// sendData.access = accessDataArray;
		sendData.access = encrypt(JSON.stringify(accessDataArray), secretKey, salt);
		sendData.salt = salt;

		await apiSend(
			API_URLS.projects,
			sendData,
			(res) => {
				if (isReturn)
					navigate('/projects');

			}
		);

	};

	const onDelete = async () => {

		toastr.confirm(
			MESSAGES.confirmAction,
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: async () => {
					await apiSend(
						API_URLS.projects,
						{
							action: 'deleteSite',
							userId: user.id,
							id: siteId
						},
						() => {
							navigate('/projects');
						}
					);
				},

			}
		);


	}


	const chParent = [
		{url: '/projects', name: MENU_TITLE.projects.many}
	];

	// if (project)
	// 	chParent.push({url: '/projects/edit/' + project.id, name: project.name});

	let info = [];
	if (site && site.created) {
		info.push(
			<div className="edited-info" key={"info-create"}>
				Добавлен: {dateFormat(site.created)}
			</div>
		);
	}


	return (
		<>
			<ContentHeader
				parent={chParent}
				title={site ? site.domain : 'Новый сайт'}
				// title={"asdasd"}
			/>
			<Win
				onClose={() => navigate('/projects')}
				footer={<div className={"win-ftr-btns flx -sb"}>
					<div>
						<Button type="apply" onClick={() => save(false)}/>
						<Button type="save" onClick={() => save(true)}/>
						<Button type="close" onClick={() => navigate('/projects')}/>
					</div>
					<Button type={BUTTON_TYPE.delete} onClick={() => onDelete()}/>
				</div>}
			>
				<form ref={formRef}>
					<FormItem
						name={"domain"}
						label={"Доменное имя"}
						descr={"Без протокола http(s)://"}
						isRequire={true}
						defVal={site ? site.domain : ''}
						maxLength={150}
					/>
					<FormItem
						fieldType={"txt"}
						name={"txt"}
						label={"Описание"}
						defVal={site ? site.txt : ''}
						maxLength={300}
					/>
					<FormItem
						fieldType={"bool"}
						name={"publish"}
						value={1}
						label={"Опубликован"}
						isChecked={!site || !site.publish}
					/>
					<hr/>
					<h2>Доступы</h2>
					<SiteAccess
						data={(site ? site.access : [])}
						siteId={site ? site.id : 0}
					/>
				</form>
				<div className={"_tar"}>
					{info}
				</div>
			</Win>
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(SiteEditContainer);

import React, {useEffect, useState, createRef} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import {useParams, useNavigate} from "react-router-dom";
import {ContentHeader, Win, Button} from "components/_common";
import {in_array, apiSend, uploadFileInfo} from "utils/func";
import {rolesModerator, rolesAdmin, API_URLS} from "utils/config";
import {UserEdit} from "components/user";
import {hashPassword} from "containers/user/LoginContainer";
import {setUser} from "actions/UserActions";


const UserEditContainer = props => {

	const {user, actions, edit = true, editSelf = false} = props;

	let { id = -1, roleName = 'user' } = useParams();
	const userId = (editSelf && user) ? parseInt(user.id) : parseInt(id);
	// console.log(userId);

	let navigate = useNavigate();

	const formRef = createRef();
	const avatarFileRef = createRef();

	const [userData, setUserData] = useState(null);
	const [divisions, setDivisions] = useState(null);

	// let apiActionProcess = false;
	const onLoad = async (isSubscribe) => {
		if (!isSubscribe)
			return;

		await apiSend(
			API_URLS.user,
			{
				action: 'getUser',
				userId: user.id,
				id: userId
			},
			(res) => {
				// console.dir(res);
				if (res.user)
					setUserData(res.user);
				if (res.divisions)
					setDivisions(res.divisions);

			},
			actions
		);

	};
	useEffect( () => {
		let isSubscribe = true;

		onLoad(isSubscribe).then(null);

		return () => {
			isSubscribe = false;
			setUserData(null);
			setDivisions(null);
		}
	}, [userId]);


	const saveUser = async (isReturn = false) => {

		const form = formRef.current;
		const avatarFile = avatarFileRef.current;

		let err = [];

		if (form.login.value == '')
			err.push('Логин не задан');

		if (!userId && form.password.value == '')
			err.push('Пароль не задан');

		if (form.name.value == '')
			err.push('Имя не задано');

		// if (form.email.value == '')
		// 	err.push('Email не задан');

		if (err.length) {
			{err.map((txt, i) => {
				toastr.error('Ошибка заполнения', txt);
			})}
		}
		else {

			let password = form.password.value.trim();
			if (password != '')
				password = hashPassword(password);
			// actions.showLoading();

			const formData = new FormData();

			formData.append('action', 'save');
			formData.append('userId', user.id);

			const data = {
				id: userData ? userData.id : 0,
				login: form.login.value,
				password: password,
				role: form.role ? form.role.value : userData.role,
				name: form.name.value,
				email: form.email.value,
				phone: form.phone.value,
				info: form.info.value,
				num: (form.num != undefined && form.num.value.trim() != '') ? parseInt(form.num.value) : 0,
				divisions: [],
				mailing: form.mailing.checked ? 1: 0
			};

			if (form.divisions)
				Array.from(form.divisions).map(itm => {
					if (itm.checked)
						data.divisions.push(itm.value);
				});

			// console.log(avatarFile.files[0]);
			if (avatarFile.files[0])
				formData.append('avatar', avatarFile.files[0]);
			formData.append('data', JSON.stringify(data));


			// console.dir(avatarFile.files[0]);
			// console.log(form.mailing.checked);
			// console.dir(data);
			// return;
			await apiSend(
				API_URLS.user,
				// data,
				formData,
				(res) => {

					if (isReturn)
						navigate('/user');

					if (editSelf)
						actions.setUser(res.user);

					// console.dir(res);
					// if (res.user)
					// 	setUserData(res.user);
					// if (res.divisions)
					// 	setDivisions(res.divisions);
				},
				actions
			);
		}

	};

	let contentHeaderParent = [];
	if (in_array(user.role, rolesModerator))
		contentHeaderParent = [
			{url: '/user', name: "Пользователи"},
		];

	let onClose = null;
	if (!editSelf)
		onClose = () => navigate('/user');

	let content = null;
	if (divisions)
		content = <UserEdit
			userId={userId}
			user={user}
			formRef={formRef}
			userData={userData}
			edit={edit}
			divisions={divisions}
			rolesList={user.userRoles}
			avatarFileRef={avatarFileRef}
			roleNameDefault={roleName}
			editSelf={editSelf}
		/>;

	let title;
	if (edit)
		title = userId ? 'Редактирование' + (editSelf ? ' профиля' : '') : 'Создание пользователя';
	else
		title = 'Информация о пользователе';

	let editButton = null;
	if (in_array(user.role, rolesAdmin) || (userData && userData.divId == user.divId && in_array(user.role, rolesModerator)))
		editButton = <Button
			type="save"
			title={"Редактировать"}
			onClick={() => navigate('/user/edit/' + userId)}
		/>;
	// console.log(editSelf);

	return (
		<>
			<ContentHeader
				parent={contentHeaderParent}
				title={title}
			/>
			<Win
				onClose={onClose}
				footer={
					edit ?
						(
							<div className={"win-ftr-btns"}>
								<Button type="apply" onClick={() => saveUser(0)}/>
								{!editSelf ? (
									<>
										<Button type="save" onClick={() => saveUser(1)}/>
										<Button type="close" onClick={() => navigate('/user')} />
									</>
								) : null
								}
							</div>
						)
						: editButton
				}
			>
				{content}
			</Win>
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, setUser,
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserEditContainer);

import React, {useState, useEffect, useRef} from 'react';
import {bindActionCreators} from "redux";
import {useLocation, useParams} from "react-router-dom";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {API_URLS, MENU_TITLE, MESSAGES, CAN_EDIT_COMMENTS_TIME} from "utils/config";
import {apiSend, confirmApiAction, copyClipboard} from "utils/func";
import {ContentHeader} from "components/_common";
// import {setCalendarSelectDateAction, setSearchAction} from "actions/GUIActions";
import {TaskShow, Comment, CommentEditor, CommentsList} from "components/tasks";
import striptags from "striptags";
import {toastr} from "react-redux-toastr";
// import * as strtotime from "strtotime";

const TasksShowContainer = props => {

    const {GUI, user, actions} = props;
    let {id = 0, commentId = 0} = useParams();
    id = parseInt(id);
    commentId = parseInt(commentId);

    console.log(`TasksShowContainer id=${id} commentId=${commentId}`);
    const [taskData, setTaskData] = useState(null);
    const taskDataRef = useRef(taskData);
    const location = useLocation();

    // console.dir(taskData);


    const setTaskDataRef = td => {
        taskDataRef.current = td;
        setTaskData(td);
        console.log(`setTaskDataRef, commentId=${commentId}`);

        // if (location.hash != '') {
        // 	scrollToComment(location.hash.replace('#', ''));
        // }
        if (commentId)
            scrollToComment(commentId);
    }

    //показать редактор для НОВОГО комментария
    const [editCommentShow, setEditCommentShow] = useState(false);
    //ИД редактируемого комментария
    const [editCommentId, setEditCommentId] = useState(0);

    // let navigate = useNavigate();
    // console.dir(window.location);

    let apiActionProcess = false;
    const getTask = async (isSubscribe = true, id, onlyComments = false) => {
        console.log('TasksShowContainer getTask ' + id);
        if (!isSubscribe)
            return;

        if (!apiActionProcess) {
            apiActionProcess = true;
            await apiSend(
                API_URLS.tasks,
                {
                    action: 'getTask',
                    id: id,
                    urlHash: window.location.hash
                },
                (res) => {
                    // console.dir(res.comments);
                    setTaskDataRef(res);
                    apiActionProcess = false;
                },
                actions
            );
        }
    }

    const saveComment = async (commentData, callback) => {
        // console.clear();
        // console.log('addComment');
        // console.dir(commentData);

        if (commentData.txt === undefined || striptags(commentData.txt).trim() === '')
            toastr.error(MESSAGES.toastTitleError, 'Впишите текст комментария');

        const formData = new FormData();

        formData.append('action', 'saveComment');
        // formData.append('taskId', id);
        // formData.append('data', JSON.stringify(commentData));
        formData.append('data', JSON.stringify({...commentData, taskId: id}));
        if (commentData.files4upload && commentData.files4upload.length) {
            commentData.files4upload.map((f, i) => {
                formData.append(`file_${i}`, f);
            })
        }
        // setEditCommentShow(false);
        // return;

        if (!apiActionProcess) {
            apiActionProcess = true;
            await apiSend(
                API_URLS.tasks,
                formData,
                (res) => {
                    // console.dir(res);

                    const {comment = null, isNew = false, commentsTotal = 0} = res;

                    if (comment) {


                        let _comments = [...taskData.comments];
                        if (isNew) {
                            _comments = [res.comment].concat(_comments);
                        }
                        else {
                            _comments.map((cmt, i) => {
                                if (cmt.id === comment.id)
                                    _comments[i] = comment;
                            });
                        }

                        // setTaskData(prev => ({
                        // 	...prev,
                        // 	comments: _comments
                        // }));
                        let td = {...taskDataRef.current};
                        td.comments = _comments;
                        td.commentsTotal = commentsTotal;
                        setTaskDataRef(td);

                        setEditCommentShow(false);
                        setEditCommentId(0);

                        if (typeof callback === 'function')
                            callback();

                    }
                    apiActionProcess = false;
                },
                actions
            );
        }
    }
    const deleteComment = (commentId) => {

        const commentDiv = document.getElementById(`comment-${commentId}`);


        confirmApiAction(
            API_URLS.tasks,
            {
                action: 'deleteComment',
                id: commentId
            },
            (res) => {
                const {deleted = false, commentsTotal = 0} = res;
                console.log(deleted);
                if (deleted && taskData.comments) {
                    let _comments = [];
                    taskData.comments.map((cmt, i) => {
                        if (cmt.id !== commentId)
                            _comments.push(cmt);
                    });

                    let td = {...taskDataRef.current};
                    td.comments = _comments;
                    td.commentsTotal = commentsTotal;

                    commentDiv.className += ' win-hide';

                    setTimeout(() => setTaskDataRef(td), 201);
                    // setTaskDataRef(td);

                    //
                    // setTaskData(prev => ({
                    // 	...prev,
                    // 	comments: _comments
                    // }));
                }
            },
            actions
        )
    }
    const replyComment = async (commentData, callback) => {
        console.clear();
        console.log('replyComment');
        // console.dir(commentData);
        await saveComment(commentData, callback);
    }

    const setStatus = async (id, status) => {
        if (!apiActionProcess) {
            apiActionProcess = true;
            await apiSend(
                API_URLS.tasks,
                {
                    action: 'setStatus',
                    id: id,
                    status: status
                },
                (res) => {

                    setTaskData(prev => ({
                        ...prev,
                        task: {
                            ...prev.task,
                            status: status
                        }
                    }));
                    let td = {...taskDataRef.current};
                    td.task.status = status;
                    setTaskDataRef(td);
                    apiActionProcess = false;
                },
                actions
            );
        }
    }

    const loadComments = async () => {
        // console.clear();
        // console.log(taskData);

        let sum = window.innerHeight + document.documentElement.scrollTop + 200;
        // console.log(`sum=${sum} - ${document.scrollingElement.scrollHeight}`);
        if (sum >= document.scrollingElement.scrollHeight) {
            console.clear();
            console.log(`loadComments`);

            const exceptId = [];

            const {comments = [], commentsTotal = -1} = taskDataRef.current ? taskDataRef.current : {};

            if (comments.length < commentsTotal)
                comments.map(cmnt => exceptId.push(cmnt.id));

            console.log(`commentsTotal=${commentsTotal}; exceptId len = ${exceptId.length}`);

            if (exceptId.length && !apiActionProcess) {
                console.log('DO getComments');
                apiActionProcess = true;
                await apiSend(
                    API_URLS.tasks,
                    {
                        action: 'getComments',
                        id: id,
                        exceptId: exceptId
                    },
                    (res) => {
                        console.dir(res);
                        apiActionProcess = false;
                        let td = {...taskDataRef.current};
                        td.comments = td.comments.concat(res.comments);
                        td.commentsTotal = res.commentsTotal;
                        setTaskDataRef(td);

                    }
                );
            }


            /*
            if (exceptId.length) {

            }
            else {
                //не надо выключать обходчик, чтобы дальше его не включать нигде
                // console.log('removeEventListener > loadComments');
                // setCommentsScrollListener(false);
            }*/


        }

    };

    const addNewComment = (comment = null) => {

        if (!comment)
            return;
        console.log('addNewComment');
        console.dir(comment);
        let td = {...taskDataRef.current};
        td.comments.unshift(comment);
        setTaskDataRef(td);


    };

    let commentViewedId = [];
    const commentSetViewed = async (commentId, timeout = 500) => {
        console.log('commentSetViewed', commentId);
        commentViewedId.push(commentId)


        setTimeout(async () => {
            await apiSend(
                API_URLS.tasks,
                {
                    action: 'commentSetViewed',
                    idList: commentViewedId
                },
                (res) => {
                    // console.log('commentSetViewed ReS', res);
                    commentViewedId = [];
                },
                actions
            );
        }, timeout);

    }

    const commentSetPin = async (commentId, isPinned) => {
        console.log(`commentSetPin ${commentId}=${isPinned}`);

        await apiSend(
            API_URLS.tasks,
            {
                action: 'commentSetPin',
                id: commentId,
                isPinned: isPinned
            },
            async (res) => {
                console.log(res);
                // if (res.isPinned) {
                //     // await getTask(true, id);
                //
                //
                // }

                let td = {...taskDataRef.current};
                // td.comments = td.comments.concat(res.comments);
                td.commentsTotal = res.commentsTotal;
                setTaskDataRef(td);
            },
            actions
        );
    }


    useEffect(() => {
            let isSubscribe = true;
            getTask(isSubscribe, id).then(null);

            // setCommentsScrollListener();
            window.addEventListener('scroll', loadComments);

            if (GUI.socket) {
                GUI.socket.on(process.env.REACT_APP_SOCKET_EMIT_NOTIFY, (notification, ackFn) => {
                    console.clear();
                    console.log('notify !!!!!');
                    console.dir(notification);

                    if (notification.type == 'task' && notification.taskId !== undefined && notification.taskId === id) {


                        if (notification.comment !== undefined) {
                            console.log(`newCommentsInTask=${notification.taskId} commentId=${notification?.comment.id}`);
                            addNewComment(notification.comment);
                        }
                        if (notification.status !== undefined && taskData && taskData.task && taskData.task?.status != notification.status) {
                            console.log('%c TODO CHANGE status', 'color:  yellow');

                            // let td = {...taskDataRef.current};
                            // td.task['status'] = notification.status;
                            // setTaskDataRef(td);
                            // setTaskData(td);
                            // setTaskData(prev => ({
                            //     ...prev,
                            //     task: {
                            //         ...prev.task,
                            //         status: notification.status
                            //     }
                            //
                            // }));
                            // setTaskData({
                            //     ...taskData,
                            //     task: {
                            //         ...taskData.task,
                            //         status: notification.status
                            //     }
                            // });
                        }
                    }
                    ackFn(`socket.on ${process.env.REACT_APP_SOCKET_EMIT_NOTIFY}`);
                });
            }

            return () => {
                isSubscribe = false;
                // setCommentsScrollListener(false);
                window.removeEventListener('scroll', loadComments);
                if (GUI.socket)
                    GUI.socket.off(process.env.REACT_APP_SOCKET_EMIT_NOTIFY);
            }
        },
        []
    );
    const scrollToComment = (commentId) => {
        // console.log(`scrollToComment ${commentId}`);
        const elem = document.getElementById(`comment-${commentId}`);

        if (elem) {
            const y = elem.getBoundingClientRect().top + window.pageYOffset - 120;
            // console.log(elem, y);
            window.scrollTo({top: y, behavior: 'smooth'});
            // elem.scrollIntoView({behavior: 'smooth'});
            // let clsWas = elem.className;
            elem.className += ' scrolled';
            setTimeout(() => {
                // elem.className = clsWas;
                elem.classList.remove('scrolled');
            }, 1500);
        }
    }

    useEffect(() => {
        scrollToComment(location.pathname.split('/').pop());

    }, [location]);


    let commentsContent = [];
    if (taskData && taskData.comments && taskData.comments.length > 0) {

        let commentsPinned = [], commentsNotPinned = [];
        let commentsPinnedParentId = -1;
        taskData.comments.map(com => {

           if (com.pinned) {
               commentsPinned.push(com);
               if (com.cid != 0 && commentsPinnedParentId === -1)
                   commentsPinnedParentId = com.cid;
           }
           else
               commentsNotPinned.push(com);
        });

        console.log('commentsPinned', commentsPinned.length);

        if (commentsPinned.length) {
            commentsContent.push(
                <CommentsList
                    key={`comments-list-pinned`}
                    comments={commentsPinned}
                    parentCommentId={commentsPinnedParentId !== -1 ? commentsPinnedParentId : 0}
                    editCommentId={editCommentId}
                    user={user}
                    saveComment={saveComment}
                    commentSetViewed={commentSetViewed}
                    deleteComment={deleteComment}
                    replyComment={replyComment}
                    setEditCommentId={setEditCommentId}
                    commentSetPin={commentSetPin}
                />
            );
        }

        commentsContent.push(
            <CommentsList
                key={`comments-list-all`}
                comments={commentsNotPinned}
                parentCommentId={0}
                editCommentId={editCommentId}
                user={user}
                saveComment={saveComment}
                commentSetViewed={commentSetViewed}
                deleteComment={deleteComment}
                replyComment={replyComment}
                setEditCommentId={setEditCommentId}
                commentSetPin={commentSetPin}
            />
        );


        // taskData.comments.map((comment, i) => {
        //     // console.dir(comment);
        //     // console.log(strtotime(comment.created));
        //     if (comment.id === editCommentId)
        //         commentsContent.push(
        //             <CommentEditor
        //                 key={`cmnt-${i}-${comment.id}`}
        //                 user={user}
        //                 saveComment={saveComment}
        //                 showEditor={true}
        //                 setEditCommentShow={(isShow) => {
        //                     //TODO когда отмена, надо скрыть редактор комментария
        //                     if (!isShow)
        //                         setEditCommentId(0);
        //                 }}
        //                 comment={comment}
        //             />
        //         );
        //     else
        //         commentsContent.push(
        //             <Comment
        //                 key={`cmnt-${comment.id}`}
        //                 user={user}
        //                 comment={comment}
        //                 // canEdit={canEditComment(user, comment)}
        //                 onDelete={canDeleteComment(user, comment) ? deleteComment : null}
        //                 onEdit={canEditComment(user, comment) ? setEditCommentId : null}
        //                 commentSetViewed={commentSetViewed}
        //
        //                 replyComment={replyComment}
        //             />
        //         );
        // })
    }

    return (
        <>
            <ContentHeader
                // title={`Задача #${id} `}
                title={
                    (taskData && taskData.task)
                        ? taskData.task.name
                        : ''}
                parent={[{url: '/tasks', name: MENU_TITLE.tasks.many}]}
                showDates={false}
            />

            {
                taskData ? <TaskShow
                    {...taskData}
                    user={user}
                    setStatus={setStatus}
                /> : null
            }
            <h2>Комментарии</h2>
            <div className="mt20">
                <CommentEditor
                    user={user}
                    saveComment={saveComment}
                    showEditor={editCommentShow}
                    setEditCommentShow={setEditCommentShow}
                />
            </div>

            {
                (commentsContent.length)
                    ? (
                        <>
                            <div className="comments mt20">
                                {commentsContent}
                            </div>
                        </>
                    )
                    : null
            }

        </>
    )

};


const mapStateToProps = store => ({
    user: store.user,
    GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        hideLoading, showLoading
    }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TasksShowContainer);


const canEditComment = (user, comment) => {

    return user.id === comment.uid
        &&
        (new Date(comment.created).getTime() >= Date.now() - CAN_EDIT_COMMENTS_TIME);
}
const canDeleteComment = (user, comment) => {
    // console.log(comment.created, new Date(comment.created).getTime());
    return user.id === comment.uid || user.isAdmin;
}

import React, {useState} from 'react';
import {
    Button,
    Win,
    FormItem,
    SmartSelect,
    FileUploader
} from "components/_common";
import {SITE_URL} from "utils/config";
import {dateFormat, copyClipboard} from "utils/func";
import VisibilitySensor from "react-visibility-sensor";
import cn from "classnames";
import {taskCommentUrl} from "./utils";
import {CommentEditor} from "./index";


const Comment = ({
                     comment, onDelete = null, onEdit = null, commentSetViewed,
                     replyComment = null,
                     user = null,
                     commentSetPin = null,
                 }) => {

    const avatar = comment.avatar
        ? <img src={SITE_URL + comment.avatar}/>
        : <i className={"fa fa-user"}/>;

    const isViewed = (comment.viewDate && parseInt(comment.viewDate) !== 0);

    const [showFiles, setShowFiles] = useState(!isViewed);
    const [showReply, setShowReply] = useState(
        // true
        false
    );

    // console.log(onEdit);
    console.dir(comment.pinned);

    // let anchor = `${window.location.origin}${window.location.pathname}#comment-${comment.id}`;

    let anchor = taskCommentUrl(comment);

    let pinned = <a href={"#"}
        title={'Прикрепленный комментарий, будет отображаться всегда сверху'}
                    onClick={e => {
                        e.preventDefault();
                        if (commentSetPin)
                            commentSetPin(comment.id, !comment.pinned);
                    }}
    >
        <i className={"fa fa-star" + (!comment.pinned ? '-o': '')}/> {comment.pinned ? 'Открепить' : 'Прикрепить'}
    </a>


    let header = (
        <div className={"comment-hdr"}>
            <div
                className="nm"
                key={`cm-hdr-nm-${comment.id}`}
            >
                <div className="avatar">
                    {avatar}
                </div>
                <b>{comment.userName}</b>
                <span>{dateFormat(comment.created, 'd.m.Y H:i')}</span>
            </div>
            <div
                className="controls"
                key={`cm-hdr-ct-${comment.id}`}
            >
                {/*<a href="#"*/}
                {/*   onClick={e => {*/}
                {/*       e.preventDefault();*/}
                {/*       setShowReply(!showReply);*/}
                {/*   }}*/}
                {/*>*/}
                {/*    <i className={"fa fa-reply"}/> Ответить*/}
                {/*</a>*/}
                <a
                    href={anchor}
                    onClick={e => {
                        e.preventDefault();
                        copyClipboard(anchor);
                    }}
                    title={"Ссылка на комментарий, кликните для копирования"}
                >
                    <i className={"fa fa-hashtag"}/> {comment.id}
                </a>
                {pinned}
                {
                    onEdit
                        ? <a href="#"
                             onClick={e => {
                                 e.preventDefault();
                                 onEdit(comment.id);
                             }}
                        >Редактировать</a>
                        : null
                }
                {
                    onDelete
                        ? <a href="#"
                             onClick={e => {
                                 e.preventDefault();
                                 onDelete(comment.id);
                             }}
                        >Удалить</a>
                        : null
                }

            </div>
        </div>
    );


    let footer = null;

    if (comment.files && comment.files.length)
        footer =
            <div
                key={`comment-${comment.id}-files`}
                className="comment-files"
            >
                {
                    showFiles
                        ? <FileUploader
                            canUpload={false}
                            maxFileSize={0}
                            acceptExtensions={[]}
                            isMinimized={false}
                            files={comment.files}
                            // onDelete={deleteFile}
                        />
                        : <div
                            className="-open"
                            onClick={() => setShowFiles(true)}
                        >
                            <span>Показать файлы ({comment.files.length} шт)</span>
                        </div>
                }

            </div>
        ;

    // let txt = comment.txtFormatted;

    let content = <div
        className="task-txt"
        dangerouslySetInnerHTML={{__html: comment.txtFormatted}}
    />;
    if (!isViewed)
        content = <VisibilitySensor
            onChange={async (isVisible) => {
                if (isVisible)
                    await commentSetViewed(comment.id);
                // console.log('comment view', comment.id, isVisible);
            }}
            delayedCall={true}
        >
            {content}
        </VisibilitySensor>

    let replyEditor = null;
    if (replyComment !== null && showReply)
        replyEditor = <CommentEditor
            user={user}
            saveComment={(commentData, callback) => {
                setShowReply(false);
                replyComment(commentData, callback);
            }}
            showEditor={true}
            setEditCommentShow={(isShow) => {
                // console.log(`${isShow}`);

                setShowReply(isShow);
            }}
            comment={{
                cid: comment.id,
            }}
        />;

    return (
        <div className={"comment-box"}>
            <Win
                id={`comment-${comment.id}`}
                header={header}
                footer={footer}
                winClass={cn("comment-item",{'new anim-win': comment.isNew != undefined, pinned: comment.pinned})}
                onClick={() => {
                    if (comment.viewDate == 0)
                        commentSetViewed(comment.id, 0)
                }}
            >
                {/*{isViewed ? 'Viewed' : 'not Viewed'}*/}
                {content}
            </Win>

            <div className={"comment-children"}>
                {replyEditor}
            </div>
        </div>
    )

};

export default Comment;

import React, {Component, Fragment} from 'react';
import {BrowserRouter as Router, Routes, Route, NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import * as Containers from "containers";
import Page404 from "components/Page404";
import Storage from "utils/Storage";
import {bindActionCreators} from "redux";
import {SESSION_USER, userAuth, USER_LOGIN} from "actions/UserActions";
import {sideBarToggle, setSocket} from "actions/GUIActions";
import {APP_TITLE, SOCKET_URL} from "utils/config";
import {toastr} from "react-redux-toastr";
import {io} from "socket.io-client";
import * as Routers from "routers/index";
import {createNotification, NotificationType} from "./utils/func";


class Routing extends Component {
	sessionUser = null;
	constructor(props) {
		super(props);
		this.sessionUser = Storage.get(SESSION_USER, null, true);

		if (this.sessionUser)
			this.props.actions.userAuth(USER_LOGIN, this.sessionUser);


	};

	connectSocket(user) {

		const {actions} = this.props;

		// console.dir(user);

		const RECONNECTION_ATTEMPTS = 10;
		let attempts = 0;
		// console.clear();
		// console.dir( process.env);
		if (SOCKET_URL) {
			if (user) {
				// console.log('connectSocket inside routing');

				const socket = io(SOCKET_URL, {
					reconnectionDelay: 15000,
					reconnectionDelayMax: 50000,
					reconnectionAttempts: RECONNECTION_ATTEMPTS,
					auth: {
						// token: (this.sessionUser && this.sessionUser.token) ? this.sessionUser.token : ''
						token: user.token
					},
					query: {
						url: window.location.pathname,
						browserWidth: window.outerWidth,
						browserHeight: window.outerHeight,
					}
				});
				// console.log(socket.connected);
				socket.on('connect', () => {
					actions.setSocket(socket);
					console.log(`Подключено к удаленному серверу, id=${socket.id}`, SOCKET_URL, new Date());
					// console.dir(socket);
					// toastr.success('Подключено к удаленному серверу ' + SOCKET_URL);
				});
				socket.on("connect_error", () => {
					console.error('Невозможно подключиться к удаленному серверу', SOCKET_URL);
					attempts++;

					if (user && user.role == 'admin' && attempts >= RECONNECTION_ATTEMPTS) {
						toastr.error(`Невозможно подключиться к удаленному серверу ${SOCKET_URL}. Система работает без обновлений "на лету"`, '', {timeOut: 0});

						socket.disconnect();
					}
				});

				socket.on(process.env.REACT_APP_SOCKET_EMIT_ERROR, (err, ackFn) => {
					console.log('error from socket:', err);
					toastr.error('Ошибка (socket)', err);

					// ackFn(`socket.on ${process.env.REACT_APP_SOCKET_EMIT_ERROR}`);
				});
				socket.on(process.env.REACT_APP_SOCKET_EMIT_MESSAGE, (msg, ackFn) => {
					console.log('msg from socket:', msg);
					toastr.info('Сообщение (socket)', msg);
					// ackFn(`socket.on ${process.env.REACT_APP_SOCKET_EMIT_MESSAGE}`);
				});

				socket.on(process.env.REACT_APP_SOCKET_EMIT_NOTIFY, (notification, ackFn) => {
					ackFn(new Date());
					console.log('routing  notify');
					// console.dir(notification);

					const {title = '', img = ''} = notification;

					createNotification(
						NotificationType.info,
						notification.text,
						title,
						notification.url,
						img
					);

				});
			}
		}
		else
			console.log('%cconnectSocket inside routing DISABLED', 'color: white; background: red');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// console.log('componentWillReceiveProps');
		//
		// console.log(this.props);
		// console.log(nextProps);

		if (this.props.user === null && nextProps.user !== null && this.props.GUI.socket === null) {

			this.connectSocket(nextProps.user);
		}

	}

	componentDidMount() {
		document.title = APP_TITLE;

		// this.unlisten = this.props.history.listen((location, action) => {
		// 	console.log("on route change");
		// });

		// const {actions} = this.props;
		// actions.sideBarToggle(false);
		// console.log(actions);
		// const sessionUser = Storage.session_get(SESSION_USER, null, true);
		// console.log(sessionUser);
		//
		// if (sessionUser)
		// 	this.props.actions.userAuth(sessionUser);
	}


	render() {
		const {user, actions} = this.props;

		// const loaderBar = <LoadingBar className={"LoadingBar"}/>;

		let content = null;
		// console.log(user);
		// const sessionUser = Storage.session_get('user', null, true);
		// console.log(sessionUser);

		if (!user) {
			content = (
				<Router>
					<Routers.LoginRouter />
				</Router>
			);
		}
		else
			content = (
				<Router>
					<Containers.ContentContainer/>
				</Router>
			);


		return (
			<Fragment>{content}</Fragment>
		);
	}
}
// export default Routing;
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		userAuth, sideBarToggle, setSocket
	}, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(Routing);

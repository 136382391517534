import React, {useState, useRef, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {rolesAdmin, rolesModerator, SITE_URL} from "utils/config";
import {dateFormat, actionMenuButton} from "utils/func";
import cn from "classnames";
// import {deleteTask} from "containers/tasks/TasksEditContainer";


// const isEditableTask = taskDate => {
// 	taskDate = taskDate + ' 23:59:00';
// 	// console.log(taskDate);
// 	// console.log(strtotime(taskDate) * 1000);
// 	// console.log(new Date().getTime() + ' / ' + date('d.m.Y H:i:s', new Date()));
// 	// console.log(strtotime(taskDate) * 1000 < new Date().getTime());
// 	// if (strtotime(taskDate) * 1000 < new Date().getTime())
// 	// 	return false;
// 	// return true;
// 	return strtotime(taskDate) * 1000 >= new Date().getTime();
// };

const printExecutors = (ids, users) => {
	let names = [];
	ids.map(id => {
		if (users[id])
			names.push(users[id].name);
	});

	return names.join(', ');
};
const TasksListTableRow = props => {
	const {
		user,
		actions,
		columnsTitle,
		task,
		project,
		statusList,
		executorsUsers,
		deleteTask = null
	} = props;


	const [actionsOpen, setActionsOpen] = useState(false);
	// const [taskState, setTaskState] = useState(task);

	// console.dir(task);
	// console.dir(project);

	//елси то, кто создал задачу
	const isCreator = user.id === task.uid;

	//можно редактировать задачу, проверка по дате
	// const isEditable = isEditableTask(task.ddateReal);


	// const actionsMenu2 = (
	// 	<div className="actions -left">
	// 		<i className="fa fa-bars"></i>
	// 		<div>
	// 			<NavLink to={`/tasks/edit/${user.id}`} title={"Информация"}>
	// 				<i className="fa fa-edit"></i> Редактировать
	// 			</NavLink>
	// 			<a href={'#'} className="">
	// 				<i className="fa fa-pause"></i> Редактировать
	// 			</a>
	//
	//
	// 		</div>
	// 	</div>
	// );
	const actionsMenu = [];
	// console.log(user.isAdmin, isCreator);
	if (user.isAdmin || isCreator) {
		actionsMenu.push(
			<NavLink
				key={`amb-${task.id}-edit`}
				to={`/tasks/edit/${task.id}`} title={"Информация"}>
				<i className="fa fa-edit"></i> Редактировать
			</NavLink>
		);
		actionsMenu.push(
			<a
				key={`amb-${task.id}-del`}
				href={'#'}
				onClick={async (e) => {
					e.preventDefault();
					console.log('deleteTask', deleteTask);
					if (typeof deleteTask === 'function')
						await deleteTask(task.id, actions);
				}}
			>
				<i className="fa fa-close"></i> Удалить
			</a>
		);
	}
	// actionsMenu.push(
	// 	<a href={'#'} className="">
	// 		<i className="fa fa-pause"></i> Редактировать
	// 	</a>
	// );

	let weightOpacity = task.weight / 100;
	if (weightOpacity < 0.2)
		weightOpacity = 0.2;


	return (
		<ul className={cn("tr tasks-list-item", {'draft': task.publish === 0})}>
			<li className={"td col-act"}>
				{actionMenuButton(actionsMenu, actionsOpen, setActionsOpen)}
			</li>
			<li className={"td col-name"}>
				<h5 className="td-title">
					{columnsTitle.task}
				</h5>
				<div>
					<NavLink
						to={`/tasks/${task.id}`}
						className={"tl-title"}
					>
						{task.name} {task.publish == 0 ? `(черновик)` : null}
					</NavLink>
					<div className="tlt-inf">
						{/*{*/}
						{/*	project ? <div>*/}
						{/*		Проект: <span>{project.name}</span>*/}
						{/*	</div> : null*/}
						{/*}*/}
						{
							(task.sid != 0 && project && project.sites) ? (
								<div>
									Сайт: <span>
									{
										project.sites[task.sid]
											? project.sites[task.sid]
											: 'undefined'
									}
								</span>
								</div>
							) : null
						}
						{
							// (!isCreator && task.userName != '')
							(task.userName != '') ? <div>
								Постановщик: <span>{task.userName}</span>
							</div> : null
						}
						{
							task.execs ? <div>
								Исполнитель: <span>{printExecutors(task.execs, executorsUsers)}</span>
							</div> : null
						}
						{
							task.viewers ? <div>
								Наблюдател{task.viewers.length == 1 ? 'ь' : 'и'}: <span>{printExecutors(task.viewers, executorsUsers)}</span>
							</div> : null
						}
						{
							task.totalComments ? <div title={"Коментарии"} className={"cmnts"}>
								<i className={"fa fa-comments-o"}/>
								<span>{task.totalComments} {
									task.newComments > 0
										? <span title={"Новые комментарии"}>({task.newComments})</span>
										: null
								}</span>
							</div> : null
						}
					</div>
				</div>
			</li>
			<li className={"td col-weight"}>
				<h5 className="td-title">
					{columnsTitle.weight}
				</h5>
				<div className="cont" style={{
					opacity: weightOpacity,
					fontWeight: weightOpacity == 1 ? 'bold' : 'normal'
				}}>
					{task.weight}
				</div>
			</li>
			<li className={"td col-status"}>
				<h5 className="td-title">
					{columnsTitle.status}
				</h5>
				<div className="cont">
					<div className={"tl-status -s" + task.status}>
						{
							statusList[task.status]
								? statusList[task.status]
								: 'undefined'
						}
					</div>
				</div>
			</li>
			<li className={"td col-created"}>
				<h5 className="td-title">
					{columnsTitle.created}
				</h5>

				<div className="cont">
					{dateFormat(task.created)}
				</div>
			</li>
			<li className={"td col-end_date"}>
				<h5 className="td-title">
					{columnsTitle.end_date}
				</h5>
				<div className="cont">
					{dateFormat(task.end_date)}
				</div>

			</li>
			<li className={"td col-exec"}>
				<h5 className="td-title">
					{columnsTitle.project}
				</h5>
				<div className="cont">
					{
						project ? project.name : null
					}
				</div>
			</li>
			{/*<li className={"td col-exec"}>*/}
			{/*	<h5 className="td-title">*/}
			{/*		{columnsTitle.exec}*/}
			{/*	</h5>*/}
			{/*	<div className="cont">*/}
			{/*		{*/}
			{/*			task.execs*/}
			{/*				? printExecutors(task.execs, executorsUsers)*/}
			{/*				: null*/}
			{/*		}*/}
			{/*	</div>*/}
			{/*</li>*/}

		</ul>
	);
};

export default TasksListTableRow;

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";
import date from "php-date";
import {bindActionCreators} from "redux";
import {setCalendarDates} from "actions/GUIActions";
import * as strtotime from "strtotime";

const ContentHeader = (props) => {

	const {title = '', parent = [], titleEnds = '',
		showDates = false,
		GUI, actions,
		datesAction,
		panelContent = []
	} = props;
	// console.log(GUI.calendarDates);

	const dateStart = date('Y-m-d', GUI.calendarDates.start);
	const dateEnd = date('Y-m-d', GUI.calendarDates.end);

	const [stateDateStart, setDateStart] = useState(strtotime(dateStart));
	const [stateDateEnd, setDateEnd] = useState(strtotime(dateEnd));
	// const [lastSetDateField, setLastSetDateField] = useState(null);
	// console.log(stateDateStart);
	// console.log(stateDateEnd);
	const dateDiff = () => {
		if (stateDateStart > stateDateEnd) {
			alert('Выбран неверный промежуток времени');
			return false;
		}

		return true;
	};

	//даты для печати
	let titlePrintDates = '';
	if (dateStart !== dateEnd )
		titlePrintDates = 'с ' + date('d.m.Y', GUI.calendarDates.start) + ' по ' + date('d.m.Y', GUI.calendarDates.end);
	else
		titlePrintDates = `за ` + date('d.m.Y', GUI.calendarDates.start);


	let panel = panelContent;
	if (showDates)
		panel.push(
			<div key={"panel-top-dates-wrap"}>
				<div className="flx -alc -dates">
					Вывести
					c &nbsp;
					<div className="panel-top-dates-item">
						<input type="date" name="start" value={dateStart} onChange={(e) => {
							const v = strtotime(e.target.value);
							setDateStart(v);
							actions.setCalendarDates({
								...GUI.calendarDates,
								start: new Date( v * 1000)
							});
							// dateDiff(e.target.name);
						}}/>
					</div>
					&nbsp; по  &nbsp;
					<div className="panel-top-dates-item">
						<input type="date" name="end" value={dateEnd} onChange={(e) => {
							const v = strtotime(e.target.value);
							setDateEnd(v);
							actions.setCalendarDates({
								...GUI.calendarDates,
								end: new Date(v * 1000)
							});
						}}/>
					</div>
				</div>
				<div className="mobile-mt20">
					<div className="btn" onClick={() => {
						if (dateDiff() && typeof datesAction == 'function') {
							datesAction();
						}
					}}>
						Показать
					</div>
				</div>
			</div>
		);




	return (
		<div className="content-header  flx -sb -alc">
			<div className="content-title">
				{parent.map((item, i) => {
					return <NavLink to={item.url} key={i}><span>{item.name}</span></NavLink>
				})}
				<h1>{title} <span className={"print-only"}>{titlePrintDates}</span>{titleEnds}</h1>
			</div>

			{
				panel.length ? (
					<div className="panel-top flx -e -alc no-print">{panel}</div>
				) : null
			}
		</div>
	);
};

ContentHeader.propTypes = {
	title: PropTypes.oneOfType([PropTypes.element,PropTypes.string]).isRequired,
	parent: PropTypes.arrayOf(PropTypes.shape({
		url: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})),
	showDates: PropTypes.bool
	// children: PropTypes.node.isRequired,
	// footer: PropTypes.oneOfType([PropTypes.element,PropTypes.string]),
	// winClass: PropTypes.string,
	// onClose: PropTypes.func,
};

const mapStateToProps = store => ({
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		setCalendarDates
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ContentHeader);
// export default ContentHeader;
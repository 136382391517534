import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Button} from "components/_common";
import { NavLink } from "react-router-dom";
import {API_URLS, MENU_TITLE} from "utils/config";
import DivisionListItem from "components/user/DivisionListItem";
import {apiSend} from "utils/func";

const DivisionsListContainer = props => {

	const {user, actions} = props;
	const [divisions, setDivisions] = useState([]);
	// const [divisionsShow, setDivisionsShow] = useState([]);

	const getAll = async (isSubscribe) => {
		// if (!isSubscribe)
		// 	return;
		//
		// actions.showLoading();
		// const res = await Api.post('users.php',  {
		// 	action: 'getDivisions',
		// 	userId: user.id
		// }).catch(er => {
		// 	toastr.error('Ошибка запроса', er.toString(), {timeOut: 0});
		// });
		//
		// apiAction(res, (data) => {
		// 	setDivisions(data);
		// });
		// actions.hideLoading();

		await apiSend(
			API_URLS.user,
			{
				action: 'getDivisions',
				userId: user.id
			},
			(res) => {
				console.dir(res);
				if (res.divisions)
					setDivisions(Object.values(res.divisions));
			},
			actions
		);
	};
	useEffect(() => {
		let isSubscribe = true;
		getAll(isSubscribe).then(r => {});
		return () => isSubscribe = false
	}, ['getAll']);

	const onDeleteAction = async (id) => {
		actions.showLoading();
		const res = await Api.post('users.php',  {
			action: 'delete',
			user: user,
			target: 'division',
			id: id
		});
		actions.hideLoading();
		apiAction(res, (data) => {
			if (data.deleted) {
				let tmp = [];
				divisions.map((item) => {
					if (item.id !== id)
						tmp.push(item);

					return null;
				});
				setDivisions(tmp);
			}
		});
	};
	const onDelete = (e, user) => {
		e.preventDefault();
		toastr.confirm(
			'Вы точно хотите удалить ' + MENU_TITLE.divisions.one + ' "'+ user.name + '" ?',
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: () => onDeleteAction(user.id),

			}
		);
	};

	return (
		<>
			<ContentHeader
				parent={[{url: '/user', name: "Пользователи"}]}
				title={MENU_TITLE.divisions.many}
			/>
			<div className="flx -sb -alc">
				<div className="">
					Всего: {divisions.length}
				</div>

				<Button
					href={"/user/divisions/edit/0"}
					type={"save"}
					title={"Создать"}
					ico={"university"}
				/>
				{/*<NavLink*/}
				{/*	to={"/user/divisions/edit/0"}*/}
				{/*	className="btn -green"*/}
				{/*>*/}
				{/*	*/}
				{/*	<div className="in">*/}
				{/*		<i className="fa fa-address-book-o"></i> Добавить {MENU_TITLE.divisions.one}*/}
				{/*	</div>*/}
				{/*</NavLink>*/}
			</div>
			{divisions.length ?
				<table className="tbl users-list mt20">
					<thead>
					<tr>
						<td width="3%" className="_tac">
							&nbsp;&nbsp;
						</td>
						<td width="42%">
							Наименование
						</td>
						<td width="45%">
							Описание
						</td>
						<td width="10%">
							Кол-во пользователей
						</td>
					</tr>
					</thead>
					<tbody>

					{divisions.map((item, i) =>
						<DivisionListItem
							key={i}
							sysUser={user}
							division={item}
							onDelete={onDelete}
						/>
					)
					}
					</tbody>
				</table>
				: ''
			}
		</>
	)

};
const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(DivisionsListContainer);

import React, {useState} from 'react';
import {Button, Win, FormItem, SmartSelect} from "components/_common";
import {in_array, dateFormat} from "utils/func";
// import date from "php-date";
// import * as strtotime from "strtotime";
import {SITE_URL} from "utils/config";

const TaskEditInfo = props => {

	const {
		task,
		setTaskData,
		executors,
		//taskExecutors,
		projects,
		formErrors,
		setFormErrors
	} = props;

	// console.clear();



	// console.dir(projects);
	const [projectId, setProjectId] = useState(task ? task.pid : 0);

	let sitesInit = [];
	if (task && task.pid) {
		projects.map(proj => {
			if (proj.id == projectId && proj.sites)
				sitesInit = proj.sites;
		});
	}

	// console.dir(sitesInit);



	const [sites, setSites] = useState(sitesInit);
	const [sitesRefresh, setSitesRefresh] = useState(false);
	// console.log(sites);

	const projectsOptions = [];
	projectsOptions.push({
		name: 'нет',
		value: 0,
		selected: projectId == 0//(!task || task.pid == 0)
	});

	for (let k in projects) {
		// console.log(task.pid, projects[k].id);
		// console.log((task && task.pid && task.pid == projects[k].id));
		projectsOptions.push({
			name: projects[k].name,
			value: projects[k].id,
			selected: (task && task.pid == projects[k].id)
		});
	}

	//сайт указывать не обязательно
	const sitesOptions = [];
	sitesOptions.push({
		name: 'нет',
		value: 0,
		selected: !task || task.sid == 0
	});

	let hasSiteSelected = false;
	sites.map((s, i) => {
		let _selected = (task && task.sid == s.id);
		sitesOptions.push({
			name: s.domain,
			value: s.id,
			selected: _selected
		});

		if (_selected)
			hasSiteSelected = true;

		return null;
	});
	if (!hasSiteSelected)
		sitesOptions[0].selected = true;

	// console.dir(task.sid, projectId, sitesOptions);


	// console.dir(executors);
	// console.dir(task.viewers);
	// console.log(taskExecutors.exec);
	const executorsOptions = [];
	const viewersOptions = [];

	executorsOptions.push({
		name: 'нет',
		value: 0,
		selected: task == null || task.executorId == 0
	});

	executors.map(exec => {
		executorsOptions.push(
			{
				name: exec.name,
				value: exec.id,
				selected: (task && task.executorId == exec.id),
				image: SITE_URL + exec.avatar
			}
		);
		viewersOptions.push(
			{
				name: exec.name,
				value: exec.id,
				selected: task.viewers && in_array(exec.id, task.viewers),
				image: SITE_URL + exec.avatar
			}
		);
	});




	return (
		<Win
			header={
				<div className="">
					<i className="fa fa-cogs"></i> Параметры
				</div>
			}
		>

			<div className="mt20X">
				<SmartSelect
					name={"pid"}
					label={"Проект"}
					placeholder={"нет"}
					options={projectsOptions}
					arrow={true}
					onSelect={(selected) => {

						let _projectId = (selected.values()).next().value['value'];

						let _sites = [];
						projects.map(proj => {
							if (proj.id === _projectId && proj.sites)
								_sites = proj.sites;
						})
						setSites(_sites);

						setProjectId(_projectId);
						// setSitesRefresh(true);
						setTaskData(prev => ({
							...prev,
							task: {
								...prev.task,
								pid: _projectId
							}
						}));


					}}
					errorMsg={ (formErrors && formErrors.pid) ? formErrors.pid : null }
					setErrorMsg={setFormErrors}
				/>
			</div>
			<div className="mt20">
				<SmartSelect
					key={"sites-select-" + projectId}
					name={"sites"}
					label={"Сайт"}
					placeholder={"нет"}
					options={sitesOptions}
					arrow={true}
					// refresh={sitesRefresh}
					onSelect={(selected) => {
						let siteId = (selected.values()).next().value['value'];
						// setSitesRefresh(false);
						setTaskData(prev => ({
							...prev,
							task: {
								...prev.task,
								sid: siteId
							}
						}));

					}}
				/>
			</div>
			<div className="mt20">
				<FormItem
					fieldType={"text"}
					name={"weight"}
					label={"Важность (вес)"}
					isRequire={true}
					defVal={(task && task.weight) ? task.weight : 0}
					numericRound={0}
					blurAction={(e, val, setFieldValue) => {

						if (val < 0) {
							setFieldValue(0)
							val = 0;
						}
						if (val > 100) {
							setFieldValue(100)
							val = 100;
						}

						setTaskData(prev => ({
							...prev,
							task: {
								...prev.task,
								weight: val
							}
						}));
					}}
				/>
			</div>
			<div className="mt20">
				<SmartSelect
					name={"executorId"}
					label={"Исполнитель*"}
					options={executorsOptions}
					placeholder={"нет"}
					// multi={true}
					arrow={true}
					// deleteOnReselect={true}
					onSelect={(selected) => {
						let execId = (selected.values()).next().value['value'];
						// console.log(execId);
						setTaskData(prev => ({
							...prev,
							task: {
								...prev.task,
								executorId: execId
							}
						}));
					}}
					errorMsg={ (formErrors && formErrors.executorId) ? formErrors.executorId : null }
					setErrorMsg={setFormErrors}
				/>
			</div>
			<div className="mt20">
				<SmartSelect
					name={"viewers"}
					label={"Наблюдатели"}
					options={viewersOptions}
					placeholder={"нет"}
					multi={true}
					arrow={true}
					// deleteOnReselect={true}
					onSelect={(selected) => {
						let execs = [];
						// console.log(selected);
						[...selected.entries()].map(([id, user]) => {
							// console.log(id);
							if (id)
								execs.push(id);
						});

						// console.log(execs);
						setTaskData(prev => ({
							...prev,
							task: {
								...prev.task,
								viewers: execs
							}
						}));;

					}}
				/>
			</div>



			<div className="mt20">
				<FormItem
					fieldType={"date"}
					name={"end_date"}
					label={"Крайний срок"}
					isRequire={true}
					changeAction={(e, val) => {
						// let valTime = strtotime(`${val} 00:00:00`) * 1000;
						// // console.log(val, valTime, Date.now(), valTime < Date.now() );
						//
						// if (valTime < Date.now())
						// 	val = dateFormat(Date.now()/1000, 'Y-m-d');
						// console.log(val);
						setTaskData(prev => ({
							...prev,
							task: {
								...prev.task,
								end_date: val
							}
						}));
					}}
					defVal={(task && task.end_date) ? (task.end_date) : ''}
					errorMsg={ (formErrors && formErrors.end_date) ? formErrors.end_date : null }
					setErrorMsg={setFormErrors}
				/>
			</div>


		</Win>
	)

};

export default TaskEditInfo;

// console.dir(process.env);
export const APP_TITLE = 'Dive CRM';
export const APP_ID = 'ru.divecrm';
export const DEBUG = /localhost/.test(window.location.href);
export const PASSWORD_SALT = 'vb45k';
export const COOKIE_LOGIN = 'login';
export const COOKIE_PASS = 'password';
export const SESSION_LIFETIME = 3600 * 24 * 5;
export const SHOW_SCROLLTOP_OFFSET = 300;

const apiPort = process.env.REACT_APP_API_PORT || 3001;

let _API_URL = null;
let _SOCKET_URL = null;
let _SITE_URL = null;
if (DEBUG) {
	_SITE_URL = `http://127.0.0.1:${apiPort}/`;
	_API_URL = `http://127.0.0.1:${apiPort}/`;
	// _API_URL = `https://crm.ghoul.ru:8090/`;
	_SOCKET_URL = `http://127.0.0.1:${apiPort}/`;
}
else {
	// _SITE_URL =  `http://crm.ghoul.ru:${apiPort}/`;
	_API_URL = `https://crm.ghoul.ru:${apiPort}/`;
	_SOCKET_URL = `https://crm.ghoul.ru:${apiPort}/`;
	// _SOCKET_URL = 'http://82.202.170.56:8088';
}
// console.log('version', process.env.REACT_APP_VERSION, 'DEBUG', DEBUG, '_SITE_URL', _SITE_URL, '_API_URL', _API_URL);
console.log(`version: ${process.env.REACT_APP_VERSION}; DEBUG: ${DEBUG}; _SITE_URL: ${_SITE_URL}; _API_URL: ${_API_URL}; _SOCKET_URL: ${_SOCKET_URL}`);
export const API_URL = _API_URL;
export const SOCKET_URL = _SOCKET_URL;
export const SITE_URL = process.env.REACT_APP_SITE_URL;
export const SITE_URL_FRONT = process.env.REACT_APP_SITE_URL_FRONT;
export const API_URLS = {
	login: 'login/',
	user: 'user/',
	projects: 'projects/',
	tasks: 'tasks/',
	settings: 'settings/',
	utils: 'utils/'
};
/**/


export const rolesAdmin = ['admin'];
export const rolesModerator = ['admin', 'moderator', 'owner'];
export const MENU_TITLE = {
	tasks: {
		one: 'Задача',
		many: 'Задачи',
		rodPad: 'задачи'
	},
	divisions: {
		one: 'Подразделение',
		many: 'Подразделения'
	},
	projects: {
		one: 'Проект',
		many: 'Проекты'
	},
	// sites: {
	// 	one: 'Сайт',
	// 	many: 'Сайты'
	// },
};
export const URLS = {
	user: '/user',
	projects: '/projects',
	tasks: '/tasks',
	settings: '/settings'
}

export const MENU = [
	// 'separator',
	// {
	// 	name: 'Консоль',
	// 	url: '/',
	// 	ico: 'fa-tachometer',
	// 	roles: []
	// },
	{
		name: MENU_TITLE.tasks.many,
		url: URLS.tasks,
		ico: 'fa-tasks',
		roles: []
	},

	{
		name: "Проекты",//MENU_TITLE.projects.many,
		url: URLS.projects,
		ico: 'fa-sitemap',
		roles: [...rolesModerator],
		// menu: [
		// 	{
		// 		name: 'Проекты',
		// 		url: '/projects',
		// 		ico: 'fa-th-list',
		// 		roles: []
		// 	},
		// 	{
		// 		name: 'Сайты',
		// 		url: '/projects/sites',
		// 		ico: 'fa-globe',
		// 		roles: []
		// 	},
		// ]
	},
	{
		name: 'Пользователи',
		url: URLS.user,
		ico: 'fa-users',
		roles: [...rolesModerator],
		menu: [
			{
				name: 'Все пользователи',
				url: URLS.user,
				ico: 'fa-user',
				roles: [],
				menu: []
			},
			{
				name: MENU_TITLE.divisions.many,
				url: URLS.user + '/divisions',
				ico: 'fa-university',
				roles: [...rolesAdmin]
			}
		]
	},
	{
		name: 'Настройки',
		url: URLS.settings,
		ico: 'fa-cogs',
		roles: [...rolesAdmin],
		menu: [
			{
				name: 'Основные',
				url: '/settings/general',
				ico: 'fa-cogs',
				roles: [...rolesAdmin]
			},
			// {
			// 	name: 'Календарь',
			// 	url: '/settings/calendar',
			// 	ico: 'fa-calendar',
			// 	roles: [...rolesAdmin]
			// },
			// {
			// 	name: 'Ограничения',
			// 	url: '/settings/limits',
			// 	ico: 'fa-ban',
			// 	roles: [...rolesAdmin]
			// },
		]
	},
];

export const MONTHS = [
	'Январь',
	'Февраль',
	'Март',
	'Апрель',
	'Май',
	'Июнь',
	'Июль',
	'Август',
	'Сентябрь',
	'Октябрь',
	'Ноябрь',
	'Декабрь',
];
export const DAYS_SHORT = [
	'пн',
	'вт',
	'ср',
	'чт',
	'пт',
	'сб',
	'вс'
];
export const DAYS_FULL = [
	'понедельник',
	'вторник',
	'среда',
	'четверг',
	'пятница',
	'суббота',
	'воскресенье'
];

export const MESSAGES = {
	confirmAction: 'Подтвердите действие',
	fieldEmpty: 'Поле не заполнено',
	toastTitleError: 'Ошибка',
};
export const IMG_EXTENSIONS = [
	'jpg', 'jpeg', 'jfif',
	'bmp', 'png', 'gif',
	'webp'
];

export const STORAGE_TASKS_FILTER = 'tasks_filter';

export const CAN_EDIT_COMMENTS_TIME =  24 * 3600 * 1000;




import React, {createRef, useState} from 'react';
import {toastr} from "react-redux-toastr";
import {getFileSize, getFileIcon, in_array, deleteFile, downloadFile, trim, apiSend} from "utils/func";
import {Win} from "components/_common";
import {API_URLS, IMG_EXTENSIONS, MESSAGES, SITE_URL} from "utils/config";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


/**
 * Загрузка/отображение файлов
 * TODO - сделать свой просмотрщик картинок в попапе, удалить фенси
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FileUploader = props => {

	const {
		canUpload = true,
		maxFileSize = 0,
		acceptExtensions = Object.keys(MimeTypes),
		getFiles = () => {
			alert('FileUploader.getFiles not defined')
		},
		isMinimized = false,
		files = [],// ранее загруженные файлы
		title = 'Файлы',
		// onDelete = (id) => {
		// 	alert('FileUploader.onDelete not defined for file id =' + id)
		// },
	} = props;

	// console.log('FileUploader RENDER');
	const fileRef = createRef();
	const [fileList, setFileList] = useState([]);
	const [existsFiles, setExistsFiles] = useState(files);
	const [winIsMinimized, setWinMinimized] = useState(isMinimized);


	const maxFileSizeMb = getFileSize(maxFileSize);
	// console.log(maxFileSize, maxFileSizeMb);
	let acceptMimes = [];
	if (acceptExtensions.length) {
		acceptExtensions.forEach(ext => {
			if (MimeTypes[ext])
				acceptMimes.push(MimeTypes[ext]);
		});
	}

	const upload = () => {
		const files_list = [];
		// const files_list_accepted = [];
		// const files_list = [...fileRef.current.files];
		// const files_list_accepted = fileList;

		console.log('upload files');
		// console.log(fileRef.current.files);
		// if (user.settings && user.settings.upload_max_filesize && attachFile.files[0] && attachFile.files[0].size >= user.settings.upload_max_filesize) {
		// 	toastr.error('Внимание!', 'Размер загружаемого файла превышает ' + getFileSize(user.settings.upload_max_filesize));
		// 	err.push('');
		// }
		[...fileRef.current.files].map(file => {
			// console.log(file);
			let add2list = true;
			let cantUploadErrors = [];
			if (maxFileSize && file.size > maxFileSize)
				cantUploadErrors.push('Размер загружаемого файла превышает ' + maxFileSizeMb)

			if (acceptMimes.length && !in_array(file.type, acceptMimes))
				cantUploadErrors.push(`Запрещенный тип "${file.type}"`);

			file.cantUploadErrors = cantUploadErrors;


			fileList.map(f => {
				if (file.name === f.name && file.size === f.size)
					add2list = false;
				return null;
			});

			if (add2list && cantUploadErrors.length == 0)
				files_list.push(file);

			// if (!cantUploadErrors.length)
			// 	files_list_accepted.push(file);
		});
		// console.log(files_list);

		let files_list_new = [...fileList];
		files_list_new = files_list_new.concat(files_list);
		setFileList(files_list_new);


		if (typeof getFiles === 'function')
			getFiles(files_list_new);

		setWinMinimized(false);
	}
	const deleteUploadedFile = (file) => {
		console.log('onDelete', file);
		const files_list = [];
		console.log(fileList);
		fileList.map(f => {
			if (file.name !== f.name && file.size !== f.size)
				files_list.push(f);
			return null;
		});

		setFileList(files_list);
		if (typeof getFiles === 'function')
			getFiles(files_list);
	};
	const deleteExistsFile = async (id) => {
		toastr.confirm(
			MESSAGES.confirmAction,
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: async () => {
					let isDeleted = await deleteFile(id);
					console.log('isDeleted', isDeleted);

					if (isDeleted) {
						let _files = [];
						existsFiles.map(f => {
							if (f.id !== id)
								_files.push(f);
						});
						setExistsFiles(_files);
					}
				}
			}
		);

	};

	//https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
	const onDropFiles = (e) => {
		e.preventDefault();
		// e.stopPropagation();
		//
		console.log('onDropFiles');


		// setWinMinimized(false);
		// return;
		const files = [];
		var dt = new DataTransfer();
		[...fileRef.current.files].map(file => {
			// console.log(f);
			dt.items.add(file);
		});
		if (e.dataTransfer.items) {
			[...e.dataTransfer.items].forEach((item, i) => {
				// If dropped items aren't files, reject them
				if (item.kind === 'file') {
					const file = item.getAsFile();

					files.push(file);
					dt.items.add(file);
					// fileRef.current.files.push(file);
					// console.log(`… file[${i}].name = ${file.name}`);
				}
			});
		} else {
			[...e.dataTransfer.files].forEach((file, i) => {
				// console.log(`… file[${i}].name = ${file.name}`);
				// fileRef.current.files
				// fileRef.current.files.push(file);
				files.push(file);
				dt.items.add(file);
			});
		}
		fileRef.current.files = dt.files;
		upload();

	}


	let winFooter = null, winHeader = null;
	if (canUpload) {

		winHeader = <div>
			<i className={"fa fa-files-o"}></i> {title} {winIsMinimized}
		</div>;

		if (acceptExtensions.length)
			winFooter = <div className="">
				Разрешены форматы: {acceptExtensions.join(', ')}
			</div>
	}

	return (
		<Win
			noOverflowContent={true}
			header={winHeader}
			minimized={canUpload}
			isMinimized={winIsMinimized}

			winClass={"file-uploader-win hdr-green" + (!canUpload ? ' -clean' : '')}
			footer={winFooter}
			id={"file-uploader-win"}
			// onDropOnContent={onDropFiles}
			onDropOnWin={onDropFiles}
		>
			<form
				encType={"multipart/form-data"}
			>
				<div className="form-item">


					{/* ранее залиты файлы*/}
					{
						existsFiles.length
							? <FileUploaderFilesList
								files={existsFiles}
								onDelete={canUpload ? deleteExistsFile : null}
								isFromServer={true}
							/>
							: null
					}

					{/* новые, еще не залиты */}
					{
						canUpload ? (
								<>
									<input
										type="file"
										multiple={true}
										ref={fileRef}
										onChange={upload}
									/>
									<FileUploaderFilesList
										files={fileList}
										onDelete={deleteUploadedFile}
										isFromServer={false}
									/>
								</>
							)
							: null
					}
				</div>
			</form>
		</Win>

	)

};

export default FileUploader;

const getFileExtension = fileName => {
	return fileName.split('.').pop();
};

const FileUploaderFilesList = ({files = [], onDelete, isFromServer}) => {
	// console.dir(files);
	return (
		<ul className="form-files-list">
			{
				files.map((file, i) => {
					let ico;
					let isImage = false;

					if (isFromServer) {
						if (in_array(file.ext.toLowerCase(), IMG_EXTENSIONS)) {
							isImage = true;
							let src = SITE_URL + trim(file.path, '/');
							// ico = <ReactFancyBox
							// 	thumbnail={src}
							// 	image={src}/>;

							// ico = <img src={src} />;

							ico = <Zoom>
								<img src={src} />
							</Zoom>;
						}
						else
							ico = getFileIcon(
								file.ext,
								file
							);
					} else {
						ico = getFileIcon(
							getFileExtension(file.name),
							file
						)
					}

					return (
						<li key={"file-uploader-files-" + i}>
							<div className="ffl-btns">
								{
									isFromServer
										? <i className="fa fa-floppy-o"
											 onClick={() => downloadFile(file.id)}
											 title={"Скачать"}
										></i>
										: null
								}
								{
									onDelete ?
										<i className="fa fa-close"
										   onClick={() => onDelete( isFromServer ? file.id : file)}
										   title={"Удалить"}
										></i>
										: null
								}
							</div>


							<figure>
								<a
									href="#"
									onClick={async (e) => {
										e.preventDefault();
										if (!isImage && isFromServer)
											await downloadFile(file.id);
									}}
								>
									{ico}

									<figcaption>
										<a href={file.path}>{file.name} ({getFileSize(file.size)})</a>
									</figcaption>
								</a>
							</figure>


						</li>
					)
				})
			}
		</ul>
	)
}

const MimeTypes = {
	//изображения
	jpg: 'image/jpeg',
	png: 'image/png',
	webp: 'image/webp',
	gif: 'image/gif',
	//текст
	pdf: 'application/pdf',
	txt: 'text/plain',
	rtf: 'application/rtf',
	doc: 'application/msword',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	xls: 'application/vnd.ms-excel',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	//архивы
	rar: 'application/vnd.rar',
	tar: 'application/x-tar',
	zip: 'application/zip',

};

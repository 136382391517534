import React, {useState} from 'react';
import {SITE_URL, MESSAGES} from "utils/config";
import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import striptags from "striptags";
import {FileUploader, FormItem, Button, BUTTON_TYPE} from "components/_common";

import {toastr} from "react-redux-toastr";

const initCommentData = {
	id: 0,
	txt: '',
	files4upload: [],
	files: [],
	showFiles: false
};
const CommentEditor = props => {

	const {user, saveComment, showEditor, setEditCommentShow, comment = {}, deleteFile = null, parentCommentId = 0} = props;

	// const [isEdit, setIsEdit] = useState(showEditor);
	const [commentData, setCommentData] = useState({
		...initCommentData,
		...comment
	});
	// console.dir(commentData);


	const avatar = user.avatar
		? <img src={SITE_URL + user.avatar}/>
		: <i className={"fa fa-user"}/>;


	/**/
	if (!showEditor)
		return (
			<div className="comment-add -placeholder">
				<div className={"avatar"}>{avatar}</div>
				<div className="like-input" onClick={() => setEditCommentShow ? setEditCommentShow(true) : null}>Добавить комментарий</div>
			</div>
		);
	/**/

	const preAddComment = async () => {
		// console.log('preAddComment');
		// console.dir(commentData);

		if (striptags(commentData.txt).trim() == '')
			toastr.error(MESSAGES.toastTitleError, 'Впишите текст комментария');
		else
			await saveComment(
				commentData,
				() => {
					setCommentData(initCommentData)
				}
			);
	}

	const sunOptions = {
		height: 200,
		buttonList: [
			["bold", "underline", "italic", "strike", "subscript", "superscript"],
			["fontSize", "formatBlock", "fontColor", "hiliteColor", "blockquote",],
			["list", "horizontalRule", "link", "image"],
			["removeFormat", "codeView"]
		]
	};

	return (
		<div className="comment-add">
			<div className={"avatar"}>{avatar}</div>
			<div className={"wrp"}>
				<FormItem
					name={""}
					label={""}
					isRequire={true}
					fieldType={<SunEditor
						lang="ru"
						setContents={commentData.txt}
						// defaultValue={commentData.txt}
						placeholder={"подробное описание задачи"}

						onChange={(content) => {

							setCommentData(prev => ({
								...prev,
								txt: content
							}));
						}}
						setOptions={sunOptions}
					/>}

				/>

				<FileUploader
					maxFileSize={
						(user && user.settings && user.settings.upload_max_filesize)
							? user.settings.upload_max_filesize
							: 0
					}
					getFiles={files => {
						// console.dir(files);
						setCommentData(prev => ({
							...prev,
							files4upload: files
						}));
					}}
					acceptExtensions={[]}
					isMinimized={commentData.files.length == 0}
					files={commentData.files}
					onDelete={deleteFile}
				/>


				<div className={"btns flx -alc"}>
					<Button
						type={BUTTON_TYPE.applyIco}
						onClick={async () => await preAddComment()}
					/>
					<a
						href={"#"}
						onClick={e => {
							e.preventDefault();
							setEditCommentShow(false);
						}}
					>
						Отменить
					</a>
				</div>
			</div>
		</div>
	)

};

export default CommentEditor;

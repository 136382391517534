import React, {createRef, useEffect, useState} from 'react';
import sha256 from "sha256";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {SESSION_USER, USER_LOGIN, userAuth} from "actions/UserActions";
import {DEBUG, PASSWORD_SALT, SESSION_LIFETIME, API_URLS} from "utils/config";
// import Api, {apiAction} from "utils/Api";
import {apiSend} from "utils/func";
import {Win, Button} from "components/_common";
import {NavLink} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import Cookies from 'universal-cookie';
import Storage from "utils/Storage";
import {FormItem} from "components/_common";

export const hashPassword = str => {
	return sha256(str + PASSWORD_SALT);
}

const LoginContainer = props => {

	const {user, actions} = props;

	// const cookies = new Cookies();

	const [content, setContent] = useState(null);
	// console.log('LoginContainer');

	// let apiActionProcess = false;

	const onLogin = async (login, password, isRemember, passFromCookie = false) => {
		const passHash = passFromCookie ? password : hashPassword(password);
		console.clear();
		console.log('onLogin');

		await apiSend(
			API_URLS.user,
			{
				action: 'login',
				userId: -1,
				login: login,
				password: passHash
			},
			(res) => {
				// Storage.set(SESSION_USER, res, SESSION_LIFETIME);
				console.log('login API res');
				actions.userAuth(USER_LOGIN, res);
			},
			actions
		)
	};
	const footer = (
		<div className={"_tar"}>
			<NavLink to="/forgetpassword">Забыли пароль?</NavLink>
			{/*<NavLink to="/registration">Регистрация</NavLink>*/}
		</div>
	);

	const formRef = createRef();
	const onSubmit = (e) => {
		e.preventDefault();
		const form = formRef.current;

		if (form.login.value !== '' && form.password.value !== '') {
			onLogin(
				form.login.value.trim(),
				form.password.value.trim(),
				true//form.remember.checked
			).then(null);
		}
		else
			toastr.warning('Внимание', 'Введите логин/пароль');
	};

	let defLogin = process.env.REACT_APP_LOGIN ? process.env.REACT_APP_LOGIN : '';
	let defPass = process.env.REACT_APP_PASS ? process.env.REACT_APP_PASS : '';

	// console.log(`${defLogin},${defPass}`);


	const logWin = (
		<div className="win-wide">
			<Win
				header={"Вход"}
				footer={footer}
				winClass={"login-win anim-win"}
			>
				<form
					ref={formRef}
					  onSubmit={(e) => {
						  e.preventDefault();
						  onSubmit(e);
					  }}
				>
					<FormItem
						key={"login"}
						name={"login"}
						defVal={defLogin}
						isRequire={true}
						ico={"user"}
						placeholder={"Логин"}
					/>
					<FormItem
						key={"password"}
						fieldType={"password"}
						name={"password"}
						defVal={defPass}
						isRequire={true}
						ico={"key"}
						placeholder={"Пароль"}
					/>

					{/*<div className="form-item">*/}
					{/*	<label className={"check"}>*/}
					{/*		<input*/}
					{/*			type={"checkbox"}*/}
					{/*			name={"remember"}*/}
					{/*			defaultValue={"1"}*/}
					{/*		/>*/}
					{/*		Запомнить меня*/}
					{/*	</label>*/}
					{/*</div>*/}
					<div className="form-item _tac">
						<Button
							type={"apply"}
							title={"Войти"}
							buttonType={"button"}
						/>
					</div>
				</form>
			</Win>
		</div>
	);

	// useEffect(() => {
	// 	let isSubscribe = true;
	//
	// 	// if (isSubscribe && !user) {
	// 	// 	const cLogin = cookies.get(COOKIE_LOGIN);
	// 	// 	const cPass = cookies.get(COOKIE_PASS);
	// 	// 	// console.log(cLogin + cPass);
	// 	// 	if (cLogin && cPass)
	// 	// 		onLogin(cLogin, cPass, true, true).then(null);
	// 	// 	else
	// 	// 		setContent(logWin);
	// 	// }
	// 	// setContent(logWin);
	//
	// 	return () => isSubscribe = false
	// }, []);


	return logWin;
	// return content;

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	// userLogin : (login, password) => dispatch(userLogin(login, password)),
	actions: bindActionCreators({
		showLoading, hideLoading,
		userAuth
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

import React, {createRef, useState, useEffect} from 'react';
// import {useParams} from "react-router-dom";
import {Win, Button} from "components/_common";
// import {DIVISION_TITLE} from "utils/config";
// import {toastr} from "react-redux-toastr";
import {FormItem} from "components/_common";


const DivisionEdit = (props) => {
	const {id, division, formRef, onSubmit} = props;


	return (
		<>
			<Win
				header={id ? 'Редактирование' : 'Добавление'}
				footer={
					<div className={"win-ftr-btns"}>
						<Button type="apply"  onClick={() => onSubmit(0)}/>
						<Button type="save" onClick={() => onSubmit(1)}/>
						<Button type="close" onClick={() => props.history.push('/user/divisions')}/>
					</div>
				}

				onClose={() => props.history.push('/user/divisions')}
			>
				<form ref={formRef}>
					<FormItem
						name={"name"}
						label={"Название"}
						defVal={division && division.name}
						isRequire={true}
					/>

					<FormItem
						fieldType={"textarea"}
						name={"txt"}
						label={"Описание"}
						defVal={division && division.txt}
						maxLength={500}
					/>

				</form>
			</Win>

		</>
	);
};


export default DivisionEdit;

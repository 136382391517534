import React, {useState} from 'react';
import {Button, Win, FormItem, SmartSelect, FileUploader, BUTTON_TYPE} from "components/_common";
import {TaskEditInfo, Comment} from "components/tasks";
import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {useNavigate} from "react-router-dom";
import striptags from "striptags";

const TaskEdit = props => {

	const {
		user,
		task = null,
		setTaskData,
		saveAction,
		formErrors,
		setFormErrors,
		deleteFile,
		deleteButton = null
	} = props

	let navigate = useNavigate();

	// console.dir(task);

	// const [editorHeight, setEditorHeight] = useState(300);
	// const [files, setFiles] = useState([]);


	// const title = id ? ( canEdit ? 'Редактирование ' . MENU_TITLE.tasks.rodPad: 'Просмотр ' + MENU_TITLE.tasks.rodPad) : 'Новая ' + MENU_TITLE.tasks.one.toLowerCase();

	// const codeButton = {
	// 	name: "codeButton",
	// 	title: "code",
	// 	buttonClass:'',
	// 	// display: ('container' || 'command' || 'submenu' || 'dialog'),
	// 	display: 'command',
	// 	innerHTML:'<i class="fa fa-code"></i>',
	// 	add: function (core, targetElement) {
	// 		const context = core.context;
	// 		const rangeTag = core.util.createElement('code');
	// 		core.util.addClass(rangeTag, '__se__t-code');
	//
	// 		// @Required
	// 		// Registering a namespace for caching as a plugin name in the context object
	// 		context.customCommand = {
	// 			targetButton: targetElement,
	// 			tag: rangeTag
	// 		};
	// 	},
	// 	active: function (element) {
	// 		if (!element) {
	// 			this.util.removeClass(this.context.customCommand.targetButton, 'active');
	// 		} else if (this.util.hasClass(element, '__se__format__range_custom')) {
	// 			this.util.addClass(this.context.customCommand.targetButton, 'active');
	// 			return true;
	// 		}
	//
	// 		return false;
	// 	},
	// 	action: function () {
	// 		const rangeTag = this.util.getRangeFormatElement(this.getSelectionNode());
	//
	// 		if (this.util.hasClass(rangeTag, '__se__format__range_custom')) {
	// 			this.detachRangeFormatElement(rangeTag, null, null, false, false);
	// 		} else {
	// 			this.applyRangeFormatElement(this.context.customCommand.tag.cloneNode(false));
	// 		}
	// 	}
	// };
	const sunOptions = {
		height: 350,
		// buttonList: buttonList.complex
		buttonList: [
			["bold", "underline", "italic", "strike", "subscript", "superscript"],
			// ["outdent", "indent", "align"],
			["fontSize", "formatBlock", "fontColor", "hiliteColor", "blockquote",

			],
			["list", "horizontalRule", "link", "image"],
			["removeFormat", "codeView"]
		]
	};
	// console.clear();
	// console.dir(task.files);
	// console.log(formErrors);

	let formHasError = false;
	for (let f in formErrors) {
		if (formErrors[f] != '')
			formHasError = true;
	}

	return (
		<div>
			<div className={"row"}>
				<div className="col-2-3">
					<Win
						noOverflowContent={true}
						// onClose={() => props.history.push('/tasks')}
						header={((task && task.id) ? `#${task.id}` : null)}
						winClass={"hdr-blue"}
					>

						<FormItem
							name={"name"}
							label={"Название"}
							isRequire={true}
							maxLength={200}
							defVal={task ? task.name : ''}
							blurAction={(e, content) => {
								setTaskData(prev => ({
									...prev,
									task: {
										...prev.task,
										name: content
									}
								}));
							}}
							errorMsg={(formErrors && formErrors.name) ? formErrors.name : null}
							setErrorMsg={setFormErrors}
						/>


						<FormItem
							name={""}
							label={"Описание"}
							isRequire={true}
							fieldType={<SunEditor
								lang="ru"
								setContents={(task && task.txt) ? task.txt : ""}
								// defaultValue={""}
								placeholder={"подробное описание задачи"}

								onChange={(content) => {

									setTaskData(prev => ({
										...prev,
										task: {
											...prev.task,
											txt: content
										}
									}));
									if (formErrors && formErrors.txt != '' && striptags(content) != '')
										setFormErrors(prev => ({
											...prev,
											txt: ''
										}));
								}}
								setOptions={sunOptions}
							/>}
							errorMsg={(formErrors && formErrors.txt) ? formErrors.txt : null}
							setErrorMsg={setFormErrors}
						/>

						<FileUploader
							maxFileSize={
								(user && user.settings && user.settings.upload_max_filesize)
									? user.settings.upload_max_filesize
									: 0
							}
							getFiles={files => {
								// console.dir(files);

								setTaskData(prev => ({
									...prev,
									task: {
										...prev.task,
										files4upload: files
									}
								}));
							}}
							acceptExtensions={[]}
							isMinimized={false}
							// isMinimized={true}
							files={task.files ?? []}
							onDelete={deleteFile}
						/>

					</Win>

				</div>

				<div className="col-3">
					<TaskEditInfo
						{...props}
					/>
				</div>
			</div>
			<Win
				wrap={false}
				footer={
					(
						<div className={"win-ftr-btns flx -c task-edit-btns"}>
							<div className="-all">
								<Button type={BUTTON_TYPE.saveIco} title={"Сохранить"} onClick={() => saveAction(true, true)}/>

								{/*{*/}
								{/*	(task.id === 0 || (task.publish != undefined && task.publish == 1))*/}
								{/*	? <Button type={BUTTON_TYPE.apply} title={"Сохранить как черновик"} onClick={() => saveAction(false, true)}/>*/}
								{/*	: null*/}
								{/*}*/}
								{
									(task.id === 0 || task.uid === user.id)
										? <Button type={BUTTON_TYPE.apply} title={"Сохранить как черновик"} onClick={() => saveAction(false, true)}/>
										: null
								}


								<Button type={BUTTON_TYPE.closeIco} onClick={() => navigate('/tasks')}/>
							</div>
							<div className="-del _tar">
								{deleteButton}
							</div>
						</div>
					)
				}
			/>
			{
				formHasError
					? <div className="_tac mt10">
						Есть ошибки заполнения
					</div>
					: null

			}
		</div>
	)

};

export default TaskEdit;

import React, {useState, useEffect} from 'react';
import {bindActionCreators} from "redux";
import {useNavigate, useParams} from "react-router-dom";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {API_URLS, MENU_TITLE, MESSAGES, URLS} from "utils/config";
import {apiSend} from "utils/func";
import {ContentHeader, Win, Button} from "components/_common";
import {setCalendarSelectDateAction, setSearchAction} from "actions/GUIActions";
import {TaskEdit} from "components/tasks";
import {toastr} from "react-redux-toastr";
// import {isEditableTask} from "components/tasks/utils";
// import date from "php-date";
import {deleteTask} from "./TasksListContainer";
import * as strtotime from "strtotime";
import striptags from "striptags";
import CryptoJS from "crypto-js";
import {base64_encode} from "../../utils/func";



const TasksEditContainer = props => {
	// console.log('TasksEditContainer init');
	const {user, actions} = props;
	let { id = 0 } = useParams();
	id = parseInt(id);


	const [taskData, setTaskData] = useState({});
	const [formErrors, setFormErrors] = useState({});

	const popupWin = null;
	// const [popupWin, setPopupWin] = useState(null);

	let navigate = useNavigate();

	let apiActionProcess = false;
	const getTask = async (isSubscribe = true, id) => {

		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			apiActionProcess = true;
			await apiSend(
				API_URLS.tasks,
				{
					action: 'editTask',
					id: id,
				},
				(res) => {
					setTaskData(res);
					apiActionProcess = false;
				},
				actions
			)
		}
	};
	useEffect(() => {
		let isSubscribe = true;
		getTask(isSubscribe, parseInt(id)).then(null);

		// actions.setCalendarSelectDateAction(() => {
		// 	props.history.push('/tasks')
		// });
		// actions.setSearchAction(doSearch);
		return () => {
			isSubscribe = false;
			//отключаем функцию обработчик на календаре
			// actions.setCalendarSelectDateAction(null);

			// setTaskData({});

			// setFileData(null);
			// actions.setSearchAction(null);
		}
	},
	[id]
	);



	const saveTask = async (isPublish, isReturn) => {

		const errors = {};

		let {task} = taskData;
		// console.dir(task);

		task.publish = isPublish;

		if (task.name === undefined || task.name === '')
			errors.name = MESSAGES.fieldEmpty;

		if (task.txt === undefined || striptags(task.txt).trim() === '')
			errors.txt = MESSAGES.fieldEmpty;

		// if (task.pid === undefined || task.pid === 0)
		// 	errors.pid = MESSAGES.fieldEmpty;

		if (isPublish && (task.executorId === undefined || task.executorId === 0))
			errors.executorId = MESSAGES.fieldEmpty;

		if (task.end_date === undefined || task.end_date === '')
			errors.end_date = MESSAGES.fieldEmpty;
		else {
			let end_date_time = strtotime(`${task.end_date} 00:00:00`) * 1000;
			// console.log(val, valTime, Date.now(), valTime < Date.now() );

			if (end_date_time < Date.now())
				errors.end_date = 'Неверная дата';
		}

		// const form = formRef.current;
		// const attachFile = attachFileRef.current;

		// console.dir('save task', task);

		// return;

		const formData = new FormData();

		formData.append('action', 'saveTask');
		formData.append('userId', user.id);
		formData.append('task', JSON.stringify(task));

		// let taskBase64 = base64_encode(JSON.stringify(task));
		// console.clear();
		// console.log(taskBase64.length);
		// formData.append('task', taskBase64);

		if (task.files4upload && task.files4upload.length) {
			task.files4upload.map((f, i) => {
				formData.append(`file_${i}`, f);
			})
		}
		// console.log(task.files4upload);
		//
		//
		// console.log('errors', errors);
		// console.log(Object.values(errors).length);
		// return;
		if (Object.values(errors).length === 0) {
			await apiSend(
				API_URLS.tasks,
				formData,
				(res) => {
					if (isReturn)
						navigate('/tasks');
				},
				actions
			);
		}
		else
			setFormErrors(errors);
	};

	const deleteFile = async (id) => {
		console.log(`deleteFile ${id}`);
		console.log(task.files);
	};

	const {task = null, executors= [], projects = [], taskExecutors = null} = taskData;

	const canEdit = true;//(task && task.task && task.task.ddate) ? isEditableTask(task.task.ddate) : true;


	// console.log(task);
	const title = id ? ( canEdit
		? 'Редактирование ' + MENU_TITLE.tasks.rodPad
		: 'Просмотр ' + MENU_TITLE.tasks.rodPad) : 'Новая ' + MENU_TITLE.tasks.one.toLowerCase();

	const winHeader = (
		<div className="flx -sb -alc w100">
			<div className="">
				{id ? MENU_TITLE.tasks.one + ' №' + id : title}
			</div>
			<div className="win-ftr-btns">
				{/*<Button type="apply"  onClick={() => saveTask(0)}/>*/}
				{/*<Button type="save" onClick={() => saveTask(1)}/>*/}
				{/*<Button type="close" onClick={() => props.history.push(onCloseUrl)} />*/}
			</div>
		</div>
	);

	let deleteButton = null;

	if (task && task.id && (task.uid === user.id || user.isAdmin)) {
		deleteButton = <Button
			type={"delete"}
			onClick={e => deleteTask(task.id, actions, (res) => {
				console.log('on deleteTask');
				if (res.deleted)
					navigate(URLS.tasks);
			})}
		/>;

	}

	return (
		<>
			<ContentHeader
				title={title}
				parent={[{url: '/tasks', name: MENU_TITLE.tasks.many}]}
				showDates={false}
			/>

			{
				(task !== null && executors.length && projects.length)
					? <TaskEdit
						user={user}
						task={task}
						setTaskData={setTaskData}
						saveAction={saveTask}
						deleteButton={deleteButton}
						executors={executors}
						taskExecutors={taskExecutors}
						projects={projects}
						formErrors={formErrors}
						setFormErrors={setFormErrors}
						deleteFile={deleteFile}
					/>
					: 'loading'
			}

			{popupWin}
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, setCalendarSelectDateAction, setSearchAction
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TasksEditContainer);

import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {useParams} from "react-router-dom";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import Api, {apiAction} from "utils/Api";
import {ContentHeader, Button} from "components/_common";
import {in_array, apiSend} from "utils/func";
import {rolesModerator, API_URLS} from "utils/config";
import {UsersList} from "components/user";
import Page404 from "components/Page404";

const UserListContainer = props => {

	const {user, actions} = props;
	const {roleName = ''} = useParams();

	// console.dir(roleName);
	const [users, setUsers] = useState([]);
	const [divisions, setDivisions] = useState(null);
	// const [usersShow, setUsersShow] = useState([]);

	const getUsers = async (isSubscribe) => {

		if (!isSubscribe)
			return;


		await apiSend(
			API_URLS.user,
			{
				action: 'getUsers',
				userId: user.id,
				onlyRoles: [roleName]
			},
			(res) => {
				if (users.length)
					setUsers([]);
				setUsers(res.users);

				if (!divisions)
					setDivisions(res.divisions);
			},
			actions
		);

	};
	useEffect(() => {
			console.log('useEffect');
			let isSubscribe = true;
			// setUsers([]);
			getUsers(isSubscribe).then(null);


			return () => {
				isSubscribe = false;
			}
		},
		[roleName]
	);

	const deleteAction = async (id) => {
		actions.showLoading();
		const res = await Api.post('users.php',  {
			action: 'delete',
			target: 'user',
			user: user,
			id: id
		});
		actions.hideLoading();
		apiAction(res, (data) => {

			if (data.deleted) {
				let tmpUsers = [];
				users.map((user) => {
					if (user.id !== id)
						tmpUsers.push(user);

					return null;
				});
				setUsers(tmpUsers);
				// setUsersShow(tmpUsers);
			}

		});
	};
	const onDelete = (e, user) => {
		e.preventDefault();
		toastr.confirm(
			'Вы точно хотите удалить пользователя: ' + user.name + '?',
			{
				okText: 'Да',
				cancelText: 'Нет',
				onOk: () => deleteAction(user.id),

			}
		);
	};
	// console.log(users.length);
	// let panel = null;
	// if (users.length)
		let panel = (
			<div className="flx -sb -alc">
				<div className="">
					Всего: {users.length}
				</div>
				{
					in_array(user.role, rolesModerator) ?
						(
							<Button
								type={"save"}
								title={"Создать пользователя"}
								// href={"/user/edit/0" + (roleName ? '/' + roleName : '')}
								href={"/user/edit/0"}
								ico={"user-plus"}
							/>

						): ''
				}
			</div>
		);
	let contentHeaderParent = [{url: '/user', name: 'Пользователи'}];
	let contentHeaderTitle = 'Пользователи';
	if (roleName !== '') {
		if (user.userRoles)
			contentHeaderTitle = `Роль: ${user.userRoles[roleName]}`;
			// contentHeaderParent.push({
			// 	url: '',
			// 	name: user.userRoles[roleName]
			// });
	}
	else {
		contentHeaderTitle = 'Все';
		// contentHeaderParent.push({
		// 	url: '',
		// 	name: 'Все'
		// });
	}

	// let usersList = null;
	// if (users && divisions)
	// 	usersList = <UsersList
	// 		users={users}
	// 		user={user}
	// 		divisions={divisions}
	// 		onDelete={onDelete}
	// 		roleInit={roleName}
	// 	/>

	// console.dir(user);
	if (user.isUser)
		return <Page404 />;

	return (
		<>
			<ContentHeader
				title={contentHeaderTitle}
				parent={contentHeaderParent}
			/>
			{panel}

			{/*{usersList}*/}
			{
				(users && users.length && divisions)
					? <UsersList
						users={users}
						user={user}
						divisions={divisions}
						onDelete={onDelete}
						roleInit={roleName}
					/>
				: null
			}
		</>
	);

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);

import React, {Fragment} from "react";
import {in_array, isAdmin, isOwner} from "utils/func";
import {SITE_URL, rolesAdmin, rolesModerator} from "utils/config";
import {Button} from "components/_common";
import {NavLink} from "react-router-dom";

const UsersListItem = (props) => {

	const {sysUser, user, onDelete, showRole = '', divisions,
		showColumnLogin = false, showColumnDivision = false
	} = props;

	//if (in_array(sysUser.role, ['admin'])))
	// console.dir(user);
	// console.log(divisions);

	let editButton = null, deleteButton = null;

	const userIsAdmin = isAdmin(sysUser);

	if (userIsAdmin || (user.divId == sysUser.divId && isOwner(sysUser))) {
		editButton = (
			<NavLink to={"/user/edit/" + user.id} title={"Редактировать"}>
				<i className="fa fa-edit"></i> Редактировать
			</NavLink>
		);

		if (user.id > 1)
			deleteButton = (
				<a href={"#"} onClick={(e) => onDelete(e, user)}>
					<i className="fa fa-close"></i> Удалить
				</a>
			);
	}

	const actionsMenu = (
			<div className="actions -left">
				<i className="fa fa-bars"></i>
				<div>
					<NavLink to={"/user/profile/" + user.id} title={"Информация"}>
						<i className="fa fa-info"></i> Информация
					</NavLink>
					{editButton}
					{deleteButton}

				</div>
			</div>
		);

	let tblColumnRole = [];
	switch(showRole) {
		default:
			tblColumnRole.push(
				<Fragment key={"user-col"}>
					{user.email}
					{
						user.phone !== '' ? <div className={"mt20"}>{user.phone}</div> : null
					}
				</Fragment>
			);

			break;
	}

	// if (in_array(sysUser.role, [...rolesModerator, 'manager']))
	// 	tblColumnRole.push(
	// 		<div className={tblColumnRole.length ? "mt20" : ""} key={'btn-user-files'}>
	// 			<Button
	// 				href={"/docs/user/" + user.id}
	// 				title={"Файлы"}
	// 				ico={"files-o"}
	// 				cls={"-lgrey -small"}
	// 			/>
	// 		</div>
	// 	);


	return (
		<tr key={user.id}>
			<td valign={"top"}>
				{actionsMenu}
			</td>
			<td className={"-name -href-clr"} valign={"top"}>
				<div className="flx -alc">
					<div className={"im"}>
						{
							user.avatar
							? <img src={SITE_URL + user.avatar} />
							: <i className={"fa fa-user-circle-o"}></i>
						}
					</div>
					{/*{*/}
					{/*	user.avatar ?*/}
					{/*		<div className={"im"} style={{backgroundImage:"url("+SITE_URL+user.avatar +")"}}></div>*/}
					{/*		: null*/}
					{/*}*/}
					<NavLink to={"/user/profile/" + user.id}>
						{user.name}
					</NavLink>
				</div>
			</td>
			{
				showColumnLogin
					? <td valign={"top"}>
						{user.login}
					</td>
					: null
			}
			<td valign={"top"}>
				{user.roleName.replace(/\//, ' / ')}
			</td>
			<td valign={"top"}>
				{tblColumnRole}
			</td>
			{
				showColumnDivision
					? <>
						<td valign={"top"}>
							{user.divisions ? user.divisions.map(divId => {
								return (<div key={divId}>
									{divisions[ divId ].name}
								</div>);
							}) : null}
						</td>
					</>
					: null

			}

		</tr>
	);
};
export default UsersListItem;

import React, {memo, useState, useCallback, createRef, useEffect, useRef} from 'react';
import {Button, Win, SmartSelect, isWinMinimized} from "components/_common";
import {in_array, objects_array_sort} from "utils/func";
// import Storage from "utils/Storage";
// import {STORAGE_TASKS_FILTER} from "utils/config";


const filterTitles = {
	status: 'Статус',
	filterByUser: 'Пользователи',
	filterByProject: 'Проекты'
};


const FilterWin = props => {

	const {
		applyFilterData,
		filterInit,
		statusList,
		users,
		projects,
	} = props;


	const filterRef = useRef(filterInit);

	const changeFilter = (selected, param) => {
		// console.log('changeFilter param:', param);
		// console.dir(selected);

		let values = [];
		let names = [];


		for (let [key, item] of selected) {
			// console.log(item);
			values.push(item.value);
			names.push(item.name);
		}

		filterRef.current[param] = {
			values: values,
			names: names
		}
		console.log(filterRef.current);
	};

	const FILTER_WIN_ID = "tasks-filter-win";


	let filterWinTitle = [];
	let filterFields = [];

	const {status = null, filterByUser = null, filterByProject = null} = filterRef.current;
	// console.dir(status);
	// console.log(filterRef.current);


	//статусы
	const statusOptions = [];
	for (const st in statusList) {
		// console.dir(st, filter.status.values, in_array(st, filter.status.values));
		statusOptions.push({
			value: st,
			name: statusList[st],
			selected: status && in_array(st, status.values)
		});
	}


	// console.dir(statusOptions);
	if (statusOptions.length)
		filterFields.push(
			<div className="col-status" key={"col-status"}>
				<SmartSelect
					name={"status"}
					key={"filter-status"}
					placeholder={"Все"}
					label={filterTitles.status}
					options={statusOptions}
					optionsHash={statusOptions.length}
					multi={true}
					deleteOnReselect={true}
					onSelect={changeFilter}
				/>
			</div>
		);
	// console.dir(users);
	const usersOptions = [];
	users.map(usr => {
		usersOptions.push({
			value: usr.id,
			name: usr.name,
			selected: filterByUser && in_array(usr.id, filterByUser.values)
		});
	});
	if (usersOptions.length)
		filterFields.push(
			<div className="col-users" key={"col-users"}>
				<SmartSelect
					name={"filterByUser"}
					key={"filter-users"}
					placeholder={"Все"}
					label={filterTitles.filterByUser}
					options={usersOptions}
					optionsHash={usersOptions.length}
					multi={true}
					deleteOnReselect={true}
					onSelect={changeFilter}
				/>
			</div>
		);

	let projectsOptions = [];
	Object.values(projects).map(proj => {
		projectsOptions.push({
			value: proj.id,
			name: proj.name.replace('\\', '/'),
			selected: filterByProject && in_array(proj.id, filterByProject.values)
		});
	});
	objects_array_sort(projectsOptions, 'name');

	if (projectsOptions.length) {
		filterFields.push(
			<div className="col-projects" key={"col-projects"}>
				<SmartSelect
					name={"filterByProject"}
					key={"filter-projects"}
					placeholder={"Все"}
					label={filterTitles.filterByProject}
					options={projectsOptions}
					optionsHash={projectsOptions.length}
					multi={true}
					deleteOnReselect={true}
					onSelect={changeFilter}
				/>
			</div>
		);
	}



	let winHeader = [];
	for (let prm in filterRef.current) {
		let {names = []} = filterRef.current[prm];
		// console.log(prm, names);

		if (names.length) {
			winHeader.push(
				<span key={"tft-" + prm}>
				{filterTitles[prm]}: {names.join(', ')}
			</span>
			);
		}

	}


	const filterWinFooter = <div className="win-ftr-btns _tac">
		<Button
			title={"Применить"}
			onClick={() => {
				// console.clear();
				applyFilterData(filterRef.current);
			}}
		/>
	</div>;


	// useEffect(() => {
	// 		console.log(filterRef.current);
	// 		return () => {
	// 			// setSelected(new Set());
	// 		}
	// 	},
	// 	[filterRef]
	// 	// []
	// );


	return (
		<Win
			id={FILTER_WIN_ID}
			noOverflowContent={true}
			header={(<div className="tasks-filter-title">
				<b>Фильтр</b> {winHeader}
			</div>)}
			// header={"Фильтр"}
			minimized={true}
			footer={filterWinFooter}
		>
			<div className="tasks-filter flx">
				{filterFields}
			</div>

		</Win>
	)

};
export default FilterWin;
// export default memo(FilterWin);

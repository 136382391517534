import React from 'react';
import {NavLink} from "react-router-dom";
import {in_array} from "utils/func";

const DivisionListItem = props => {

	const {sysUser, division, onDelete} = props;

	let actionsMenu = null;

	if (in_array(sysUser.role, ['admin']))
		actionsMenu = (
			<div className="actions -left">
				<i className="fa fa-bars"></i>
				<div>
					<NavLink to={"/user/divisions/edit/" + division.id} title={"Редактировать"}>
						<i className="fa fa-edit"></i> Редактировать
					</NavLink>
					<a href={"#"} onClick={(e) => onDelete(e, division)}>
						<i className="fa fa-close"></i> Удалить
					</a>
				</div>
			</div>
		);

	return (
		<tr key={division.id}>
			<td valign={"top"}>
				{actionsMenu}
			</td>
			<td>
				{division.name}
			</td>
			<td>
				{division.txt}
			</td>
			<td className={"_tac"}>
				{division.countUsers}
			</td>
		</tr>
	);

};

export default DivisionListItem;

import React, {useState} from 'react';
import cn from "classnames";
import date from "php-date";
import * as strtotime from "strtotime";
import {toastr} from "react-redux-toastr";
import {FormItem, FormItemType} from "components/_common";
import {in_array, array_unset} from "utils/func";
import {rolesAdmin, rolesModerator, SITE_URL, MESSAGES} from "utils/config";

function checkNotificationPromise() {
	try {
		Notification.requestPermission().then();
	} catch(e) {
		return false;
	}

	return true;
}
function askNotificationPermission() {
	// Function to actually ask the permissions
	function handlePermission(permission) {
		// Whatever the user answers, we make sure Chrome stores the information
		if (!Reflect.has(Notification, 'permission')) {
			Notification.permission = permission;
		}

	}

	//Check if the browser supports notifications
	if (!Reflect.has(window, 'Notification')) {
		console.log('This browser does not support notifications.');
	} else {
		if (checkNotificationPromise()) {
			Notification.requestPermission().then(handlePermission);
		} else {
			Notification.requestPermission(handlePermission);
		}
	}
}

const Personal = props => {
	const {user,
		settings, savedSettings, setSavedSettings,
	} = props;

	let notificationGranted = false;
	if (Reflect.has(window, 'Notification') && Notification.permission === 'granted') {
		notificationGranted = true;
	}

	console.log(Notification.permission, notificationGranted);


	return (
		<>
			<FormItem
				fieldType={FormItemType.text}
				name={""}
				label={"Ширина контентной части сайта при ширине экрана больше 1024px, (укажите вместе с px или %)"}
				defVal={settings['user_' + user.id + '_style_content_max_width'] && settings['user_' + user.id + '_style_content_max_width']}
				changeAction={e => {
					setSavedSettings({
						...savedSettings,
						['user_' + user.id + '_style_content_max_width']: e.target.value.trim()
					});
				}}
				descr={"если пусто, то 100%"}
			/>
			<FormItem
				fieldType={FormItemType.bool}
				name={"notification"}
				label={notificationGranted ? "Уведомления включены" : "Включить уведомления от сайта (Web Notification)"}
				isChecked={notificationGranted}
				changeAction={e => {
					askNotificationPermission();
				}}
				edit={!notificationGranted}
			/>
		</>
	)

};

export default Personal;
import React from "react";
import {toastr} from "react-redux-toastr";
import {roundTo} from "round-to";
import Api, {apiAction} from "utils/Api";
import {saveSync} from "save-file";
import {API_URLS, MESSAGES, rolesAdmin, rolesModerator, SITE_URL, SITE_URL_FRONT} from "utils/config";
import date from "php-date";
import CryptoJS from "crypto-js";
import Storage from "utils/Storage";
import {SESSION_USER} from "actions/UserActions";
import cn from "classnames";
import striptags from "striptags";


export const in_array = (val, arr) => {

	return arr.indexOf(val) !== -1;
};
export const array_unset = (arr, val) => {
	const i = (typeof val === 'number') ? val : arr.indexOf(val);

	if (i > -1)
		arr.splice(i, 1);

	return arr;
};
export const is_empty = arr => {
	if (Array.isArray(arr))
		return !arr.length;
	if (typeof arr === 'object')
		return !Object.keys(arr).length;

	return true;
};
export const objects_array_sort = (arr, field) => {
	arr.sort((a,b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
}
export const unixTime = () => {
	return Math.round((new Date()).getTime() / 1000);
};
export const formatDate = (dateSrc, toUnixtime = false, withHours = false) => {
	const date = new Date(dateSrc);

	const getFullDayMoth = (val) => {
		return val > 9 ? val : '0' + val;
	};

	if (toUnixtime)
		return Math.round(date.getTime() / 1000);

	let formatted = getFullDayMoth(date.getDate()) + '.' + getFullDayMoth((date.getMonth() + 1)) + '.' + date.getFullYear();

	if (withHours)
		formatted += ' ' + date.getHours() + ':' + date.getMinutes();

	return formatted;
};
export const dateFormat = (dt, format = 'd.m.Y') => {
	// console.log(dt, typeof  dt);
	// if (typeof  dt === 'string')
	// 	dt = strtotime(dt);// * 1000;
	//
	// console.log(dt, typeof  dt);
	//
	// if (typeof  dt !== 'number')
	// 	throw new Error('dateFormat  date must be number or string');
	// console.log('dateFormat', dt);

	// if (typeof dt === 'number')
	// 	dt = dt * 1000;


	return date(format, new Date(dt));
};
export const getFullDayMoth = (val) => {
	return val > 9 ? val : '0' + val;
};
export const get_noun = (_num, form_for_1, form_for_2, form_for_5) => {

	form_for_5 = form_for_5 || form_for_2;
	const num = Math.abs(_num) % 100; // берем число по модулю и сбрасываем сотни (делим на 100, а остаток присваиваем переменной num)
	const num_x = num % 10; // сбрасываем десятки и записываем в новую переменную
	if (num > 10 && num < 20) // если число принадлежит отрезку [11;19]
		return form_for_5;
	if (num_x > 1 && num_x < 5) // иначе если число оканчивается на 2,3,4
		return form_for_2;
	if (num_x === 1) // иначе если оканчивается на 1
		return form_for_1;

	return form_for_5;
};

export const string2num = (value, roundDigits = 0) => {

	if (isNaN(value))
		return 0;

	let newValue = value.replace(/,/g, '.');
	newValue.trim();

	newValue = !roundDigits ? parseInt(newValue) : parseFloat(newValue);
	newValue = newValue || 0;

	return roundTo(newValue, roundDigits);
};
export const getFileSize = (fsizeBytes, withPostfix = true) => {
	let unit = '', size = 0;
	if (fsizeBytes > 1024 * 1024) {
		size = Math.round(fsizeBytes / 1024 / 1024);
		unit = 'Мб';
	} else if (fsizeBytes > 1024) {
		size = Math.round(fsizeBytes / 1024);
		unit = 'Кб';
	} else {
		size = fsizeBytes;
		unit = 'байт';
	}

	return size + (withPostfix ? ' ' + unit : '');
};


export const getFileIcon = (extension, file = null) => {

	// console.log(extension, file);

	let fileIco = null;
	switch (extension) {
		case "pdf":
			fileIco = <i className={"fico fa fa-file-pdf-o"}></i>;
			break;
		case "doc":
		case "docx":
			fileIco = <i className={"fico fa fa-file-word-o"}></i>;
			break;
		case "xls":
		case "xlsx":
			fileIco = <i className={"fico fa fa-file-excel-o"}></i>;
			break;
		case "txt":
			fileIco = <i className={"fico fa fa-file-text-o"}></i>;
			break;
		case "jpg":
		case "jpeg":
		case "png":
		case "gif":
		case "bmp":
		case "tiff":
		case "webp":
			if (file !== null) {
				let src;
				if (file.path) {
					src = SITE_URL + trim(file.path, '/');
				} else {
					src = URL.createObjectURL(file);
				}
				fileIco = <img src={src}/>
			} else
				fileIco = <i className={"fico fa fa-file-image-o"}></i>;
			break;
		case "zip":
		case "rar":
		case "7z":
		case "tar":
		case "gz":
			fileIco = <i className={"fico fa fa-file-archive-o"}></i>;
			break;
		default:
			fileIco = <i className={"fico fa fa-file-o"}></i>;
			break;
	}
	return fileIco;
};

export const number_format = (number, decimals = 2, dec_point = ".", thousands_sep = " ") => {	// Format a number with grouped thousands
	let i, kw, kd;

	i = parseInt(number = (+number || 0).toFixed(decimals)) + "";
	kw = i.split(/(?=(?:\d{3})+$)/).join(thousands_sep);
	kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : "");

	return kw + kd;
};

export const deleteFile = async (id) => {
	console.log('delete file', id);

	let deleted = false;
	await apiSend(
		API_URLS.utils,
		{
			action: 'deleteFile',
			id: id,
		},
		(res) => {
			console.log(res);
			deleted = res.deleted;
		}
	)

	return deleted;
	// if (typeof callback === 'function')
	// 	callback(id);
}
export const downloadFile = async (id) => {
	await apiSend(
		API_URLS.utils,
		{
			action: 'downloadFile',
			id: id,
		},
		(res) => {
			saveSync(
				res.data,
				res.name
			);
		}
	);
};
export const actionMenuButton = (buttons, isOpen, setOpen) => {
	if ((typeof buttons == 'object' && buttons.length == 0) || buttons == '')
		return null;

	return (
		<div
			className={cn("actions -left", {open: isOpen})}
			 onClick={() => setOpen(!isOpen)}
			 onMouseLeave={() => setOpen(false)}
		>
			<i
				className="fa fa-bars"
				// onClick={() => setOpen(!isOpen)}
			></i>
			<div>
				{buttons}
			</div>
		</div>
	);
}


export const rand = (x, y) => {
	return parseInt(Math.random() * (y + 1 - x) + x, 10);
}
export const arrayRemove = (k, arr) => {
	const i = arr.indexOf(k);
	return i > -1 ? arr.splice(i, 1) : [];
}
let isApiSend = false;
export const apiSend = async (api_url, params, callback, actions) => {

	if (!isApiSend) {
		isApiSend = true;
		let start = Date.now();

		if (actions)
			actions.showLoading();

		try {

			// console.dir(params);

			const sessionUser = Storage.get(SESSION_USER, null, true);
			// console.log('api send token', sessionUser.token);

			const res = await Api.post(api_url, params, {headers: {'token': sessionUser ? sessionUser.token : ''}});

			apiAction(res, (data) => {
				callback(data, res.headers);
				console.log('time:', (Date.now() - start) / 1000 + ' sec');
			}, () => {
				isApiSend = false;
				if (actions)
					actions.hideLoading();
			});
		} catch (e) {
			isApiSend = false;
			if (e.response && e.response.data) {
				toastr.error(
					`${e.response.data.error} (${e.response.data.statusCode})`,
					e.response.data.message,
					{timeOut: 0}
				);
			} else
				toastr.error('Ошибка', 'apiSend: ' + e.message);


		}
		if (actions)
			actions.hideLoading();
	}
}
let confirmActionProcess = false;
export const confirmApiAction = (url, params, callback, actions = null, msg = '') => {
	toastr.confirm(
		msg != '' ? msg : MESSAGES.confirmAction,
		{
			okText: 'Да',
			cancelText: 'Нет',
			onOk: async () => {

				if (!confirmActionProcess) {
					confirmActionProcess = true;

					await apiSend(
						url,
						params,
						(res) => {
							if (typeof callback === 'function')
								callback(res);
							confirmActionProcess = false;
						},
						actions
					);
				}
			},

		}
	);
}
export const copyClipboard = str => {
	//https://stackoverflow.com/a/65996386
	if (navigator.clipboard && window.isSecureContext) {
		navigator.clipboard.writeText(str).then(null);
	} else {
		// text area method
		let textArea = document.createElement("textarea");
		textArea.value = str;
		// make the textarea out of viewport
		textArea.style.position = "fixed";
		textArea.style.left = "-999999px";
		textArea.style.top = "-999999px";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		document.execCommand('copy');
		textArea.remove();

	}

	toastr.info('Сообщение', 'Скопировано в буфер обмена');
}
export const bool_val = v => {

	if (v === true || v === false)
		return v;

	if (typeof v === 'string') {
		if ('true' === v)
			return true;
		if ('false' === v)
			return false;
		if ('1' === v)
			return true;
		if ('0' === v || v === '')
			return false;
		if ('' !== v)
			return true;
	} else if (typeof v === 'number' && v > 0)
		return true;
	else if (typeof v === 'object' && Object.keys(v).length)
		return true;

	return false;
}
export const uploadFileInfo = file => {
	return {
		name: file.name,
		size: file.size,
		mime: file.type
	};
}
export const isRole = (user, roles) => {
	if (!user || !user.role)
		return false;
	return in_array(user.role, roles);
}
export const isAdmin = user => {
	return isRole(user, rolesAdmin);
}
export const isOwnerPlus = user => {
	return isRole(user, rolesModerator);
}
export const isOwner = user => {
	return isRole(user, ['owner']);
}
export const isUser = user => {
	return isRole(user, ['user']);
}

export const encrypt = (str, skey, salt) => {
	// let encoded = CryptoJS.AES.encrypt(str, skey + salt).toString();
	// const encodedWord = CryptoJS.enc.Utf8.parse(CryptoJS.AES.encrypt(str, skey + salt).toString());
	// console.log(str, CryptoJS.enc.Base64.stringify(encodedWord));

	return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(CryptoJS.AES.encrypt(str, skey + salt).toString()));
}
export const decrypt = (str, skey, salt) => {


	// const encodedWord = CryptoJS.enc.Base64.parse(str);
	// const decoded64 = CryptoJS.enc.Utf8.stringify(encodedWord);
	// console.log(decoded64);
	let bytes = CryptoJS.AES.decrypt(
		CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(str)),
		skey + salt
	);
	// let bytes  = CryptoJS.AES.decrypt(decoded64, skey + salt);
	return bytes.toString(CryptoJS.enc.Utf8);//CryptoJS.enc.Utf8

}
export const base64_encode = str => {
	return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(str));
}
export const base64_decode = str => {
	return CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(str));
}

export const trim = (str, charlist) => {
	if (typeof str !== "string" || str == '')
		return str;

	charlist = !charlist ? ' \s\xA0' : charlist.replace(/([\[\]\(\)\.\?\/\*\{\}\+\$\^\:])/g, '\$1');

	let re = new RegExp('^[' + charlist + ']+|[' + charlist + ']+$', 'g');
	return str.replace(re, '');
}

export const generateRandomString = (maxLength, smallLetters = false, digits = false, delimiter = '', delimiterPacks = 4) => {
	let simbArr = [];

	for (let i = 0; i <= 9; i++)
		simbArr.push(i);

	if (smallLetters)
		for (let i = 97; i <= 122; i++)
			simbArr.push(String.fromCharCode(i));

	for (let i = 65; i <= 90; i++)
		simbArr.push(String.fromCharCode(i));

	if (digits)
		for (let i = 0; i <= 9; i++)
			simbArr.push(i);

	let simbArrCount = simbArr.length - 1;

	let result = '';
	if (simbArrCount > 0) {
		do {
			result += simbArr[rand(0, simbArrCount)];
		}
		while (result.length < maxLength);
	}

	if (delimiter != '') {
		let t = '';
		for (let i = 0; i < result.length; i++) {
			if (i > 0 && i % delimiterPacks == 0)
				t += delimiter;
			t += result[i];

		}
		result = t;
	}
	return result;
}
export const NotificationType = {
	info: {
		title: 'Сообщение',
		icon: ''
	}
};
export const createNotification = (type, msg, title = '', url = '', icon = '') => {

	let _title = title;
	if (_title == '') {
		if (NotificationType.hasOwnProperty(type))
			_title = NotificationType[type].title;
		else
			_title = 'Уведомление';
	}


	if (icon != '') {
		if (icon.indexOf('http') === -1)
			icon = `${SITE_URL_FRONT}${icon}`;
	}
	else
		icon = `${SITE_URL_FRONT}img/logo64.png`;


	console.log(`createNotification "${_title}"`,  striptags(msg), icon);

	const notification = new Notification(
		_title,
		{
			body: striptags(msg),//cut more 120 chars
			icon: icon
		}
	);
	if (url  != '')
		notification.onclick = function() {
			window.location.href = url;
		};
}

// export const encode_str = (str, salt = 777) => {
// 	let encoded = '';
// 	const alpha_map = ['P', 'M', 'S', 'K'];
// 	str = str.toString();
// 	const len = str.length;
// 	const ascii_arr = [];
// 	// var salt = 150;
//
// 	for (let x = 0; x < len; x++) {
// 		ascii_arr[x] = (rand(1, 9) * 1000) + (salt + str.charCodeAt(x));
// 	}
//
// 	const enc = ascii_arr.join('') + salt;
// 	const enc_len = enc.length;
//
// 	for (let x = 0; x < enc_len; x++) {
// 		if (typeof(alpha_map[enc[x]]) != 'undefined') {
// 			encoded += alpha_map[enc[x]];
// 		} else {
// 			encoded += enc[x];
// 		}
// 	}
//
// 	return encoded.toLowerCase();
// }
//
// export const decode_str = (str) => {
// 	str = str.toUpperCase();
// 	const unit_size = 4;
// 	const alphatonum = (s) => {
// 		const alpha_map = ['P', 'M', 'S', 'K'];
// 		const l = s.length;
// 		let s_ = '';
//
// 		for (let x = 0; x < l; x++) {
// 			const pos = alpha_map.indexOf(s[x]);
// 			s_ += (pos >= 0) ? pos : s[x];
// 		}
//
// 		return s_;
// 	};
//
// 	let decoded = '';
// 	const salt = alphatonum(str.substr(-3));
// 	const data = alphatonum(str.substr(0, (str.length - 3)));
//
// 	var loop = data.length / unit_size;
//
// 	for (let x = 0; x < loop; x++) {
// 		const unit = data.substr(x * unit_size, unit_size);
// 		const ascii_ = (unit % 1000) - salt;
// 		decoded += String.fromCharCode(ascii_)
// 	}
//
// 	return decoded;
// }

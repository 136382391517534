import React, {useState, useEffect, useRef, forwardRef, useReducer} from 'react';
import {useParams} from "react-router-dom";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {rolesModerator, MENU, MENU_TITLE, MESSAGES, STORAGE_TASKS_FILTER, API_URLS} from "utils/config";
import {toastr} from "react-redux-toastr";
import {ContentHeader, Button, Win} from "components/_common";
import {FilterWin, TasksList} from "components/tasks";
// import {NavLink} from "react-router-dom";
import {in_array, apiSend} from "utils/func";
import {setIsPrint, setCalendarSelectDateAction, setSearchAction, setTasksFilter} from "actions/GUIActions";
import Storage from "utils/Storage";
// import date from "php-date";
// import {saveSync} from "save-file";
import {sortTasks} from "components/tasks/utils";
// import * as strtotime from "strtotime";
// import {SmartSelect} from "components/_common";
import useUpdateListener from "hooks/useUpdateListener";
import {SESSION_USER} from "actions/UserActions";


const TASKS_ACTION_GET_API = 'tasks_get_from_api';
const TASKS_ACTION_DELETE = 'tasks_delete_task';
export const TASKS_ACTION_SORTING = 'tasks_sorting';
// const TASKS_ACTION_SORTING_LAST_FIELD = 'tasks_sorting_last_field';

export const sortingEmpty = {
	// name: 0,
	weight: 0,
	status: 0,
	created: 0,//1 = ASC, -1 = DESC
	end_date: 0,
	pid: 0,
};
const sortingInit = Storage.get(TASKS_ACTION_SORTING, sortingEmpty, true);
const initState = {
	// tasksRaw: [],
	tasks: [],
	sites: [],
	statusList: null,
	executorsUsers: null,//object
	sorting: sortingInit,
	users: [],//array
}

const reducer = (state, action) => {

	// console.dir(state);
	switch (action.type) {
		case TASKS_ACTION_GET_API:

			const {tasks: sortedTasks} = sortTasks(
				action.data.tasks,
				state.sorting,
				''//Storage.get(TASKS_ACTION_SORTING_LAST_FIELD, '')
			);
			return {
				...state,
				tasks: sortedTasks,//action.data.tasks,
				projects: action.data.projects,
				statusList: action.data.statusList,
				executorsUsers: action.data.executorsUsers,
				users: action.data.users
			}
			break;

		case TASKS_ACTION_DELETE:
			const {taskId = 0} = action;
			const newTasks = [];
			state.tasks.map(task => {
				if (task.id !== taskId)
					newTasks.push(task);
			});
			return {
				...state,
				tasks: newTasks
			};
		case TASKS_ACTION_SORTING:
			// console.log(action);
			const {tasks, sorting} = sortTasks(state.tasks, state.sorting, action.field);
			Storage.set(TASKS_ACTION_SORTING, sorting);
			// Storage.set(TASKS_ACTION_SORTING, sortingEmpty);
			// Storage.set(TASKS_ACTION_SORTING_LAST_FIELD, action.field);

			return {
				...state,
				tasks: tasks,
				sorting: sorting
			};

		default:
			return state;
	}
};

export const deleteTask = async (id, actions, callback) => {
	//тут вопрос на удаление toastr

	if (window.confirm(MESSAGES.confirmAction)) {
		// console.log('deleteTask');
		await apiSend(
			API_URLS.tasks,
			{
				action: 'deleteTask',
				id: id,
			},
			(res) => {
				console.dir(res);

				if (typeof callback == 'function')
					callback(res);


			},
			actions
		);
	}
	// toastr.confirm(
	// 	MESSAGES.confirmAction,
	// 	{
	// 		okText: 'Да',
	// 		cancelText: 'Нет',
	// 		onOk: async () => {
	// 			await apiSend(
	// 				API_URLS.tasks,
	// 				{
	// 					action: 'deleteTask',
	// 					id: id,
	// 				},
	// 				(res) => {
	// 					console.dir(res);
	//
	// 					if (typeof callback == 'function')
	// 						callback(res);
	//
	//
	// 				},
	// 				actions
	// 			);
	// 		}
	// 	}
	// );
}
const TasksListContainer = (props) => {

	const {user, GUI, actions} = props;

	const [state, dispatch] = useReducer(reducer, initState);

	const {tasks, statusList, projects, executorsUsers, sorting, users = []} = state;

	const filterInit = Storage.get(STORAGE_TASKS_FILTER, {}, true);


	const updateDataTime = useUpdateListener(GUI.socket, user.id, 'tasks');


	// console.log(sorting);

	// console.log('TaskListContainer RENDER');

	const getData = async (filterData = {}) => {
		console.log('%cgetTasks............', 'color: orange; font-weight: bold');

		// console.log(filterData);
		await apiSend(
			API_URLS.tasks,
			{
				action: 'getTasks',
				filter: filterData
			},
			(res) => {
				// console.dir(res);
				dispatch({type: TASKS_ACTION_GET_API, data: res});
			},
			actions
		)
	}

	const applyFilter = async (data) => {
		console.log('applyFilter');
		console.dir(data);
		Storage.set(STORAGE_TASKS_FILTER, data);

		// console.log(Storage.get(STORAGE_TASKS_FILTER));

		await getData(data);
	}

	const doSearch = str => {
		alert('сделать поиск');
	};

	const updateListener = res => {
		console.log('SOCKET - tasks updateListener', res);
		// console.log(GUI.calendarDates);
		// if (parseInt(res.userId) !== parseInt(user.id))
		getData(filterInit).then(null);
	}


	useEffect(() => {
			console.log('TaskListContainer useEffect');
			let isSubscribe = true;

			if (isSubscribe)
				getData(filterInit).then(null);

			// actions.setSearchAction(doSearch);

			if (GUI.socket)
				GUI.socket.on('updateTasksList', updateListener);

			return () => {
				isSubscribe = false;

				// actions.setSearchAction(null);

				if (GUI.socket)
					GUI.socket.off('updateTasksList', updateListener);
			}
		},
		[dispatch, updateDataTime]
		// ['getTasks', 'onlyTodoList']
	);


	const onDeleteTask = async (taskId) => {

		console.log(state);

		await deleteTask(taskId, actions, (res) => {
			if (res.deleted)
				dispatch({type: TASKS_ACTION_DELETE, taskId: taskId});
		});

	}

	let panelTop = [];
	panelTop.push(
		<Button
			key={'create-task-btn'}
			title={"Создать задачу"}
			href={"/tasks/new/"}
			ico={"plus"}
			type={"save"}
		/>
	);

	// let tasksList = [];


	return (
		<>
			<ContentHeader
				title={MENU_TITLE.tasks.many}
				// parent={{url: '/tasks', name: 'Заявки'}}
				showDates={false}
				panelContent={panelTop}
			/>
			{
				statusList
					? <FilterWin
						applyFilterData={applyFilter}
						statusList={statusList}
						users={users}
						filterInit={filterInit}
						projects={projects}

					/>
					: null
			}

			<TasksList
				user={user}
				// GUI={GUI}
				actions={actions}
				tasks={tasks}
				projects={projects}
				statusList={statusList}
				executorsUsers={executorsUsers}
				deleteTask={onDeleteTask}
				sorting={sorting}
				dispatch={dispatch}
			/>

		</>
	)
};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, setIsPrint, setCalendarSelectDateAction, setSearchAction,
		// setTasksFilter
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TasksListContainer);

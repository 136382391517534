import {useState, useEffect, useCallback} from 'react';

const useUpdateListener = (socket, userId, target = null) => {

	const [updateTime, setUpdateTime] = useState(false);
	// const [data, setData] = useState([]);


	useEffect(() => {

		if (socket) {
			socket.emit(
				'setUpdateListener',
				{
					userId: userId,
					target: target
				});

			socket.on("updateData", response => {
				// console.log('updateData', response);
				setUpdateTime(response);
			});
		}

		return () => {
			if (socket)
				socket.emit(
					'setUpdateListener',
					{
						userId: userId,
						target: null
					});
		}
	}
		, [socket, target]
	);

	return updateTime;
};
export default useUpdateListener;
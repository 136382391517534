import React, {useState} from 'react';
import * as linkify from "linkifyjs";
import striptags from "striptags";
import {BUTTON_TYPE, Button, Win, FormItem, SmartSelect, FileUploader} from "components/_common";
import {useNavigate} from "react-router-dom";
import {URLS} from "utils/config";
import {copyClipboard, dateFormat} from "utils/func";
import {TaskAccess} from "components/tasks";


const TaskShow = props => {

	const {
		user,
		task,
		files = [],
		project = null,
		site = null,
		executor = null,
		viewers = [],
		underwater = '',
		statusList = null,
		setStatus = async (id, val) => {
			alert('setStatus not defined')
		}
	} = props;
	let navigate = useNavigate();

	const [showAccess, setShowAccess] = useState(false);


	const canEdit = (user.isAdmin || user.id == task.uid);

	// let winHeader = (
	// 	<div className={"flx -sb X-alc w100 task-title"}>
	// 		<div>
	//
	// 			Задача #{task.id}
	// 		</div>
	// 		<div>
	//
	// 		</div>
	// 	</div>
	// );

	let winHeader;
	winHeader = <div className="flx -alc -sb w100">
		<div>Задача #{task.id}</div>
		<div>
			<i className={"b fa fa-clone"}
			   onClick={() => copyClipboard(task.name)}
			   title={"Копировать название задачи в буфер обмена"}
			/>
			<i className={"b fa fa-link"}
			   onClick={() => copyClipboard(window.location.href)}
			   title={"Копировать ссылку на задачу в буфер обмена"}
			/>
			<i className={"b space"}/>
		</div>
	</div>
	// winHeader = `Задача #${task.id}`;

	let winFooter = null;
	if (files.length)
		winFooter = <FileUploader
			files={files}
			canUpload={false}
		/>;

	let winInfoHeader = <div className="flx -sb -alc w100">
		<i className={"fa fa-info"}/>
		<div>
			Важность: {task.weight}
		</div>
	</div>;
	// console.dir(executor);
	let viewersNames = [];
	viewers.map(usr => viewersNames.push(usr.name));

	let statusSelect = null;
	if (statusList) {
		const statusSelectOptions = [];
		for (let stId in statusList) {
			statusSelectOptions.push({
				name: statusList[stId],
				value: stId,
				selected: stId == task.status
			});
		}

		statusSelect = (
			<TaskInfoItem label={"Статус"}>
				<SmartSelect
					name={"pid"}
					label={""}
					placeholder={""}
					options={statusSelectOptions}
					arrow={true}
					onSelect={async (selected) => {

						let value = (selected.values()).next().value['value'];
						await setStatus(task.id, value);
					}}
				/>
			</TaskInfoItem>
		);
	}


	return (
		<div className={"row task-page"}>
			<div className="col-2-3">
				<Win
					header={winHeader}
					onClose={() => navigate(URLS.tasks)}
					winClass={"hdr-grey"}
					footer={winFooter}
				>
					<div
						dangerouslySetInnerHTML={{__html: task.txtFormatted}}
						className={"task-txt"}
					/>
				</Win>
			</div>
			<div className="col-3">
				<Win
					header={winInfoHeader}
					winClass={"hdr-grey"}
					// footer={winFooter}
				>
					<div className="task-info">

						{statusSelect}

						{
							project !== null
								? <TaskInfoItem label={"Проект"}>{project.name}</TaskInfoItem>
								: null
						}
						{
							site !== null
								? <TaskInfoItem
									label={"Сайт"}
								>
									<a href={"//" + site.domain} target={"_blank"}>{site.domain}</a>
									<div
										className="task-info-show-site-info"
										onClick={() => setShowAccess(!showAccess)}
									>
										<i className={"fa fa-info-circle"}/> доступы
									</div>
								</TaskInfoItem>
								: null
						}
						{
							showAccess
								? <TaskAccess site={site} underwater={underwater} canEdit={canEdit}/>
								: null
						}
						{/*<TaskAccess site={site} underwater={underwater} canEdit={canEdit} />*/}

						{
							task.uid !== 0
								? <TaskInfoItem
									label={"Постановщик"}
								>
									{task.userName}
								</TaskInfoItem>
								: null
						}
						{
							executor
								? <TaskInfoItem
									label={"Исполнитель"}
								>
									{executor.name}
								</TaskInfoItem>
								: null
						}
						{
							viewers.length
								? <TaskInfoItem
									label={"Наблюдател" + (viewers.length == 1 ? 'ь' : 'и')}
								>
									{viewersNames.join(', ')}
								</TaskInfoItem>
								: null

						}

						<TaskInfoItem
							label={"Крайний срок"}
						>
							{dateFormat(task.end_date)}
						</TaskInfoItem>
					</div>

					{
						canEdit
							? <Button
								type={BUTTON_TYPE.edit}
								onClick={() => navigate(`${URLS.tasks}/edit/${task.id}`)}
								className={"-small"}
							/>
							// <i
							// 	className={"fa fa-edit b tip"}
							// 	onClick={() => navigate(`${URLS.tasks}/edit/${task.id}`)}
							// 	title={"Редактировать задачу"}
							// />
							: null
					}
				</Win>
			</div>
		</div>
	)

};

export default TaskShow;


const TaskInfoItem = ({label, children}) => {
	return (
		<div className={"task-info-item"}>
			<label>{label}:</label>
			<div>
				{children}
			</div>
		</div>
		// <div className="task-info-item">
		// 	<div>
		// 		{label}
		// 	</div>
		// 	<div>
		// 		{value}
		// 	</div>
		// </div>
	);
}

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cn from "classnames";
import Storage from "utils/Storage";
import {bool_val} from "utils/func";


export const isWinMinimized = id => {
	return bool_val(Storage.get('win_hidden_' + id, false));
}

const Win = (props) => {

	const {
		children = null, header = null, footer = null, wrap = true,
		winClass = '',
		onClose = null,
		noOverflowContent = true,

		minimized = false, //может быть минимизировано- добавляет кнопку
		isMinimized = null,//по умолчанию  null | true | false
		titleIfMinimized = '',//показывать заголовок когда окно свернуто
		minimizedDefault = false,

		id = null,//нужен для запоминания состояния окна
		onDropOnContent = null, onDropOnWin = null,
		onClick=null
	} = props;

	const [isContentHide, setContentHide] = useState(
		isMinimized !== null ? isMinimized :
			(id ? isWinMinimized(id) : minimizedDefault)
	);
	// if (id == 'file-uploader-win')
	// 	console.log('isMinimized', isMinimized);
	// const [isContentHide, setContentHide] = useState(isMinimized);
	//
	// if (id == 'file-uploader-win') {
	// 	// console.clear();
	// 	console.log('isMinimized', isMinimized);
	// 	// console.log((id ? isWinMinimized(id) : minimizedDefault));
	// 	console.log('isContentHide', isContentHide);
	// }


	let defHeader = <div>&nbsp;</div>;


	// console.log(onClose);
	// console.log(header + typeof header );
	let headerCont = null;
	if (header || onClose || minimized) {

		if (titleIfMinimized != '' && !header)
			defHeader = titleIfMinimized;// + ' - id ' + id;

		headerCont = (
			<div
				className={"h" + ((onClose || minimized) ? ' -wc' : '')}
				onClick={() => {
					if (minimized) {
						if (id) {
							const newIsContentHide = !isContentHide;
							console.log('win_hidden_' + id, newIsContentHide);

							Storage.set(
								'win_hidden_' + id,
								newIsContentHide ? true : null
							);

						}

						setContentHide(!isContentHide);
					}
				}}
			>
				{header ? header : defHeader}

				<div>
					{
						minimized ? <i
							className={cn("b fa", {
								"fa-window-minimize": !isContentHide,
								"fa-window-maximize": isContentHide
							})}

							title={isContentHide ? 'Развернуть' : 'Свернуть'}
						></i> : null
					}
					{onClose ?
						<i className="b fa fa-close" onClick={onClose}></i>
						: null
					}
				</div>


			</div>
		);
	}


	let cont = "";
	if (wrap) {
		cont = (
			<div className="-wrap">
				{children}
			</div>
		);
	} else
		cont = children;

	return (
		<div
			className={cn("win", winClass, {
			minimized: isContentHide,
			'only-footer': (children === null && header === null)
			})}
			 id={id}
			onDragOver={e => e.preventDefault()}
			onDrop={e => onDropOnWin != null ? onDropOnWin(e) : null}
			onClick={onClick}
		>
			{headerCont}
			{
				cont
					? <div
						className={cn("c", {
							"-no-overflow": noOverflowContent,
							"hide": isContentHide
						})}
						onDragOver={e => e.preventDefault()}
						onDrop={e => onDropOnContent != null ? onDropOnContent(e) : null}

					>

						{cont}
					</div>
					: null
			}

			{(footer && !isContentHide) ? <div className="f">{footer}</div> : null}
		</div>
	)
};


Win.propTypes = {
	header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),//PropTypes.node.isRequired,
	footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	winClass: PropTypes.string,
	onClose: PropTypes.func,
	onDropOnContent: PropTypes.func,
};

export default Win;

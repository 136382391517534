import React from 'react';
// import cn from "classnames";
import {TasksListTableRow} from  "components/tasks";
import {TASKS_ACTION_SORTING} from "containers/tasks/TasksListContainer";

const TasksList = props => {

	const {user,
		// GUI,
		actions,
		tasks = [],
		projects, statusList,
		executorsUsers,
		deleteTask = null,
		sorting = null,//сортировки
		dispatch,
	} = props;

	// console.log(sorting);

	if (!tasks.length)
		return null;


	// console.dir(projects);

	const columnsTitle = {
		act: <></>,
		name: 'Задача',
		weight: 'Важность',
		status: 'Статус',
		created: 'Создана',
		end_date: 'Крайний срок',
		// exec: 'Исполнитель',
		pid: 'Проект',
	};

	// console.dir(sorting);

	return (
		<div className={"tasks-list mt20 tbl"}>
			<TableHeader
				columnsTitle={columnsTitle}
				sorting={sorting}
				dispatch={dispatch}
			/>
			<div className="tbody">
				{
					tasks.map((task, i) => {
						return <TasksListTableRow
							key={task.id}
							user={user}
							actions={actions}
							columnsTitle={columnsTitle}
							task={task}
							project={projects[ task.pid ] ?projects[ task.pid ] : null}
							statusList={statusList}
							executorsUsers={executorsUsers}
							deleteTask={deleteTask}
						/>
					})
				}

			</div>
		</div>
	);

};

export default TasksList;



const TableSorter = ({dir = 0}) => {
	return (
		<div className={"tbl-sort" + (dir !== 0 ? ' active' : '')}>
			<i className={"fa fa-caret-" + (dir == 1 ? 'up' : 'down')}></i>
		</div>
	)
};
const TableHeader = ({columnsTitle, sorting, dispatch}) => {
	let columns = [];
	for (let k in columnsTitle) {
		const sortable = (sorting && sorting[k] != undefined);
		// console.log(k, sortable);
		columns.push(
			<li
				className={"td col-" + k + (sortable ? ' sortable' : '')}
				key={"tl-h-col" + k}
				onClick={() => {
					if (sortable)
						dispatch({type: TASKS_ACTION_SORTING, field: k});
				}}
			>
				{columnsTitle[k]}
				{
					sortable
						? <TableSorter dir={sorting[k]}/>
						: null
				}
			</li>
		);
	}


	return (
		<div className="thead">
			<ul className="tr">
				{columns}
			</ul>
		</div>
	);
};

import React from 'react';
import {useLocation, useRoutes} from "react-router-dom";

const Page404 = props => {
	const {text = ''} = props;

	// console.log(useLocation());
	return(
		<div className="page-404">
			{text !== '' ? text : 'Страница не найдена'}
		</div>
	)
}
export default Page404;

import React, {createRef, useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {ContentHeader, Win, Button, FormItem} from "components/_common";
import {MENU_TITLE, API_URLS} from "utils/config";
import {apiSend, dateFormat, isAdmin} from "utils/func";
import {useNavigate, useParams} from "react-router-dom";

const ProjectsEditContainer = props => {

	const {user, actions} = props;
	let { id } = useParams();

	const [project, setProject] = useState({});
	const formRef = createRef();
	let navigate = useNavigate();

	console.log('ProjectsEditContainer');

	useEffect(() => {
		let isSubscribe = true;
		if (isSubscribe && parseInt(id))
			apiSend(
				API_URLS.projects,
				{
					action: 'getProject',
					userId: user.id,
					id: id,
				},
				(res) => {
					console.dir(res);
					setProject(res.project)
				},
				actions
			).then(null);


		return () => isSubscribe = false
	}, []);

	const save = async (isReturn) => {

		const form = formRef.current;

		await apiSend(
			API_URLS.projects,
			{
				action: 'saveProject',
				userId: user.id,
				id: id,
				name: form.name.value,
				txt: form.txt.value,
				publish: form.publish.checked ? 1 : 0
			},
			(res) => {
				if (isReturn)
					navigate('/projects');
				else if (id == 0 && res.id)
					navigate('/projects/edit/' + res.id);

			},
			actions
		);

	};

	const info = [];

	if (!project)
		return null;


	if (project.created) {
		info.push(
			<div className="edited-info" key={"info-create"}>
				Создано: {dateFormat(project.created)}
			</div>
		);
		info.push(
			<div className="edited-info" key={"info-edit"}>
				Изменено: {dateFormat(project.edited)}
			</div>
		);
		if (isAdmin(user))
			info.push(
				<div className="edited-info" key={"info-user"}>
					Владелец: {project.userName}
				</div>
			);
	}





	return (
		<>
			<ContentHeader
				parent={[{url: '/projects', name: MENU_TITLE.projects.many}]}
				title={project.name ? project.name : 'Новый проект'}
			/>

			<Win
				onClose={() => props.history.push('/projects')}
				footer={<div className={"win-ftr-btns"}>
					<Button type="apply" onClick={() => save(false)}/>
					<>
						<Button type="save" onClick={() => save(true)}/>
						<Button type="close" onClick={() => navigate('/projects')} />
					</>
				</div>}
			>
				<form ref={formRef}>
					{/*<input*/}
					{/*	type={"hidden"}*/}
					{/*	name={"id"}*/}
					{/*	value={project ? project.id : 0}*/}
					{/*/>*/}
					<FormItem
						name={"name"}
						label={"Название"}
						isRequire={true}
						defVal={project ? project.name : ''}
						maxLength={50}
					/>
					<FormItem
						fieldType={"txt"}
						name={"txt"}
						label={"Описание"}
						defVal={project ? project.txt : ''}
						maxLength={250}
					/>
					<FormItem
						fieldType={"bool"}
						name={"publish"}
						value={1}
						label={"Опубликован"}
						isChecked={project.publish ? project.publish : true}
					/>
				</form>
				<div className={"_tar"}>
					{info}
				</div>
			</Win>
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsEditContainer);

import {NOW_DATE, GUI_MENU_TOGGLE, GUI_SIDE_MENU_OPEN_STORAGE_KEY,
	GUI_SET_DATES, GUI_SET_IS_PRINT, GUI_SET_SETTINGS,
	GUI_SET_CALENDAR_SELECT_DATE_ACTION, GUI_SET_SEARCH_ACTION,
	GUI_SET_SOCKET,
	TASKS_ACTION_SET_FILTER_PARAMS
} from "actions/GUIActions";
import Storage from "utils/Storage";
import {getFullDayMoth} from "utils/func";
import date from "php-date";
import {STORAGE_TASKS_FILTER} from "utils/config";

// Storage.set(
// 	NOW_DATE,
// 	new Date(2020, 7, 26),1
// );
const nowDateStorage = Storage.session_get(NOW_DATE);
// console.log(nowDateStorage);
let nowDate;
if (nowDateStorage)
	nowDate = new Date(parseInt(nowDateStorage));
else
	nowDate = new Date();


const initialState = {
	sideBarOpen: /true/.test(Storage.get(GUI_SIDE_MENU_OPEN_STORAGE_KEY, 'true')),
	calendarDates: {
		start: nowDate,
		end: nowDate,
		// start: new Date(2020, 4, 20),
		// end: new Date(2020, 4, 20),
		//выбранные юзером
		year: date('Y', nowDate),
		month: date('n', nowDate),
		day: date('j', nowDate)
	},
	isPrint: false,
	calendarSelectDateAction: null,
	searchAction: null,
	socket: null,
	// tasksFilter: Storage.get(STORAGE_TASKS_FILTER, {}, true)
};

// console.log(initialState);
const GUIReducer = (state = initialState, action) => {

	switch(action.type) {
		case GUI_MENU_TOGGLE:
			return {
				...state,
				sideBarOpen: action.payload.sideBarOpen
			};
		case GUI_SET_DATES:
			return {
				...state,
				calendarDates: {...action.payload}
			};
		case GUI_SET_IS_PRINT:
			return {
				...state,
				// sideBarOpen: !action.payload,
				isPrint: action.payload
			};
		case GUI_SET_SETTINGS:
			let settings = state.settings;
			settings[action.payload.key] = action.payload.value;
			return {
				...state,
				settings: settings
			};
		case GUI_SET_CALENDAR_SELECT_DATE_ACTION:
			return {
				...state,
				calendarSelectDateAction: action.payload
			};
		case GUI_SET_SEARCH_ACTION:
			return {
				...state,
				searchAction: action.payload
			};
		case GUI_SET_SOCKET:
			return {
				...state,
				socket: action.payload
			};
		// case TASKS_ACTION_SET_FILTER_PARAMS:
		// 	return {
		// 		...state,
		// 		tasksFilter: action.payload
		// 	};
		default:
			return state;
	}
}
export default GUIReducer;
import React, {createRef, useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import Api, {apiAction} from "utils/Api";
import {ContentHeader} from "components/_common";
import {API_URLS, MENU_TITLE} from "utils/config";
import {DivisionEdit} from "components/user";
import {apiSend} from "utils/func";

const DivisionsEditContainer = props => {
	let navigate = useNavigate();
	let { id } = useParams();
	id = parseInt(id);


	const {user, actions} = props;


	const [division, setDivision] = useState({});

	const onLoad = async (isSubscribe) => {
		if (!isSubscribe)
			return;

		await apiSend(
			API_URLS.user,
			{
				action: 'getDivisions',
				userId: user.id,
				id: id
			},
			(res) => {
				console.dir(res);
				if (id) {
					if (res.divisions[id] !== undefined)
						setDivision(res.divisions[id]);
					else
						toastr.error(MENU_TITLE.divisions.one + ' не найдено');
				}
			},
			actions
		);
	};
	useEffect(async () => {
		let isSubscribe = true;
		await onLoad(isSubscribe);
		return () => {
			isSubscribe = false;
		}
	}, []);

	const formRef = createRef();
	const onSubmit = async (isReturn = 0) => {
		const form = formRef.current;

		let err = [];
		if (form.name.value === '')
			err.push('Впишите название');


		if (err.length) {
			{err.map((txt, i) => {
				toastr.error('Ошибка заполнения', txt);
			})}
		}
		else {

			await apiSend(
				API_URLS.user,
				{
					action: 'saveDivision',
					userId: user.id,

					id: id || 0,
					name: form.name.value,
					txt: form.txt.value,
				},
				(res) => {
					// console.dir(res);

					if (isReturn)
						navigate('/user/divisions');
				},
				actions
			);
			/*
			actions.showLoading();

			const res = await Api.post('users.php',  {
				action: 'saveDivision',
				userId: user.id,
				data: {
					id: id || 0,
					name: form.name.value,
					data: form.data.value
				}
			});
			apiAction(res, (data) => {
				if (isReturn)
					props.history.push('/user/divisions')

			});
			actions.hideLoading();
			*/
		}

	};


	return (
		<>
			<ContentHeader
				parent={[
					{url: '/user', name: "Пользователи"},
					// {url: '/user/divisions', name: DIVISION_TITLE.many},
				]}
				title={MENU_TITLE.divisions.many}
			/>
			<DivisionEdit
				id={id}
				division={division}
				formRef={formRef}
				onSubmit={onSubmit}
				{...props}
			/>
		</>
	)

};
const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(DivisionsEditContainer);

export const SiteAccessFieldType = {
	url: 'url',
	string: 'string',
	password: 'password'
};
export const SiteAccessFieldTypeInfo = [
	{
		type: SiteAccessFieldType.url,
		name: 'URL адрес'
	},
	{
		type: SiteAccessFieldType.string,
		name: 'Host'
	},
	{
		type: SiteAccessFieldType.string,
		name: 'Логин'
	},
	{
		type: SiteAccessFieldType.password,
		name: 'Пароль'
	},
];

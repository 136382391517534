import React from 'react';
import {NavLink} from "react-router-dom";
import {rolesAdmin} from "utils/config";
import {actionMenuButton, in_array} from "utils/func";
import {Button} from "components/_common";
import cn from "classnames";



const ProjectsListItem = props => {
	const {user, project} = props;
	// console.dir(project);
	const actionButtons = [];
	if (in_array(user.role, rolesAdmin) || project.uid == user.id)
		actionButtons.push(
			<NavLink
				key={'pl-act-edit'}
				to={"/projects/edit/" + project.id} title={"Редактировать"}>
				<i className="fa fa-edit"></i> Редактировать
			</NavLink>
		);


	return (
		<div className={cn("tr", {unpublish: !project.publish})}>
			{/*<div className="td col-act">*/}
			{/*	{actionMenuButton(actionButtons)}*/}
			{/*</div>*/}
			<div className="td col-name">
				<div className="td-title">
					Наименование
				</div>
				<NavLink
					to={"/projects/edit/" + project.id}>
					{project.name}
				</NavLink>

				{
					project.txt !== '' ? <div className={"pl-name-descr"}>{project.txt}</div> : null
				}
			</div>
			{
				user.isAdmin ?
					<div className="td col-owner">
						<div className="td-title">
							Владелец
						</div>
						<NavLink
							to={"/user/profile/" + project.userId}>
							{project.userName}
						</NavLink>
					</div> : null
			}
			<div className="td col-sites">
				<div className="td-title">
					Сайты
				</div>
				{
					project.sites ? project.sites.map(s => {
						return (
							<Button
								key={"pl-site-" + s.id}
								href={"/projects/site/" + s.id}
								title={s.domain}
								cls={"pl-site -lgrey"}
								// ico={"globe"}
							/>
						);
					}) : null
				}
				<Button
					href={"/projects/site_add/" + project.id}
					type={null}
					title={null}
					cls={"pl-site -green"}
					ico={"plus"}
					tip={"Добавить сайт"}
				/>
			</div>

		</div>
	)

};

export default ProjectsListItem;

import React from 'react';
import ReactDOM from 'react-dom';
import 'style/style.css';
import 'style/font-awesome.css';
import Routing from './Routing';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import Store from "store/Store";
import ReduxToastr from 'react-redux-toastr';
import LoadingBar from 'react-redux-loading-bar';

// <React.StrictMode></React.StrictMode>
ReactDOM.render(

	<Provider store={Store}>
		<LoadingBar
			// className={"LoadingBar"}
			style={{ zIndex: 1000, backgroundColor: '#007BFF'}}
		/>

		<ReduxToastr
			timeOut={5000}
			newestOnTop={false}
			preventDuplicates={true}
			position="top-right"
			getState={(state) => state.toastr} // This is the default
			transitionIn="fadeIn"
			transitionOut="fadeOut"
			progressBar={false}
			closeOnToastrClick={false}
			maxAnimationDelay={0}
		/>
		<Routing />
	</Provider>
,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

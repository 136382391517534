import React, {Fragment, useEffect, useState, useRef} from "react";
import {BrowserRouter as Router, Routes, Route, NavLink, useLocation, useMatch} from 'react-router-dom';
import * as Containers from "containers/index";
import Page404 from "components/Page404";
import TopbarContainer from "containers/TopbarContainer";
// import CustomScroll from 'react-custom-scroll';
// import Scrollbars from 'react-custom-scrollbars';
import SideMenu from "components/menu/SideMenu";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import CalendarContainer from "components/menu/CalendarContainer";
// import * as Routers from "routers/index";
import {setCalendarSelectDateAction, sideBarToggle} from "actions/GUIActions";
import ScrollTopButton from "components/_common/ScrollTopButton";
import {API_URL, DEBUG, SOCKET_URL} from  "utils/config";
import cn from "classnames";

import {
	// LoginContainer, ForgetPassword,
	UserEditContainer, UserListContainer,
	DivisionsListContainer, DivisionsEditContainer,
} from "containers/user";
import * as Projects from "containers/projects";
import SettingsContainer from "containers/settings/SettingsContainer";
import TasksListContainer from "containers/tasks/TasksListContainer";
import TasksEditContainer from "containers/tasks/TasksEditContainer";
import TasksShowContainer from "containers/tasks/TasksShowContainer";
import TopbarUserinfo from "components/user/TopbarUserinfo";




const ContentContainer = (props) => {

	const {GUI, user, actions} = props;

	const [scrollClassName, setScrollClassName] = useState('');//'menu-side-scroll'

	const sideOpen = GUI.sideBarOpen ? 'open' : '';

	const location = useLocation();

	const [mobileMenuOpen, setMobileMenuOpen] = useState('');



	setTimeout(() => {
		const element = location.hash.substr(1) != ''
			? document.getElementById(location.hash.substr(1))
			: null;
		if (element)
			window.scrollTo({
				behavior: "smooth",
				top: element.offsetTop
			});
	}, 100);

	let contentStyle = {};
	useEffect(() => {
		console.log('ContentContainer useEffect on change location');

		setMobileMenuOpen('');
		if (GUI.socket)
			GUI.socket.emit(
				'changeURL',
				{
					path: location.pathname + location.search + location.hash,
					calendarDates: GUI.calendarDates
				});
	},[location]);

	if (window.innerWidth > 1024) {
		if (user.settings['user_' + user.id + '_style_content_max_width'])
			contentStyle['width'] = user.settings['user_' + user.id + '_style_content_max_width'];
	}


	let mainClass = null;
	const locPathArr = location.pathname.split('/');

	if (locPathArr[1])
		mainClass = locPathArr[1];

	// console.log(useLocation());
	return(
		<div className={cn("anim-show", {print: GUI.isPrint})}>
			<>
				<div className={"ms-top flx -sb no-print " + sideOpen}>
					<div className="logo">
						<NavLink to="/">
							DiveCRM
							{DEBUG ? <sup>dev</sup> : null}
						</NavLink>

					</div>

					<div className="menu-bars"
						 onClick={e => {
							 setMobileMenuOpen(
							  mobileMenuOpen === '' ? ' mobi-open' : ''
							 );
						 }}
					>
						<i className="fa fa-bars"></i>
					</div>
				</div>
				<div className={"menu-side no-print " + sideOpen + mobileMenuOpen}>
					{/*<div className="menu calendar-menu">*/}
					{/*	<ul className="_z ">*/}
					{/*		<CalendarContainer />*/}
					{/*		<li>*/}
					{/*			<hr />*/}
					{/*		</li>*/}
					{/*	</ul>*/}
					{/*</div>*/}

					<div className="mobi-user">
						<TopbarUserinfo />
					</div>

					<SideMenu
						// setScrollClassName={setScrollClassName}
						user={props.user}
					/>
					{/*<Scrollbars*/}
					{/*	autoHide={true}*/}
					{/*	// autoHeight={true}*/}
					{/*	className={scrollClassName}*/}
					{/*>*/}
					{/*	*/}
					{/*</Scrollbars>*/}
				</div>
			</>
			<TopbarContainer />
			{/*{!GUI.isPrint ? <TopbarContainer /> : null}*/}



			<main
				className={cn(mainClass,{full: !props.GUI.sideBarOpen})}
			>
				<div className="content" style={contentStyle}>
					<Routes>
						<Route
							path="/"
						>

							{/* USER */}
							<Route
								path="user"
								// element={<Routers.UserRouter />}
							>
								<Route
									path="edit/:id"
									element={<UserEditContainer {...props} />}
								/>
								<Route
									path="profile_edit"
									element={<UserEditContainer {...props} editSelf={true}/>}
								/>
								<Route
									path="profile/:id"
									element={<UserEditContainer {...props} edit={false}/>}
								/>
								<Route
									path="role/:roleName"
									element={<UserListContainer {...props} />}
								/>
								<Route
									path="divisions"
								>
									<Route
										index
										element={<DivisionsListContainer {...props} />}
									/>
									<Route
										path="edit/:id"
										element={<DivisionsEditContainer {...props} />}
									/>
								</Route>
								<Route
									index
									element={<UserListContainer {...props} />}
								/>
							</Route>
							{/* ///   USER */}
							{/* PROJECTS */}
							<Route
								path="projects"
							>
								<Route
									path="edit/:id"
									element={<Projects.ProjectsEditContainer {...props} />}
								/>

								<Route
									path="site/:id"
									element={<Projects.SiteEditContainer {...props} />}
								/>
								<Route
									path="site_add/:id"
									element={<Projects.SiteEditContainer {...props} isNew={true}/>}
								/>
								<Route
									index
									element={<Projects.ProjectsListContainer {...props} />}
								/>
							</Route>
							{/* /// PROJECTS */}

							<Route
								path="settings"
								element={<SettingsContainer {...props} />}
							/>
							<Route
								path="settings/:settingsType"
								element={<SettingsContainer {...props} />}
							/>

							<Route
								path="tasks"
							>
								<Route
									path=":id"
									element={<TasksShowContainer {...props} />}
								/>
								<Route
									path=":id/:commentId"
									element={<TasksShowContainer {...props} />}
								/>
								<Route
									path="new/"
									element={<TasksEditContainer {...props} isNew={true}/>}
								/>
								<Route
									path="edit/:id"
									element={<TasksEditContainer {...props} isNew={false}/>}
								/>
								<Route
									index
									// element={<Routers.TasksRouter />}
									element={<TasksListContainer {...props} />}
								/>
							</Route>

							<Route
								index
								// element={<Routers.TasksRouter />}
								element={<TasksListContainer {...props} />}
							/>
							<Route path="*" element={<Page404
								// text={"end 404"}
							/>}
							/>
						</Route>

						{/*<Route path="/" index element={<Containers.ConsoleContainer />} />*/}
						{/*<Route path="/" index element={<Routers.TasksRouter />} />*/}
						{/*<Route path="/tasks" element={<Routers.TasksRouter />} />*/}
						{/*<Route path="/user" element={<Routers.UserRouter />} />*/}
						{/*<Route path="/tasks" key={"tasks-tasks"} component={Routers.TasksRouter} />*/}

						{/*<Route path="/docs" component={Routers.DocsRouter} />*/}

						{/*<Route path="/settings" component={Routers.SettingsRouter} />*/}



						{/*<Route path="/projects" component={Routers.ProjectsRouter} />*/}




					</Routes>
				</div>
			</main>

			<ScrollTopButton />

		</div>
	);
};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		setCalendarSelectDateAction, sideBarToggle
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);

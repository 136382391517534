import React from 'react';
import CommentEditor from "./CommentEditor";
import Comment from "./Comment";
import {CAN_EDIT_COMMENTS_TIME} from "../../utils/config";
import {in_array} from "../../utils/func";

const CommentsList = ({
                          comments: commentsAll,
                          parentCommentId,
                          editCommentId,
                          user,
                          saveComment,
                          commentSetViewed,
                          deleteComment,
                          replyComment,
                          setEditCommentId,
                          commentSetPin,
                      }) => {


    let comments = [];
    let childrenExists4Id = [];
    commentsAll.map(com => {
        if (com.cid == parentCommentId)
            comments.push(com);

        if (!in_array(com.cid, childrenExists4Id))
            childrenExists4Id.push(com.cid);
    })

    let content = [];

    comments.map((comment, i) => {
        if (comment.id === editCommentId)
            content.push(
                <CommentEditor
                    key={`cmnt-${i}-${comment.id}`}
                    user={user}
                    saveComment={saveComment}
                    showEditor={true}
                    setEditCommentShow={(isShow) => {
                        //TODO когда отмена, надо скрыть редактор комментария
                        if (!isShow)
                            setEditCommentId(0);
                    }}
                    comment={comment}
                />
            );
        else
            content.push(
                <div key={`cmnt-${comment.id}`} >
                    <Comment
                        user={user}
                        comment={comment}

                        onDelete={canDeleteComment(user, comment) ? deleteComment : null}
                        onEdit={canEditComment(user, comment) ? setEditCommentId : null}
                        commentSetViewed={commentSetViewed}

                        replyComment={replyComment}
                        commentSetPin={commentSetPin}
                    />
                    {
                        in_array(comment.id, childrenExists4Id)
                        ? <div className={"comment-children"}><CommentsList
                                key={`comments-list-0`}
                                comments={commentsAll}
                                parentCommentId={comment.id}
                                editCommentId={editCommentId}
                                user={user}
                                saveComment={saveComment}
                                commentSetViewed={commentSetViewed}
                                deleteComment={deleteComment}
                                replyComment={replyComment}
                                setEditCommentId={setEditCommentId}
                                commentSetPin={commentSetPin}
                            /></div> : null
                    }
                </div>
            );
    });


    return content;
};

export default CommentsList;

const canEditComment = (user, comment) => {

    return user.id === comment.uid
        &&
        (new Date(comment.created).getTime() >= Date.now() - CAN_EDIT_COMMENTS_TIME);
}
const canDeleteComment = (user, comment) => {
    // console.log(comment.created, new Date(comment.created).getTime());
    return user.id === comment.uid || user.isAdmin;
}
import React, {useRef, useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {ContentHeader, Button, FormItem} from "components/_common";
import {MENU_TITLE, rolesAdmin, rolesModerator, API_URLS} from "utils/config";
import {apiSend, in_array} from "utils/func";
import ProjectsListItem from "components/projects/ProjectsListItem";
// import date from "php-date";
// import * as strtotime from "strtotime";



const ProjectsListContainer = props => {

	const {user, actions} = props;
	const [projects, setProjects] = useState([]);
	const [showPublish, setShowPublish] = useState(true);
	const ShowPublishedRef = useRef(true);


	const gerProjects = async () => {
		await apiSend(
			API_URLS.projects,
			{
				action: 'getList',
				userId: user.id,
				published: ShowPublishedRef.current
			},
			(res) => {
				console.dir(res);
				if (res.projects)
					setProjects(res.projects);
				// res = Object.values(res);
				// if (res && res.length)
				// 	setProjects(res);
			},
			actions
		)
	}

	useEffect(() => {
		let isSubscribe = true;

		if (isSubscribe)
			// gerProjects();//.then(null);
			gerProjects().catch(console.error);

		return () => isSubscribe = false
	}, []);

	const panel = <div className="panel-top flx -sb mt20 no-print">

		<div className="col-2">
			<FormItem
				fieldType={"bool"}
				name={"showPublished"}
				label={"Только опубликованные"}
				// defVal={true}
				isChecked={showPublish}
				// isChecked={ShowPublishedRef.current}
				changeAction={(e, checked) => {
					// ShowPublishedRef.current = checked;
					// gerProjects();
					setShowPublish(checked);
				}}
			/>
		</div>

		<div className="col-2 _tar">
			<Button
				href={"/projects/edit/0"}
				type={"save"}
				title={"Создать " + MENU_TITLE.projects.one.toLowerCase()}
				ico={"plus-circle"}
			/>
		</div>
	</div>

	return (
		<>
			<ContentHeader
				title={MENU_TITLE.projects.many}
			/>
			{
				in_array(user.role, rolesModerator)
					? panel
					: null
			}
			{
				projects.length ? (
					<div className="projects-list mt20 tbl">
						<div className="thead">
							<div className="tr">
								{/*<div className="td col-act">*/}
								{/*	&nbsp;*/}
								{/*</div>*/}
								<div className="td col-name">
									Наименование
								</div>
								{
									user.isAdmin ? <div className="td col-owner">
										Владелец
									</div> : null
								}
								<div className="td col-sites">
									Сайты
								</div>
							</div>
						</div>
						<div className="tbody">
							{
								projects.map(project => {
									if (!showPublish || project.publish)
										return <ProjectsListItem
											key={project.id}
											user={user}
											project={project}
										/>;

									return null;
								})
							}
						</div>
					</div>

				) : null
			}
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsListContainer);

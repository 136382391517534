import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import TopbarUserinfo from "components/user/TopbarUserinfo";
import {NavLink, useNavigate} from "react-router-dom";
import {bindActionCreators} from "redux";
import {sideBarToggle} from "actions/GUIActions";
import {toastr} from "react-redux-toastr";
import {dateFormat, NotificationType, createNotification, in_array} from "utils/func";
import cn from "classnames";
// import {URLS} from "utils/config";
import useSound from 'use-sound';
// import notifySound from 'sound/relax-message-tone.mp3';
import notifySound from 'sound/so-proud-notification.mp3';


/*
notificationType = {
	text: 'Текст уведомления',
	date: 123,//время уведомления
	url: 'ссылка/если_есть',
}
 */

const notsInit = [
	{
		text: 'Новые комент к задаче',
		url: 'tasks/2',
		date: Date.now() / 1000
	},
	{
		text: 'Новая задача ()',
		url: 'tasks/2',
		date: Date.now() / 1000
	}
];

const NotificationsIdShowed = [];
let SocketListenerCreated = false;


const TopbarContainer = (props) => {
	const {
		GUI,
		user,
		actions
	} = props;

	console.log(`TopbarContainer`, GUI.socket === null);

	const [searchString, setSearchString] = useState('');

	const [notifications, setNotifications] = useState([]);

	const [notifySoundPlay, {stop}] = useSound(notifySound, {interrupt : true});

	// function socketNotify(notification, ackFn) {
	// 	ackFn('TopbarContainer:socketNotify');
	// 	console.log('topbar notify');
	// 	console.dir(notification);
	// 	console.log(typeof ackFn);
	// 	notifySoundPlay();
	//
	// 	const {title = '', img = ''} = notification;
	//
	// 	setNotifications(notifications.concat(notification));
	//
	// 	if (!in_array(notification.id, NotificationsIdShowed)) {
	// 		createNotification(
	// 			NotificationType.info,
	// 			notification.text,
	// 			title,
	// 			notification.url,
	// 			img
	// 		);
	// 		NotificationsIdShowed.push(notification.id);
	// 	}
	//
	//
	//
	// 	setTimeout(() => stop(), 300);
	// }
	// if (GUI.socket && !SocketListenerCreated) {
	// 	SocketListenerCreated = true;
	// 	GUI.socket.connect();
	// 	GUI.socket.on(process.env.REACT_APP_SOCKET_EMIT_NOTIFY, socketNotify);
	// }

	const onSearch = () => {

		if (searchString.trim() == '') {
			toastr.error('Ошибка', 'Введен пустой поисковый запрос');
			return;
		}
		if (GUI.searchAction)
			GUI.searchAction(searchString.trim());
	};
	const onEnterPressedCatcher = e => {
		if (e.key.toLowerCase() === 'enter')
			onSearch();
	};
	useEffect(() => {
		console.log(`TopbarContainer useEffect`);
		// try {
		// 	GUI.socket.connect();
		// 	console.log('GUI.socket.connect');
		// }catch(err){
		// 	// console.log(err);
		// }
		//
		// if (GUI.socket) {
		// 	// GUI.socket.connect();
		// 	GUI.socket.on(process.env.REACT_APP_SOCKET_EMIT_NOTIFY, socketNotify);
		// }
		// if (GUI.socket && !SocketListenerCreated) {
		// 	SocketListenerCreated = true;
		//
		// 	// GUI.socket.on(process.env.REACT_APP_SOCKET_EMIT_NOTIFY, socketNotify)
		// 	// GUI.socket.on(process.env.REACT_APP_SOCKET_EMIT_NOTIFY, (notification, ackFn) => {
		// 	// 	// console.clear();
		// 	// 	// ackFn(Date.now());
		// 	//
		// 	// 	console.log('topbar notify');
		// 	// 	console.dir(notification);
		// 	// 	console.log(typeof ackFn);
		// 	// 	notifySoundPlay();
		// 	//
		// 	// 	const {title = '', img = ''} = notification;
		// 	//
		// 	// 	setNotifications(notifications.concat(notification));
		// 	//
		// 	// 	if (!in_array(notification.id, NotificationsIdShowed)) {
		// 	// 		// createNotification(
		// 	// 		// 	NotificationType.info,
		// 	// 		// 	notification.text,
		// 	// 		// 	title,
		// 	// 		// 	notification.url,
		// 	// 		// 	img
		// 	// 		// );
		// 	// 		// NotificationsIdShowed.push(notification.id);
		// 	// 	}
		// 	//
		// 	// 	setTimeout(() => stop(), 300);
		// 	//
		// 	//
		// 	// });
		//
		//
		// 	// GUI.socket.on(process.env.REACT_APP_SOCKET_EMIT_NOTIFY, notification => {
		// 	// 	console.log('topbar notify');
		// 	// 	console.dir(notification);
		// 	// });
		//
		// }

		return () => {
			// if (GUI.socket) {
			// 	GUI.socket.off(process.env.REACT_APP_SOCKET_EMIT_NOTIFY, socketNotify);
			// 	// 	GUI.socket.off(process.env.REACT_APP_SOCKET_EMIT_NOTIFY);
			// }
		};
	}, []);// не передавать даже пустые зависимости, иначе сокет не работает на стороне клиента


	const deleteNotification = index => {
		let nots = [];
		notifications.map((item, i) => {
			if (i !== index)
				nots.push(item);
		});
		setNotifications(nots);
	}

	return (
		<div className={"top-bar flx -alc no-print " + (!GUI.sideBarOpen ? 'full' : '')}>
			<div className="tb-left flx -alc">
				<div
					className="menu-bars"
					onClick={() => actions.sideBarToggle(!props.GUI.sideBarOpen)}
				>
					<i className="fa fa-bars"></i>
				</div>
				<ul className="tb-menu flx -alc _z">
					{/*{*/}
					{/*	(user.settings && user.settings.mkad_url && user.settings.mkad_url !== '') ?*/}
					{/*		<li><a href={user.settings.mkad_url} target={"_blank"}>*/}
					{/*			<i className={"fa fa-map-pin"}></i> Расчет расстояния от МКАД*/}
					{/*		</a></li> : null*/}
					{/*}*/}

				</ul>
			</div>
			<div className={"tb-srch flx" + (!GUI.searchAction ? ' hidden' : '')}>
				<input
					type="text"
					name="srch"
					placeholder="Поиск"
					value={searchString}
					onChange={e => setSearchString(e.target.value)}
					onKeyDown={e => onEnterPressedCatcher(e)}
					onFocus={e => {
						setSearchString('');
					}}
				/>
				<button
					onClick={e => onSearch()}
				>
					<i className="fa fa-search"></i>
				</button>
				<i
					className="fa fa-close"
					onClick={e => setSearchString('')}
				></i>
			</div>
			<div className="tb-right flx -e -alc">
				<div className="tb-notifs flx">
					{
						notifications.length
						? <Notifications data={notifications} deleteNotification={deleteNotification}/>
						: null
					}

				</div>
				<TopbarUserinfo/>
			</div>
		</div>
	)
};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});
const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		sideBarToggle
	}, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TopbarContainer);

const Notifications = ({data = [], deleteNotification}) => {
	// console.log(data);
	const [opened, setOpened] = useState(false);

	let navigate = useNavigate();

	if (data.length === 0)
		return null;

	return (
		<div className="topbar-notify">
			<div
				className="ico"
				 onClick={() => setOpened(!opened)}
			>
				<i className="fa fa-bell-o"></i>
				<span>{data.length}</span>
			</div>
			<ul className={cn("topbar-notify-list _z", {active: opened})}
				onMouseLeave={() => setOpened(false)}
			>
				{
					data.map((notify, i) => {
						return (
							<li
								key={`notify-${i}`}
								className={cn({linked: notify.url != ''})}
							>
								<div
									className="t"
									onClick={() => {
										if (notify.url) {
											deleteNotification(i);
											navigate(notify.url);
										}
									}}
									dangerouslySetInnerHTML={{__html: notify.text}}
								>
									{/*{notify.text}*/}
								</div>
								<div className="d">
									{dateFormat(notify.date, 'd.m H:i')}
								</div>
								<div className="dl">
									<i
										className={"fa fa-close"}
										onClick={() => deleteNotification(i)}
									/>
								</div>
							</li>
						);
					})
				}

			</ul>
		</div>
	);
}

import React, {useEffect, useReducer} from 'react';
import {Win, Button, FormItem} from "components/_common";
import FieldContainer from "./FieldContainer";
import * as uniqid from "uniqid";
// import {SITE_ACCESS_PART_NAMES} from "utils/config";

const reducer = (state, action) => {
	// console.log(action);

	let newFields = [];
	switch (action.type) {
		case 'add':

			const id = uniqid();
			newFields = [...state.Fields];
			newFields.push(
				<FieldContainer
					key={id}
					edit={action.edit}
					id={id}
					onDelete={action.onDelete}
					partId={action.partId}
					data={action.data}
				/>
			);
			return {
				...state,
				Fields: newFields
			}

		case 'delete':
			newFields = [];
			state.Fields.map(itm => {
				if (itm.key !== action.id)
					newFields.push(itm);
			});

			return {
				...state,
				Fields: newFields
			}
		default:
			throw new Error();
			break;
	}


}
const PartContainer = (props) => {
	const {edit = true, name = '', onDelete, id, data, siteId = 0, index = 0} = props;
	// console.dir(props);
	const [state, dispatch] = useReducer(reducer, {Fields: []});
	const {Fields} = state;
	// console.dir(state);
	// console.dir(dispatch);
	// console.dir(Fields);
	// console.log(edit, id, name);
	// console.log(onDelete);
	// console.log(typeof onDelete);

	const onDeleteField = (id) => {
		console.log('onDeleteField GO', id);
		dispatch({type: 'delete', id: id});
	}

	useEffect(() => {
		// dispatch({type: 'add', edit: edit, partId: id, onDelete: onDeleteField});//test

		if (data && data.fields && data.fields.length) {
			data.fields.map(f => {
				dispatch({type: 'add', edit: edit, partId: id, onDelete: onDeleteField, data: f});
				return null;
			});
		}

	}, [dispatch, data]);



	const predefinedNames = [
		'Панель администрирования',
		'FTP',
		'База данных',
		'SSH',
		'Панель управления хостингом (сервером)'
	];

	return (
		<Win
			winClass="mt20 -grey"
			onClose={() => {
				onDelete(id)
			}}
			footer={<div className={"win-ftr-btns flx -c"}>
				<Button
					type={null}
					title={"Добавить поле и его значение"}
					cls={"-grey X-small mt20"}
					ico={"code"}
					onClick={e => {
						dispatch({type: 'add', edit: edit, partId: id, onDelete: onDeleteField})
					}}
				/>
			</div>}

			minimized={true}
			titleIfMinimized={data ? data.name : 'новая запись'}
			id={`win-site-access-${siteId}-${index}`}
			minimizedDefault={true}
		>
			<FormItem
				edit={edit}
				name={"part-name-" + id}
				defVal={data ? data.name : ''}
				label={'Название раздела'}
				isRequire={true}
				predefined={predefinedNames}

			/>

			{Fields}


		</Win>
	)
}
export default PartContainer;

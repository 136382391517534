import React, {useState, useEffect} from 'react';
import {useParams, useLocation, useRouteMatch} from "react-router-dom";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {API_URLS} from "utils/config";
import {apiSend, in_array} from "utils/func";
import {toastr} from "react-redux-toastr";
import {ContentHeader, Button, Win} from "components/_common";
import * as Settings from "components/settings";
import {userSetSettings} from "actions/UserActions";
import Page404 from "components/Page404";


const SettingsContainer = props => {

	const {user, GUI, actions} = props;


	const {settingsType = 'personal'} = useParams();
	let settingsTypeResult = settingsType;
	console.log('settingsType', settingsType);

	const location = useLocation();
	// if (location.pathname === '/ustav')
	// 	settingsTypeResult = 'ustav';


	const [settings, setSettings] = useState(null);
	const [savedSettings, setSavedSettings] = useState({});
	const [divisions, setDivisions] = useState(null);
	const [deleteSettings, setDeleteSettings] = useState([]);

	const [todoList, setTodoList] = useState(null);

	let apiActionProcess = false;
	const getSettings = async (isSubscribe = true, stype = '') => {

		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			await apiSend(
				API_URLS.settings,
				{
					action: 'getSettings',
					type: settingsType
				},
				(res) => {
					// console.dir(res);
					setSettings(res.settings);
				},
				actions
			);
		}
	};
	useEffect(() => {
			let isSubscribe = true;
			getSettings(isSubscribe, settingsTypeResult).then(null);

			return () => {
				isSubscribe = false;
				setSettings(null);
			}
		},
		[]
	);

	const saveSettings = async (data = null) => {
		// console.clear();
		// console.log('saveSettings');
		// console.log(savedSettings);
		// console.log(data);
		// return;
		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('settings.php', {
				action: 'setSettings',
				user: user,
				data: data ? data: savedSettings,
				deleteSettings: deleteSettings
			});

			apiAction(res, (data) => {
				for (const key in savedSettings) {
					actions.userSetSettings(key, savedSettings[key]);
				}
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();

			});
		}
	};

	if (!settings || typeof settings !== 'object')
		return null;

	let contentHeaderTitle = '',
		contentHeaderParent = [{url: '/settings', name: 'Настройки'}],
		content = '',
		fullContent = null;

	let winFooter = (
		<div className={"win-ftr-btns"}>
			<Button
				type="apply"
				onClick={() => saveSettings()}
				disabled={!Object.keys(savedSettings).length}
				// disabled={true}
			/>
		</div>
	);


	switch(settingsTypeResult) {
		// case "calendar":
		// 	contentHeaderTitle = 'Календарь';
		// 	content=<Settings.Calendar
		// 		{...props}
		// 	/>;
		// 	break;

		case "general":
			contentHeaderTitle = 'Основные настройки';

			let initTodoSelectColors = {};
			for (const divId in divisions) {
				let bgr = '#fffff1', color = '#999999';
				if (settings['g_todo-select-bgr-' + divId])
					bgr = settings['g_todo-select-bgr-' + divId];
				if (settings['g_todo-select-color-' + divId])
					color = settings['g_todo-select-color-' + divId];
				initTodoSelectColors[divId] = {
					bgr: bgr,
					color: color
				};
			}
			let initTodoListBgr = {};
			if (todoList)
				for (let todo in todoList) {
					let clr;

					if (todoList[todo].hideInTodo === undefined || !todoList[todo].hideInTodo) {
						if (settings.task_list_bgr && settings.task_list_bgr[todo])
							clr = settings.task_list_bgr[todo];
						else
							clr = '#ffffff';
						// clr = '#FD7E14';

						initTodoListBgr[todo] = clr;
					}
				}
			// console.log(initTodoSelectColors);

			if (settings && divisions && todoList)
				content = <Settings.General
					// setSettings={setSettings}
					settings={settings}
					divisions={divisions}
					initTodoSelectColors={initTodoSelectColors}
					savedSettings={savedSettings}
					setSavedSettings={setSavedSettings}
					userRoles={user.userRoles && user.userRoles}
					todoList={todoList}
					initTodoListBgr={initTodoListBgr}
				/>;
			break;
		default:
			contentHeaderTitle = 'Персональные настройки';
			content = <Settings.Personal
				user={user}
				settings={settings}
				savedSettings={savedSettings}
				setSavedSettings={setSavedSettings}
			/>
			break;
	}
	if (fullContent)
		return fullContent;

	return (
		<>
			<ContentHeader
				title={contentHeaderTitle}
				parent={contentHeaderParent}
				showDates={false}
			/>
			<Win
				header={contentHeaderTitle}
				footer={winFooter}
			>
				{content}
			</Win>
		</>
	);

};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, userSetSettings
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);

import React, {useEffect, useReducer} from 'react';
import {Button} from "components/_common";
// import {arrayRemove} from "utils/func";
import PartContainer from "./PartContainer";
import * as uniqid from "uniqid";
// import {emptyString} from "react-select/src/utils";

const reducer = (state, action) => {
	// console.log(action);
	// console.log(state);
	let newParts;

	switch (action.type) {
		case 'add':
			// const id = uniqid('site-access-part-');
			const id = uniqid('');
			newParts = [...state.Parts];
			newParts.push(
				<PartContainer
					key={id}
					edit={action.edit}
					onDelete={action.onDelete}
					id={id}
					data={action.data}
					siteId={action.siteId}
					index={action.index}
				/>
			);
			return {
				...state,
				Parts: newParts
			}
			// break;
		case 'delete':
			newParts = [];
			state.Parts.map(itm => {
				if (itm.key !== action.id)
					newParts.push(itm);
			});

			return {
				...state,
				Parts: newParts
			}

		default:
			throw new Error('Странная ошибка в SiteAccess->reducer');

	}
}

const SiteAccess = ({edit = true, data = [], siteId = 0}) => {

	const onDeletePart = (id) => {
		console.log('onDeletePart GO');
		dispatch({type: 'delete', id: id, siteId: siteId});
	}
	// const [Parts, setParts] = useState([]);

	// console.dir(data);
	// const partsInit = [];
	// if (data.length) {
	// 	data.map((p) => {
	// 		// console.log(p);
	// 		const id = uniqid('');
	// 		partsInit.push(
	// 			<PartContainer
	// 				key={id}
	// 				edit={edit}
	// 				onDelete={onDeletePart}
	// 				id={id}
	// 			/>
	// 		);
	// 		// dispatch({type: 'add', edit: edit, onDelete: onDeletePart, data: p})
	// 	})
	// }
	// console.dir(partsInit);


	const [state, dispatch] = useReducer(reducer, {Parts: []});
	const {Parts} = state;
	// console.dir(Parts);




	useEffect(() => {
		// console.log('SiteAccess init');

		// const partsInit = [];
		// console.dir(data);
		if (data.length) {
			data.map((p, i) => {
				dispatch({type: 'add', edit: edit, onDelete: onDeletePart, data: p, siteId: siteId, index: i});
				return null;
			})
		}

		// dispatch({type: 'add', edit: edit, onDelete: onDeletePart});

	}, [dispatch, data]);

	return (
		<>
			{Parts}

			{
				edit ? <Button
					type={null}
					title={"Добавить раздел"}
					cls={"pl-site -green mt20"}
					ico={"plus"}
					onClick={e => dispatch({type: 'add', edit: edit, onDelete: onDeletePart, siteId: siteId, index: Parts.length})}
				/>
				: null
			}

		</>
	)

};








export default SiteAccess;

/*
const deleteField1 = num => {
		console.log('delete', num);
		console.log(Fields.length);
		console.dir(Fields);
		// const newArr = arrayRemove(num, Fields);

		// const newArr = Fields;
		// console.dir(newArr);
		// newArr.splice(num, 1);
		// console.dir(newArr);
		// setFields(newArr);
	}

	const addField1 = data => {
		// setFields([
		// 	...Fields,
		// 	<FieldContainer
		// 		key={"pc-fc-" + (Fields.length + 1)}
		// 		edit={edit}
		// 		num={Fields.length}
		// 		onDelete={deleteField}
		// 	/>
		// ]);
		// const newArr = Fields;
		const newArr = [...Fields];
		const num = newArr.length;
		newArr.push(
			<FieldContainer
				key={"pc-fc-" + (num)}
				edit={edit}
				num={num}
				onDelete={e => deleteField(num)}
			/>
		);
		setFields(newArr);
		// console.dir(newArr);
		// console.dir(Fields);
	}
	const deleteField = useCallback((num) => {
		console.log('delete', num);
		console.dir(Fields);
	},[Fields]);
	const addField = useCallback((data) => {
		// console.log(Fields);
			const newArr = [...Fields];
			const num = newArr.length;
			console.log(num);
			newArr.push(
				<FieldContainer
					key={"pc-fc-" + (num)}
					edit={edit}
					num={num}
					onDelete={() => deleteField(num)}
				/>
			);
			setFields(newArr);
		},
		[Fields]
	);
 */
